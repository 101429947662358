import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const contractServiceUrl = `${API_URL}/contract`;

const getCustomerContracts = (programs) => RequestHelper.GET(`${contractServiceUrl}/contracts?${programs}`);

const getCustomerContractsCount = () => RequestHelper.GET(`${contractServiceUrl}/contracts/count`);

const getContractInfo = (contractId) => RequestHelper.GET(`${contractServiceUrl}/${contractId}/info`);

const getContractDetails = (contractId) => RequestHelper.GET(`${contractServiceUrl}/${contractId}/details`);

const getContractNumberAllowed = (contractId) => RequestHelper.GET(`${contractServiceUrl}/${contractId}/authorize`);

const getContractAssociatedToCompany = (companyEncryptedId) => RequestHelper.GET(`${contractServiceUrl}/companyContracts/${companyEncryptedId}`)

export const ContractService = {
  getContractAssociatedToCompany,
  getCustomerContracts,
  getCustomerContractsCount,
  getContractInfo,
  getContractDetails,
  getContractNumberAllowed
};