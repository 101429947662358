/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTable } from 'commons/Table/CustomTable';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '20vw',
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px'
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '90px',
      backgroundColor: theme.palette.primary.light
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export const CustomTableWithInfiniteScrolling = ({
  rows,
  headers,
  onRowCLick,
  filters,
  isLoading,
  rowKey,
  onMaxScroll,
  onChange,
  total,
  sortParams,
  paginationParams,
  onSort
}) => {
  const classes = useStyles();
  const listInnerRef = useRef();
  let fullyLoaded = rows.length >= total;

  const onScroll = (e) => {
    e.stopPropagation();
    if (listInnerRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        if (!isLoading && !fullyLoaded) {
          paginationParams?.setPage(page => {
            onMaxScroll(page+1);
            return page + 1
          });
        }
      }
    }
  };

  useEffect(() => {
    onSort && onSort(sortParams);
  }, [sortParams?.order, sortParams?.orderBy])

  return (
    <div className={classes.tableContainer} onScroll={onScroll} ref={listInnerRef}>
      <CustomTable
        excludePagination
        filters={filters}
        headers={headers}
        isBackend
        isLoading={isLoading}
        isLoadingExtraData={!fullyLoaded}
        onChange={onChange}
        onRowCLick={onRowCLick}
        paginationParams={paginationParams}
        rowKey={rowKey}
        rows={rows}
        sortParams={sortParams}
        stickyHeader
        total={total}
      />
    </div>
  )
}


CustomTableWithInfiniteScrolling.propTypes = {
  ...CustomTableWithQuerySort.propTypes,
  excludePagination: undefined,
  isBackend: undefined
};

CustomTableWithInfiniteScrolling.defaultProps = {
  ...CustomTableWithQuerySort.defaultProps,
  excludePagination: true,
  isBackend: true
};