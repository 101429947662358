import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { LanguageService } from 'services/LanguageService';
import { getDefaultHeaders } from 'utils/request';

export const initI18N = (token, callback = () => {}) => {
  const headers = getDefaultHeaders();
  headers.Authorization = `Bearer ${token}`;

  i18next.on('loaded', () => {
    callback();
  });

  i18next.use(HttpBackend).init({
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    },
    ns: ['translation'],
    defaultNS: 'translation',
    initImmediate: false,
    backend: {
      loadPath: LanguageService.localizationUrl,
      allowMultiLoading: false,
      crossDomain: true,
      withCredentials: true,
      customHeaders: headers
    }
  });
}