/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType } from 'commons/Dialog/CustomDialog';
import { useDialog } from 'hook/DialogProvider';
import { FileService } from 'services/FileService';

/**
 * Hook used to mutualise the file download logic
 * @returns {function(fileIdEncrypted : string)} a function to trigger file download given an id
 */
export default function useFileDownload() {
  const showDialog = useDialog();

  return (fileId) => FileService.downloadFile(fileId)
    .catch((errorMessage) => showDialog({
      variant: DialogType.ERROR,
      title: 'error',
      description: errorMessage
    }))
}