import * as qs from 'query-string';
import { ParamTypes } from 'utils/constants/param.type';

/**
 * Basic function to map a value to a given param type
 * @param value the value to map
 * @param paramType the param type (see ParamTypes enumeration)
 * @returns {*} the casted type
 */
export const mapValue = (value, paramType) => {
  switch (paramType) {
  case ParamTypes.STRING:
    return value;
  case ParamTypes.NUMBER:
    return Number(value);
  case ParamTypes.BOOLEAN:
    // Need this check as Boolean('false') is true and we may have a string here
    if (value === 'false') {
      return false;
    }
    return Boolean(value);
  case ParamTypes.ARRAY:
    if (!value) {
      return [];
    }
    if (!Array.isArray(value)) {
      return [value];
    }
    return value;
  default:
    throw new Error(`Got an incorrect param type : ${paramType}`);
  }
};

/**
 * Merges the value into the query string or removes it if it is null without reloading the page
 * @param key the key to add to the querystring (or remove if the value is null or empty)
 * @param value the value to add to the query string ore remove if null
 */
export const setQueryStringValue = (key, value) => {
  const values = qs.parse(window.location.search);
  let newQsValue;
  if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
    delete values[key];
    newQsValue = qs.stringify(values);
  } else {
    newQsValue = qs.stringify({
      ...values,
      [key]: value
    });
  }
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${newQsValue}`;
  window.history.replaceState({ path: newurl }, '', newurl);
};

/**
 * Parses the querystring to obtain the value of a given key with the right type
 * @param key the key to find
 * @param queryString the querystring (location.search)
 * @param paramType the parameter type to cast the result into
 * @returns {*} the value of the key casted
 */
export const parseQueryStringValue = (key, queryString, paramType) => {
  const values = qs.parse(queryString);
  return mapValue(values[key], paramType);
};