import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: ({maxLines}) => ({
    display: "-webkit-box",
    "-webkit-line-clamp": maxLines,
    "-webkit-box-orient": "vertical",
    overflow: 'hidden',
    wordBreak: "break-all"
  })
}));

export const TruncatedMultiline = ({children, maxLines=2}) => {
  const classes = useStyles({maxLines});
  return (
    <span className={classes.content}>{children}</span>
  );
};

TruncatedMultiline.propTypes = {
  maxLines: PropTypes.number
};