/* eslint-disable max-len */
import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const diplomaServiceUrl = `${API_URL}/diploma`;

const getDiplomasCertifyRange = (contractId) => RequestHelper.GET(`${diplomaServiceUrl}/${contractId}/certifyRange`);

const getDiplomasCertifyAll = (contractId) => RequestHelper.GET(`${diplomaServiceUrl}/${contractId}/certifyAll`);

export const DiplomaService = {
  getDiplomasCertifyRange,
  getDiplomasCertifyAll
};