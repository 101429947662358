import { makeStyles } from '@material-ui/core/styles';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import React from 'react';
import { AuditCompletion } from 'utils/constants/audit.completion';

const useStyles = makeStyles((theme) => ({
  success: {
    fontWeight: 'bold',
    color: theme.palette.success.dark
  },
  cancelled: {
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  failed: {
    fontWeight: 'bold',
    color: theme.palette.error.dark
  }
}));

const getClassName = (completion, classes) => {
  switch (completion) {
  case AuditCompletion.SUCCESS:
    return classes.success;
  case AuditCompletion.CANCELLED:
    return classes.cancelled;
  case AuditCompletion.FAILED:
    return classes.failed;
  default:
    return '';
  }
}

const getCompletionTooltip = (completion) => {
  switch (completion) {
  case AuditCompletion.SUCCESS:
    return 'success';
  case AuditCompletion.CANCELLED:
    return 'cancelled';
  case AuditCompletion.FAILED:
    return 'failed';
  default:
    return '';
  }
}

const AuditStatusDisplay = ({ status, completion }) => {
  const classes = useStyles();
  let displayedStatus = status;
  if (Array.isArray(status)) {
    if (status.length === 1) {
      [displayedStatus] = status;
    } else {
      displayedStatus = `${localize(status[0])} - ${localize(status[1])}`;
    }
  }

  return (
    <ConditionalWrapper
      condition={completion}
      wrapper={(childrenNodes) => (
        <CustomTooltip title={getCompletionTooltip(completion)}>
          <div>
            {childrenNodes}
          </div>
        </CustomTooltip>
      )}>
      <LocalizedText className={getClassName(completion, classes)}>{displayedStatus}</LocalizedText>
    </ConditionalWrapper>
  );
};

export default AuditStatusDisplay;