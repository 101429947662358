import React from 'react';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {GridLayout} from 'utils/helpers/GridLayout';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold'
  }
}));

export const ColumnDisplay = ({valueDict, verticalSpacing=2, horizontalSpacing=2}) => {
  const styles = useStyles();
  const valueEntries = Object.entries(valueDict);

  return (
    <GridLayout
      alignItems="flex-start"
      direction="column"
      spacing={verticalSpacing}
    >
      {valueEntries.map(([key, value], index) => {
        return (
          <GridLayout
            alignItems="flex-start"
            direction="row"
            justifyContent="space-around"
            spacing={horizontalSpacing}
          >
            <Typography className={styles.label}>
              <LocalizedText text={key} /> {":"}
            </Typography>
            <Typography>
              {value}
            </Typography>
          </GridLayout>
        )
      })}
    </GridLayout>
  )
}
      

ColumnDisplay.propTypes = {
  horizontalSpacing: PropTypes.number,
  valueDict: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  verticalSpacing: PropTypes.number
};