import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useFeatures } from 'hook/FeatureProvider';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GENERATE_ROUTE, GENERATE_TAB_VALUE, ROUTE_MATCHES } from 'utils/constants/routes';
import { GET_MENU_TABS } from 'utils/constants/tabs';

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: 3
  },
  tabs: {
    marginLeft: -10,
    [theme.breakpoints.down('md')]: {
      marginLeft: -30
    },
    color: theme.palette.common.white,
    width: 1100
  },
  tab: {
    minHeight: 67,
    minWidth: 50,
    padding: '3px 10px',
    fontSize: '112%',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.menuHover
    },
    '& .MuiTab-wrapper > *:first-child': {
      marginBottom: 3
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '95%'
    }
  }
}));

const HeaderMenu = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { contract } = useParams();
  const menuTabs = GET_MENU_TABS(useFeatures());
  const isRouteMatchingTab = menuTabs.some((currentTab) => ROUTE_MATCHES(location, currentTab.ROUTE));

  return (
    <Tabs
      centered
      classes={{
        indicator: classes.indicator
      }}
      className={classes.tabs}
      indicatorColor="secondary"
      textColor="secondary"
      // If no route are matching tab set no active value to prevent console error
      value={isRouteMatchingTab ? 0 : false}
      variant="fullWidth"
    >
      {menuTabs.map((currentTab) => (
        <Tab
          classes={{
            root: classes.tab
          }}
          icon={<CustomIcon icon={currentTab.ICON} size="lg" />}
          key={currentTab.LABEL}
          label={<LocalizedText className="bold" text={(currentTab.LABEL)} />}
          onClick={() => history.push(GENERATE_ROUTE(currentTab.ROUTE, { contract }))}
          value={GENERATE_TAB_VALUE(location, currentTab.ROUTE)}
        />
      ))}
    </Tabs>
  );
};

export default HeaderMenu;