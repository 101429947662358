import { faSuitcase } from '@fortawesome/pro-solid-svg-icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 525,
    margin: 10,
    padding: 0
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 2
    }
  },
  subCategoryHeader: {
    textAlign: 'center'
  },
  subCategory: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  },
  marginBlock: {
    margin: theme.spacing(1)
  },
  divider: {
    margin: 4
  },
  propertyContainerInline: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  complementaryInformation: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  partLabContainer: {
    color: theme.palette.warning.main,
    display: 'flex',
    alignItems: 'center'
  }
}));

const MultiLineDateProperty = ({ classes, propertyName, propertyValue }) => (
  <div className={classes.propertyContainer}>
    <LocalizedText className={classes.bold}>
      {propertyName}
    </LocalizedText>
    <Typography>
      {propertyValue ? timestampToDate(propertyValue) : '-'}
    </Typography>
  </div>
);

const LaboratoryCard = ({ laboratory }) => {
  const classes = useStyles();
  const {
    receptionScheduled, receptionReal, receptionDeadline, name, testReal, testScheduled, scrapping, partLab
  } = laboratory;
  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.cardHeader}>
        <Typography component="h5" variant="h6">
          {name}
        </Typography>
      </div>
      <CardContent className={classes.cardContent}>
        <LocalizedText className={classes.subCategoryHeader} component="h6" variant="h6">
            productReceptionDate
        </LocalizedText>
        <Divider className={classes.divider} />
        <div className={classes.subCategory}>
          <MultiLineDateProperty classes={classes} propertyName="scheduledProperty" propertyValue={receptionScheduled} />
          <MultiLineDateProperty classes={classes} propertyName="realProperty" propertyValue={receptionReal} />
          <MultiLineDateProperty classes={classes} propertyName="deadlineProperty" propertyValue={receptionDeadline} />
        </div>
        <LocalizedText className={classes.subCategoryHeader} component="h6" variant="h6">
          productTestDate
        </LocalizedText>
        <Divider className={classes.divider} />
        <div className={classes.subCategory}>
          <MultiLineDateProperty classes={classes} propertyName="scheduledProperty" propertyValue={testScheduled} />
          <MultiLineDateProperty classes={classes} propertyName="realProperty" propertyValue={testReal} />
        </div>
        <div className={classes.complementaryInformation}>
          <div className={classes.propertyContainerInline}>
            <LocalizedText className={classes.bold}>
              scrappingProperty
            </LocalizedText>
            &nbsp;
            <LocalizedText>
              {scrapping ? 'yes' : 'no'}
            </LocalizedText>
          </div>
          {
            partLab
            && (
              <div className={classes.partLabContainer}>
                <CustomIcon icon={faSuitcase} />
                &nbsp;
                <LocalizedText>
                part-lab
                </LocalizedText>
              </div>
            )
          }
        </div>
      </CardContent>
    </Card>
  );
};

export default LaboratoryCard;