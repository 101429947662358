import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ParamTypes } from 'utils/constants/param.type';
import { isEmpty } from 'utils/processing/filters';
import { mapValue, parseQueryStringValue, setQueryStringValue } from 'utils/processing/url';

/**
 * This is a hook to synchronize state with the querystring of the page, basically every modification of the state will be
 * projected inside the query string and if the querystring has a value when loading the page the initial state will be fetched
 * from there.
 *
 * @param key name of the property in the querystring
 * @param defaultValue initial value of the state if the query string does not contain the key
 * @param paramType ParamType (see the enumeration ParamTypes for available types) this is needed to convert the query string into
 * the right data type
 * @returns {*} the initialstate with the type provided and a function to set it which will update the query string
 */
export default function useQueryState(key, defaultValue, paramType = ParamTypes.STRING) {
  const { search } = useLocation();
  const parsedValue = parseQueryStringValue(key, search, paramType);
  const initialValue = isEmpty(parsedValue) ? defaultValue : parsedValue;
  const [value, setValue] = useState(mapValue(initialValue, paramType));
  const onSetValue = useCallback(
    (val) => {
      const newValue = mapValue(val, paramType);
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key, paramType]
  );
  return [value, onSetValue];
}