import { Grid, makeStyles } from '@material-ui/core';
import { TextLabel } from 'commons/Label/TextLabel';
import React from 'react';
import { AutoCompleteWithValue } from './AutoCompleteWithValue';

const useStyles = makeStyles(() => ({
  generalContainer: {
    height: '70px'
  }
}));

export const AutoCompleteWithLabel = ({
  label, disabled, getOptionLabel, isTranslatingOptions, value, options, required, isLoading, onChange, enableColorChangeOnModification,
  startsModified = false, className, valueName, isFree, isDoubleBound, isMultiple, textFieldLabel,
  limitTags, labelXS = 6, autoCompleteXS = 6,
  noOptionsText
}) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Grid alignItems='center' className={classes.generalContainer} container justifyContent='space-between' spacing={2} wrap='nowrap'>
        <Grid item xs={labelXS}>
          <TextLabel required={required} text={label}/>
        </Grid>
        <Grid item xs={autoCompleteXS}>
          <AutoCompleteWithValue
            className={className}
            disabled={disabled}
            enableColorChangeOnModification={enableColorChangeOnModification}
            getOptionLabel={getOptionLabel}
            isDoubleBound={isDoubleBound}
            isFree={isFree}
            isLoading={isLoading}
            isMultiple={isMultiple}
            isTranslatingOptions={isTranslatingOptions}
            label={textFieldLabel}
            limitTags={limitTags}
            noOptionsText={noOptionsText}
            onChange={value => onChange(value)}
            options={options}
            required={required}
            startsModified={startsModified}
            value={value}
            valueName={valueName}
            variant='outlined'
          />
        </Grid>
      </Grid>
    </Grid>
  )
};