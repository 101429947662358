import { useCallback, useState } from 'react';

/**
 * Hook to use filter state
 * returns an object to store filters as functions and a function to update filters
 * the difference between the updateFilters function and a normal setter is that
 * you specify a property name and a value to identify a filter and it will add it
 * or remove it if the value is null from the filters object
 * @returns {[{}, function]} filters object, function to update the filters
 */
export default function useFilters() {
  const [filters, setFilters] = useState({});

  const updateFilters = useCallback((valueName, filter) => {
    if (filter) {
      setFilters((prevFilter) => ({
        ...prevFilter,
        [valueName]: filter
      }));
    } else {
      const newFilters = { ...filters };
      if (newFilters[valueName]) {
        delete newFilters[valueName];
        setFilters(newFilters);
      }
    }
  }, [filters, setFilters]);

  return [filters, updateFilters];
}