/* eslint-disable no-param-reassign */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  labelText: {
    fontWeight: 'bold'
  },
  dataRow: {
    padding: theme.spacing(1)
  }
}
));

/**
 * Prints a row of data in the form  label: value
 * @param label the label of the row (translated)
 * @param value the value of the row (can be either a string which will be wrapped in text or a full fledged readt component)
 * @param isTranslate does the value needs translation (only if value is a string)
 */
export const DataRow = ({ label, value, isTranslate }) => {
  const classes = useStyles();
  if (value == null) {
    value = '-';
  }
  return (
    <Grid className={classes.dataRow} container direction="row" item>
      <Grid container item xs={5}>
        <LocalizedText className={classes.labelText}>
          {label}
        </LocalizedText>
        <Typography>
          :&nbsp;
        </Typography>
      </Grid>
      <Grid container item xs={7}>
        {
          React.isValidElement(value)
            ? (
              <>
                {value}
              </>
            )
            : isTranslate ? (
              <LocalizedText>
                {value}
              </LocalizedText>
            ) : (
              <Typography>
                {value}
              </Typography>
            )
        }
      </Grid>
    </Grid>
  );
};

DataRow.propTypes = {
  isTranslate: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired
};

DataRow.defaultProps = {
  isTranslate: false
};