import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { loginRequest, msalConfig } from "authConfig";
import { InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Spinner } from "commons/Spinner/Spinner";

const CustomAuthContext = createContext({});
export const useAuthContext = () => useContext(CustomAuthContext);

const msalInstance = new PublicClientApplication(msalConfig);

export const AuthProvider = ({children}) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthCustomProvider>
        <AuthTokenProvider>
          {children}
        </AuthTokenProvider>
      </AuthCustomProvider>
    </MsalProvider>
  )
}

export const AUTH_TOKEN_KEY = 'token'; // TODO: use uuid for key  ?

export const AuthCustomProvider = ({children}) => {

  const { instance, accounts, inProgress: interactionStatus } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const customLogout = useCallback(() => {
    handleMsalLogout(instance, accounts);
  }, [instance, accounts]);

  const customLogin = useCallback(() => {
    handleMsalLogin(instance);
  }, [instance]);

  const interactionIsInProgress = useMemo(() =>
    interactionStatus !== InteractionStatus.None
  , [interactionStatus]);

  const contextValue = useMemo(() => ({
    logIn: customLogin,
    logOut: customLogout,
    isLoggedIn: isAuthenticated,
    inProgress: interactionIsInProgress
  }), [customLogin, customLogout, isAuthenticated, interactionIsInProgress])

  return (
    <CustomAuthContext.Provider value={contextValue}>
      {/* We don't render children while login is in progress because we don't want any unwanted redirection
          while retrieving the necessary query params from the redirect url
          This would break the authentication flow and cause infinite redirections
       */}
      {interactionIsInProgress ? <Spinner/> : children}
    </CustomAuthContext.Provider>
  );

};

// --------- AUTH REQUIRED SECTION ---------

export const AuthRequiredSection = ({children}) => {
  const { logIn, isLoggedIn, inProgress: loginInProgress } = useAuthContext();

  useEffect(() => {
    if (!loginInProgress && !isLoggedIn) {
      logIn();
    }
  }, [isLoggedIn, loginInProgress, logIn])

  return (
    <AuthenticatedTemplate>
      {children}
    </AuthenticatedTemplate>
  )
}

// --------- TOKEN HANDLER ---------

const CustomAuthTokenContext = createContext({});
export const useAuthToken = () => useContext(CustomAuthTokenContext);

export const AuthTokenProvider = ({children}) => {
  const { isLoggedIn, inProgress } = useAuthContext();
  const [accessToken, setAccessToken] = useState();
  const [idToken, setIdToken] = useState();

  useEffect(() => {
    if (!inProgress && isLoggedIn) {
      acquireTokens(msalInstance).then(tokenResponse => {
        setAccessToken(tokenResponse.accessToken);
        setIdToken(tokenResponse.idToken);
      })
    }
  }, [inProgress, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && accessToken) {
      return setupOnTokenNearExpiration(accessToken, 300000, () => {
        acquireTokens(msalInstance, true).then(tokenResponse => {
          setAccessToken(tokenResponse.accessToken);
          setIdToken(tokenResponse.idToken);
        })
      });
    }
  }, [isLoggedIn, accessToken, idToken]);

  useEffect(() => {
    if (idToken) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, idToken);
    } else {
      sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
  }, [accessToken, idToken]);

  const contextValue = useMemo(() => ({
    token: idToken
  }), [idToken]);

  return (
    <CustomAuthTokenContext.Provider value={contextValue}>
      {/* We need to render children when not logged in to allow login redirect */}
      {/* We prevent rendering children when token are not loaded to avoid unauthorized api calls */}
      {!isLoggedIn || (accessToken && idToken) ? children : ""}
    </CustomAuthTokenContext.Provider>
  );
}

// --------- LOGIN/LOGOUT ---------

function handleMsalLogin(instance) {
  instance.loginRedirect(loginRequest).catch((error) => {
    console.error("Error while starting auth session");
    console.debug({error}) // TODO: check if debug logs are shown in Production (Security concern)
  });
}

function handleMsalLogout(instance, accounts) {
  let logoutRequest = {
    account: accounts[0],
    postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
  };
  instance.logoutRedirect(logoutRequest).catch((error) => {
    console.error("Error while ending auth session");
    console.debug({error}) // TODO: check if debug logs are shown in Production (Security concern)
  });
}

// --------- REFRESH TOKENS ---------

const acquireTokens = async (msalInstance, forceRefresh= false) => {
  // This will only return a non-null value
  // if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
    console.warn("User is not logged in");
  }

  const request = {
    scopes: ["User.Read"],
    account: activeAccount || accounts[0]
  };

  const silentRequest = {
    ...request,
    forceRefresh
  }

  let authResult;

  try {
    authResult = await msalInstance.acquireTokenSilent(silentRequest)
  } catch (error) {
    // acquireTokenSilent can fail for a number of reasons, fallback to interaction
    if (error instanceof InteractionRequiredAuthError) {
      if (window.confirm("Your session expired, do you wish to extend it ? " +
        "(If you select Cancel you'll be required to reload the page)"
      )) { // Prompt user before redirecting and loosing progress
        authResult = await msalInstance.acquireTokenRedirect(request)
      }
    } else {
      console.error("Error while renewing auth session");
      console.debug({error}) // TODO: check if debug logs are shown in Production (Security concern)
    }
  }

  if (!authResult) {
    throw new Error("No active session");
  }

  return {
    accessToken: authResult.accessToken,
    idToken: authResult.idToken
  }
};

function decodeJWT(token) {
  var base64Payload = token.split(".")[1];
  var payload = decodeURIComponent(
    atob(base64Payload)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(payload);
}

function extractTokenExpirationTime(token) {
  const jwtObject = decodeJWT(token)
  return jwtObject.exp * 1000; // seconds to millis
}

function getDelayBeforeExpiration(accessToken) {
  const expirationTime = extractTokenExpirationTime(accessToken); // ~ 80 min by manual testing
  return expirationTime - new Date().getTime(); // Delay in millis
}

function setupOnTokenNearExpiration(accessToken, triggerMarginBeforeExpirationInMillis, onTokenNearExpirationCallback) {
  const validityDuration = getDelayBeforeExpiration(accessToken);
  const timeUntilRefresh = Math.max(validityDuration - triggerMarginBeforeExpirationInMillis, 0);

  const timer = setTimeout(onTokenNearExpirationCallback, timeUntilRefresh);
  return () => clearTimeout(timer);
}