import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { GENERATE_ROUTE, GENERATE_TAB_VALUE, ROUTE_MATCHES } from 'utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: 3
  },
  tabs: {
    color: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1),
      marginTop: -8
    }
  },
  tab: {
    minWidth: '20%',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.menuHover
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      fontSize: '80%',
      minWidth: '24%'
    }
  }
}));

const TabsMenu = ({ role, tabs }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { contract, test, audit } = useRealParams();
  const isRouteMatchingTab = tabs.some((currentTab) => ROUTE_MATCHES(location, currentTab.ROUTE));

  /*   const matchingTabs = tabs.filter((currentTab) => ROUTE_MATCHES(location, currentTab.ROUTE));
   *   const isRouteMatchingTab = matchingTabs.length ?? false;
   *   const firstMatch = isRouteMatchingTab && tabs.indexOf(matchingTabs[0]);
   */
  function isAuthorized(currentTab) {
    if (currentTab?.AUTHORIZED) {
      return currentTab.AUTHORIZED?.includes(role)
    }
    return true;
  }

  return (
    <Tabs
      centered
      classes={{
        indicator: classes.indicator
      }}
      className={classes.tabs}
      indicatorColor="secondary"
      textColor="secondary"
      value={isRouteMatchingTab ? 0 : false}
    >
      {tabs.map((currentTab) => isAuthorized(currentTab) && (
        <Tab
          classes={{
            root: classes.tab
          }}
          icon={<CustomIcon icon={currentTab.ICON} size="lg"/>}
          key={currentTab.LABEL}
          label={<LocalizedText text={(currentTab.LABEL)}/>}
          onClick={() => history.push(GENERATE_ROUTE(currentTab.ROUTE, { contract, test, audit }))}
          value={GENERATE_TAB_VALUE(location, currentTab.ROUTE)}
        />
      ))}
    </Tabs>
  );
};

export default TabsMenu;