import * as Excel from "exceljs/dist/exceljs.min.js";
import saveAs from "file-saver";
import { localize } from "commons/LocalizedText/LocalizedText";

const initWorkbook = () => {
  const workbook = new Excel.Workbook();

  workbook.creator = 'CLARIS';
  workbook.lastModifiedBy = 'CLARIS';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  return workbook;
};

const addColumns = (columnStyle, headers) => (
  headers.map((elem) => {
    const translatedElem = localize(elem);
    return {
      header: translatedElem, key: elem, width: translatedElem.length + 4, style: columnStyle
    };
  })
);

const addRows = (json, headers, worksheet, rowStyle) => {
  json.forEach((elem) => {
    const modifiedElem = {};
    headers.forEach((prop) => {
      if (!elem[prop]) {
        modifiedElem[prop] = '';
      } else if (Array.isArray(elem[prop])) {
        const modifiedArray = elem[prop].map(subElem => (
          // subElem['text'] ? subElem['text'] : subElem
          localize(subElem.text || subElem.code || subElem.name || subElem.label || subElem)
        ));
        modifiedElem[prop] = modifiedArray.map(a => localize(a)).join(', ');
      } else if (elem[prop].text) {
        modifiedElem[prop] = localize(elem[prop].text);
      }else{
        modifiedElem[prop] = localize(elem[prop]);
      }
    });
    const row = worksheet.addRow(modifiedElem);
    // Modify row style
    const rowProps = Object.keys(rowStyle);
    rowProps.forEach((prop) => {
      row[prop] = rowStyle[prop];
    });
  });
};

const hideUnwantedColumns = (hiddenColumns, worksheet) => {
  hiddenColumns.forEach((elem) => {
    const col = worksheet.getColumn(elem);
    col.hidden = true;
  });
};

const writeXLSX = (workbook, filename) => {
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${filename}.xlsx`);
  });
};

/**
 *
 * @param json Contains results that we want to display in the sheet; highest level of the JSON = column names
 * @param filename Name of the excel file (without extension) that will be downloaded
 * @param hiddenColumns list of columns we want to hide in the sheet; should be an array like ['recid', 'precision']
 * @param rowStyle add style to all rows with the following format : { font: { size: 12, bold: true } }
 * @param columnStyle add style to all columns with the following format : { font: { size: 12, bold: true } }
 *
 * Learn more about Exceljs and possibilities regarding styling here: https://www.npmjs.com/package/exceljs
 */
export const convertJsonToXlsx = (
  json,
  filename,
  hiddenColumns = [],
  rowStyle = { font: { size: 10 } },
  columnStyle = { font: { size: 12, bold: true } }
) => {
  const workbook = initWorkbook();
  const worksheet = workbook.addWorksheet(localize('sheet1'));
  const headers = Object.keys(json[0]);

  worksheet.columns = addColumns(columnStyle, headers);

  addRows(json, headers, worksheet, rowStyle);

  hideUnwantedColumns(hiddenColumns, worksheet);

  writeXLSX(workbook, filename);
};