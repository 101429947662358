/* eslint-disable max-len */
import { AdministrationGeneralPage } from 'pages/administration/AdministrationGeneralPage';
import { AdministrationNewsPage } from 'pages/administration/AdministrationNewsPage';
import { AdministrationNewsEditPage } from 'pages/administration/AdministrationNewsEditPage';
import { AdministrationUsersPage } from 'pages/administration/AdministrationUsersPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ContractRoute from 'hook/ContractRoute';

import { ROUTES } from 'utils/constants/routes';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import { isSuperAdmin } from 'utils/constants/roles';
import { AdministrationCreateUserPage } from "../pages/administration/AdministrationCreateUserPage";

export const AdministrationRoutes = () => {
  const { role } = useCustomerInfo();

  return (
    <Switch>
      <ContractRoute path={ROUTES.ADMINISTRATION.GENERAL} render={(props) => <AdministrationGeneralPage {...props} />} />
      <ContractRoute exact path={ROUTES.ADMINISTRATION.NEWS.CREATE} render={(props) => <AdministrationNewsEditPage {...props} />} />
      <ContractRoute exact path={ROUTES.ADMINISTRATION.NEWS.EDIT} render={(props) => <AdministrationNewsEditPage {...props} />} />
      <ContractRoute path={ROUTES.ADMINISTRATION.NEWS.BASE} render={(props) => <AdministrationNewsPage {...props} />} />
      <ContractRoute path={ROUTES.ADMINISTRATION.USERS.CREATE} render={(props) => <AdministrationCreateUserPage {...props} />} />
      <ContractRoute path={ROUTES.ADMINISTRATION.USERS.EDIT} render={(props) => <AdministrationCreateUserPage {...props} />} />
      <ContractRoute path={ROUTES.ADMINISTRATION.USERS.BASE} render={(props) => <AdministrationUsersPage {...props} />} />
      <Redirect
        from={ROUTES.ADMINISTRATION.BASE}
        to={isSuperAdmin(role) ? ROUTES.ADMINISTRATION.GENERAL : ROUTES.ADMINISTRATION.USERS.BASE}
      />
    </Switch>
  );
};