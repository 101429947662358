import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useContractInfo } from 'hook/ContractInfoProvider';
import React, { Fragment } from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  separator: {
    borderRight: `2px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      borderRight: 'none'
    }
  },
  generalContainer: {
    display: '-webkit-inline-box',
    flexWrap: 'wrap'
  },
  generalText: {
    fontWeight: 'bold'
  },
  gridPadding: {
    padding: theme.spacing(1)
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  gridPoints: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

export const ContractGeneralTab = ({ data }) => {
  const classes = useStyles();
  const contractInfo = useContractInfo();

  const {
    subProgramCodes, externalExports, points, totalPoints, ...rest
  } = data ?? [];

  rest.subProgramCodes = (subProgramCodes && subProgramCodes.length) ? subProgramCodes.join() : '-';
  rest.externalExports = (externalExports && externalExports.length) ? externalExports.join() : '-';

  const narrow = contractInfo.isContractPointsActivated;

  return (
    <div className={classes.centeredFlex}>
      <Grid className={`${classes.generalContainer} ${narrow && classes.separator}`} container item sm={narrow ? 6 : 12} xs={12}>
        {Object.entries(rest).map(([key, value]) => (
          <Fragment key={key}>
            <Grid container>
              <Grid className={classes.gridPadding} item sm={6} xs={6}>
                <Typography className={classes.generalText}>
                  <LocalizedText text={key} />
                  {': '}
                </Typography>
              </Grid>
              <Grid className={classes.gridPadding} item sm={6} xs={6}>
                <Typography>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      {
        contractInfo.isContractPointsActivated
        && (
          <Grid className={`${classes.generalContainer} ${classes.gridPoints}`} container item sm={6} xs={12}>
            <Grid className={classes.gridPadding} item sm={6} xs={6}>
              <Typography>
                <LocalizedText text="points" />
              </Typography>
            </Grid>
            <Grid className={classes.gridPadding} item sm={6} xs={6}>
              {points !== undefined && (
                <Typography variant="h4">
                  {`${points}/${totalPoints}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        )
      }
    </div>
  );
};