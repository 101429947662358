import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import CampaignCard from 'components/CampaignCard/CampaignCard';
import ListingInfo from 'components/ListingInfo/ListingInfo';
import { PageContainer } from 'components/PageContainer/PageContainer';
import RangeCard from 'components/RangeCard/RangeCard';
import SkeletonCampaignCard from 'components/Skeletons/SkeletonCampaignCard/SkeletonCampaignCard';
import SkeletonRangeCard from 'components/Skeletons/SkeletonRangeCard/SkeletonRangeCard';
import useFetch from 'hook/UseFetch';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DashboardService } from 'services/DashboardService';
import { NewsPanel } from 'components/News/NewsPanel';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1)
  }
}));

export const HomePage = () => {
  const { contract: contractId } = useParams();
  const classes = useStyles();
  const { response: campaigns, isLoading } = useFetch(() => DashboardService.getCampaignsInfos(contractId), []);
  const customerRangesByContract = useFetch((() => DashboardService.getCustomerRangesByContract(contractId)), []).response;
  const totalRanges = customerRangesByContract.reduce((sum, cur) => sum + cur.count, 0);

  function generateCampaignCards() {
    return campaigns.length
      ? campaigns.sort((c1, c2) => c2.referenceYear - c1.referenceYear)
        .map((campaign) => <CampaignCard
          campaign={campaign}
          contract={contractId}
          key={campaign.referenceYear}/>)
      : <LocalizedText>noRecentCampaignFound</LocalizedText>;
  }

  return (
    <>
      <NewsPanel/>
      <PageContainer>
        <LocalizedText className={classes.title} component="h3" variant="h5">
          dashboardRanges
        </LocalizedText>
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly"
        >
          {customerRangesByContract.length > 0
            ? customerRangesByContract.map((range) => (
              <RangeCard
                key={range.status}
                range={range}
                totalRanges={totalRanges}
              />
            ))
            : (
              <>
                <SkeletonRangeCard />
                <SkeletonRangeCard />
                <SkeletonRangeCard />
                <SkeletonRangeCard />
              </>
            )}
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="flex-start"
        >
          <LocalizedText className={classes.title} component="h4" variant="h5">
            dashboardCampaigns
          </LocalizedText>
          <ListingInfo contract={contractId}/>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly"
        >
          {
            isLoading
              ? (
                <>
                  <SkeletonCampaignCard />
                  <SkeletonCampaignCard />
                </>
              )
              : generateCampaignCards()
          }
        </Grid>
      </PageContainer>
    </>
  );
};