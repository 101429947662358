/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { localize } from 'commons/LocalizedText/LocalizedText';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: props => ({
    flexGrow: 1,
    width: props.isMultiple ? '100%' : '50%',
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: !props.isMultiple && '0 5px'
      }
    },
    "& .MuiFormLabel-root": {
      "&.MuiInputLabel-outlined": {
        transform: props.hasValue ? 'transform: translate(14px, -6px) scale(0.75)' : 'translate(14px, 12px) scale(1)'
      }
    },
    "& .MuiFormControl-marginNormal":{
      marginTop: '8px'
    }
  }),
  inputRoot: {
    '& .MuiAutocomplete-tagSizeSmall': {
      maxWidth: 'calc(40% - 4px)'
    }
  },
  modified: {
    '& fieldset': {
      borderColor: '#31A597',
      borderWidth: '2px'
    }
  }
}));

/**
 * AutoComplete is a multi-select list; selected value(s) is(are) synchronized with the query string
 * @param options list of available options
 * @param label translated label of the field
 * @param getOptionLabel
 * @param required when true, displays a red star next to the label
 * @param isFree is selection of items not in the list possible
 * @param isDoubleBound
 * @param isMultiple is the field accepting multiple selects
 * @param className is used to apply style
 * @param isTranslatingOptions function to translate options if needed
 * @param isLoading do the data are loading
 * @param value value to display
 * @param onChange is the function triggered when the value changes
 * @param limitTags is the maximum number of values to be displayed when its collapsed
 * @param noOptionsText text to display when no available option
 * @param onChangeTextField is the function triggered when the textfield value changes
 * @param variant the variation of the textfield inside the autocomplete
 * @param error is the is an error
 * @param helperText is the text to display in the helper in case of error
 * @param invertSort
 * @param startsModified shows item as modified on tab loading
 * @param enableColorChangeOnModification enable the color changement when the value is changed
 * @param disabled disable modification
 * @param getOptionSelected
 */
export const AutoCompleteWithValue = ({
  options,
  label,
  getOptionLabel,
  required,
  isFree,
  isDoubleBound,
  isMultiple,
  className,
  isTranslatingOptions,
  value,
  onChange,
  isLoading,
  limitTags = 3,
  noOptionsText,
  onChangeTextField,
  variant,
  error,
  helperText,
  invertSort = false,
  startsModified = false,
  enableColorChangeOnModification,
  disabled,
  getOptionSelected = null
}) => {
  const classes = useStyles({isMultiple, hasValue: !!value});
  const [isModified, setIsModified] = useState(startsModified);
  const [realOptions, setRealOptions] = useState(options);

  useEffect(() => {
    const optionsArray = Array.from(new Set(options.filter((option) => option != null).sort()));
    if(invertSort){
      setRealOptions(optionsArray.reverse());
    }else{
      setRealOptions(optionsArray);
    }
  }, [options]);

  const handleOnChangeTextField = (value) => {
    onChangeTextField(value);
    if(enableColorChangeOnModification) setIsModified(true);
  }

  const handleOnChange = (event, value) => {
    onChange(value);
    if(enableColorChangeOnModification) setIsModified(true);
  }
  const  internalGetOptionLabel = option => {
    const optionLabel = (getOptionLabel) ? getOptionLabel(option) : option
    return isTranslatingOptions ? localize(optionLabel) : optionLabel
  }

  const isOptionEqualToValue = (option, value) => {
    if(getOptionSelected){
      return getOptionSelected(option, value)
    }
    return internalGetOptionLabel(option) === internalGetOptionLabel(value)
  }

  return (
    <Autocomplete
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot
      }}
      className={`${classes.root} ${className}`}
      disabled={disabled}
      filterSelectedOptions
      freeSolo={isFree}
      getOptionLabel={internalGetOptionLabel}
      getOptionSelected={isOptionEqualToValue}
      isDoubleBound={isDoubleBound}
      isOption
      limitTags={limitTags}
      loading={isLoading}
      loadingText={localize('loadingText')}
      multiple={isMultiple}
      noOptionsText={localize(noOptionsText)}
      onChange={handleOnChange}
      options={realOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={error ? helperText : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <div style={{marginRight: '25px', color:'black'}}><CircularProgress color='inherit' size={20} /></div>}
                {params.InputProps.endAdornment}
              </>
            ),
            className: `${params.InputProps.className} ${isModified && classes.modified}`
          }}
          label={localize(label)}
          margin="normal"
          onChange={e => handleOnChangeTextField(e.target.value)}
          required={required}
          variant={variant}
        />
      )}
      size="medium"
      value={value}
    />
  );
};

AutoCompleteWithValue.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isFree: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isTranslatingOptions: PropTypes.bool,
  label: PropTypes.string,
  onChangeTextField: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  variant: PropTypes.string
};

AutoCompleteWithValue.defaultProps = {
  className: '',
  isFree: false,
  isMultiple: false,
  isTranslatingOptions: false,
  onChangeTextField: () => {},
  options: [],
  variant: 'standard'
};