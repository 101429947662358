import { faUserGear } from '@fortawesome/pro-solid-svg-icons';
import { ButtonBase } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DialogType } from 'commons/Dialog/CustomDialog';
import CustomIcon from 'commons/Icon/CustomIcon';
import { LanguagePicker } from 'commons/LanguagePicker/LanguagePicker';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { useDialog } from 'hook/DialogProvider';
import { useFeatures } from 'hook/FeatureProvider';
import { useLocales } from 'hook/LocalesProvider';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LanguageService } from 'services/LanguageService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { isAdmin } from 'utils/constants/roles';
import { GENERATE_ROUTE, GENERATE_TAB_VALUE } from 'utils/constants/routes';
import { GET_MENU_TABS, MENU_TABS_OBJECT } from 'utils/constants/tabs';
import { extractInitials } from 'utils/processing/string';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';
import useMobile from 'hook/UseMobile';
import { NewsMenuButton } from 'components/News/NewsMenuButton';
import Fab from '@material-ui/core/Fab';
import { useAuthContext } from "hook/AuthProvider";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 50,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: 36
    }
  },
  menu: {
    '& ul div, svg': {
      color: theme.palette.primary.main,
      '&:hover': {
        '& p': {
          color: theme.palette.secondary.main
        },
        '& svg': {
          color: theme.palette.secondary.main
        }
      }
    },
    minWidth: 350,
    [theme.breakpoints.down('sm')]: {
      minWidth: 320
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      minWidth: 260
    }
  },
  selectedTab: {
    '& p, svg': {
      color: theme.palette.secondary.main
    }
  },
  languagePicker: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '& div div fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& div label': {
      color: theme.palette.primary.main
    }
  },
  profile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    textTransform: 'uppercase',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: 45,
      width: 45
    }
  },
  profileInfos: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexGrow: 1
  },
  infoContainer: {
    padding: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  userAdminSection: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    minWidth: '100%'
  },
  userAdminContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '150%',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      '& svg': {
        color: theme.palette.secondary.main
      }
    }
  },
  newsButton: {
    position: 'relative',
    flexShrink: 0,
    height: 50,
    width: 50,
    maxWidth: 50,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark
    },
    marginLeft: theme.spacing(2)
  }
}));

const BurgerMenu = ({ setDisplayBurger }) => {
  const history = useHistory();
  const location = useLocation();
  const { contract } = useParams();
  const account = useCustomerInfo();
  const isMobile = useMobile();
  const classes = useStyles();
  const showDialog = useDialog();
  const {
    role, userName, firstName, lastName
  } = account;
  const locales = useLocales();
  const [locale, setLocale] = useState(locales[locales.findIndex((loc) => loc.current)]);
  const menuTabs = GET_MENU_TABS(useFeatures());
  const {logOut} = useAuthContext();

  useEffect(() => {
    setLocale(locales[locales.findIndex((loc) => loc.current)]);
  }, [locales]);

  const changeLocale = (newLocale) => {
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: localize('changeLanguageConfirmation', {
        lang: newLocale.name
      }),
      onSubmit: () => LanguageService.changeLocale({ lang: newLocale.languageCode })
        .then(() => {
          setLocale(locale);
          window.location.reload();
        })
    });
  };

  return (
    <div
      className={classes.menu}
      role="presentation"
    >
      <Grid alignItems="center" className={classes.infoContainer} container direction="row">
        <div aria-label="user" className={classes.profile}>
          <Typography component="h6" variant="h6">
            {extractInitials(userName)}
          </Typography>
        </div>
        <div className={classes.profileInfos}>
          <Typography className={classes.bold}>
            {firstName}
            {' '}
            {lastName}
          </Typography>
          <LocalizedText>{role}</LocalizedText>
          <Grid className={classes.userAdminSection}>
            {locale && <img alt="language" className={classes.image} 
              src={getAssetUrl(`/assets/images/flags/${locale.countryCode}.png`)}/>}
            {!isMobile && (
              <ButtonBase
                className={classes.userAdminContainer}
                onClick={() => {
                  setDisplayBurger(false);
                  return history.push(GENERATE_ROUTE(MENU_TABS_OBJECT.ACCOUNTS.ROUTE, { contract }));
                }}>
                <LocalizedText>
                  myAccount
                </LocalizedText>
                &nbsp;
                <CustomIcon icon={faUserGear}/>
              </ButtonBase>
            )}
          </Grid>
        </div>
        {isMobile && (
          <Fab aria-label="user" className={classes.newsButton} size="medium">
            <NewsMenuButton/>
          </Fab>
        )}
      </Grid>
      <Divider />
      <List>
        {menuTabs.map((tab) => (
          <ListItem
            button
            className={GENERATE_TAB_VALUE(location, tab.ROUTE) === 0 ? classes.selectedTab : ''}
            key={tab.LABEL}
            onClick={() => {
              setDisplayBurger(false);
              history.push(GENERATE_ROUTE(tab.ROUTE, { contract }));
            }}
          >
            <ListItemIcon>
              <CustomIcon icon={tab.ICON} size="lg" />
            </ListItemIcon>
            <ListItemText primary={<LocalizedText>{tab.LABEL}</LocalizedText>} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {
          isAdmin(role)
          && (
            <ListItem
              button
              onClick={() => {
                setDisplayBurger(false);
                return history.push(GENERATE_ROUTE(MENU_TABS_OBJECT.ADMINISTRATION.ROUTE, { contract }));
              }}
            >
              <ListItemIcon>
                <CustomIcon icon={MENU_TABS_OBJECT.ADMINISTRATION.ICON} />
              </ListItemIcon>
              <ListItemText primary={<LocalizedText>{MENU_TABS_OBJECT.ADMINISTRATION.LABEL}</LocalizedText>} />
            </ListItem>
          )
        }
        {
          isMobile && (
            <ListItem
              button
              onClick={() => {
                setDisplayBurger(false);
                return history.push(GENERATE_ROUTE(MENU_TABS_OBJECT.ACCOUNTS.ROUTE, contract));
              }}
            >
              <ListItemIcon>
                <CustomIcon icon={faUserGear} />
              </ListItemIcon>
              <ListItemText primary={<LocalizedText>myAccount</LocalizedText>} />
            </ListItem>
          )
        }
        <ListItem
          button
          onClick={() => logOut()}
        >
          <ListItemIcon>
            <CustomIcon icon={MENU_TABS_OBJECT.LOGOUT.ICON} />
          </ListItemIcon>
          <ListItemText primary={<LocalizedText>{MENU_TABS_OBJECT.LOGOUT.LABEL}</LocalizedText>} />
        </ListItem>
      </List>
      <Divider />
      <LanguagePicker
        className={classes.languagePicker}
        id="locale-selection"
        label="chooseYourLanguage"
        locales={locales}
        onChange={changeLocale}
        value={locale}
      />
    </div>
  );
};

export default BurgerMenu;