import { faBars, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from 'commons/Button/CustomButton';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { Triangle } from 'commons/Triangle/Triangle';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';
import { extractInitials } from 'utils/processing/string';

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    height: 67,
    padding: 5,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%'
    }
  },
  headerButton: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 10
  },
  profileButton: {
    position: 'relative',
    flexShrink: 0,
    height: 50,
    width: 50,
    maxWidth: 50,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  menuButton: {
    position: 'absolute',
    top: 25,
    left: 30,
    width: 25,
    minHeight: 25,
    height: 25,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white
    }
  },
  contractInfoContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contractInfo: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(1)
  },
  contractInfoNotLoaded: {
    maxWidth: 30,
    opacity: 0
  },
  contractInfoLoaded: {
    maxWidth: 130,
    opacity: 1,
    transition: 'max-width 0.3s, opacity 0.5s',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 230
    }
  },
  programCode: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    border: '1px solid white',
    backgroundColor: theme.palette.primary.main
  },
  editIcon: {
    flexShrink: 0
  },
  flexFix: {
    display: 'flex',
    minWidth: 0
  }
}));

const HeaderLeft = ({ onBurgerClick, contractInfo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { userName } = useCustomerInfo();

  const goToChooseContract = () => {
    history.push(GENERATE_ROUTE(`${ROUTES.CHOICE}?back=true`));
  };

  const tooltipFullInfo = (
    <>
      <LocalizedText text="programProperty" />
      &nbsp;
      {contractInfo.programCode}
      <br />
      {
        (Array.isArray(contractInfo.subProgramCodes) && contractInfo.subProgramCodes.length > 0
          && (
            <>
              <LocalizedText text="subprogramProperty" />
              &nbsp;
              {contractInfo.subProgramCodes.join(', ')}
              <br />
            </>
          )
        )
      }
      <LocalizedText text="companyProperty" />
      &nbsp;
      {contractInfo.companyName}
      <br />
      <LocalizedText text="contractNumberProperty" />
      &nbsp;
      {contractInfo.contractNumber}
      <br />
    </>
  );

  const oneContractOnly = contractInfo.numberOfContracts <= 1;

  return (
    <>
      <div className={classes.background}>
        <Fab aria-label="user" className={classes.profileButton} onClick={onBurgerClick} size="medium">
          {userName && extractInitials(userName)}
          <Fab aria-label="burger" className={classes.menuButton} component="div" size="small">
            <CustomIcon icon={faBars} size="xs" />
          </Fab>
        </Fab>
        <CustomButton
          className={classes.headerButton}
          onClick={goToChooseContract}
          tooltip={tooltipFullInfo}
          variant={(oneContractOnly) ? 'disabled' : 'header'}
        >
          <div className={classes.contractInfoContainer}>
            <Chip className={classes.programCode} disabled={oneContractOnly} label={contractInfo.programCode} size="small" />
            <div className={classes.flexFix}>
              <span
                className={
                  `${classes.contractInfo} ${contractInfo.companyName
                    ? classes.contractInfoLoaded
                    : classes.contractInfoNotLoaded}`
                }
              >
                {contractInfo.companyName}
              </span>
            </div>
            {
              contractInfo.numberOfContracts > 1 && (
                <div>
                  <CustomIcon className={classes.editIcon} icon={faEdit} />
                </div>
              )
            }
          </div>
        </CustomButton>
      </div>
      <Triangle backgroundColor={theme.palette.primary.main} height={67} notOnMobile width={60} />
    </>
  );
};

export default HeaderLeft;