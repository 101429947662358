/* eslint-disable max-len */
import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const customerServiceUrl = `${API_URL}/customer`;

const searchUsers = (searchParams={}, signal=undefined) => RequestHelper.POST(`${customerServiceUrl}/search`, searchParams, {signal});

const getCustomerInfo = () => RequestHelper.GET(`${customerServiceUrl}/customerInfo`);

const getCustomersWithContract = (contractId) => RequestHelper.GET(`${customerServiceUrl}/customers/${contractId}`);

const getDebugInfos = () => RequestHelper.GET(`${customerServiceUrl}/debug`);

const postBugReport = (data) => RequestHelper.POST(`${customerServiceUrl}/bugreport`, data);

const removeImpersonation = () => RequestHelper.POST(`${customerServiceUrl}/removeImpersonation`, {});

const setImpersonation = (id) => RequestHelper.POST(`${customerServiceUrl}/setImpersonation/${id}`, {});

const getRoles = () => RequestHelper.GET(`${customerServiceUrl}/roles`);

const getCivilities = () => RequestHelper.GET(`${customerServiceUrl}/civilities`);

const getCustomerRoles = () => RequestHelper.GET(`${customerServiceUrl}/customerRoles`);

const getAccountStatuses = () => RequestHelper.GET(`${customerServiceUrl}/accountStatuses`);

export const CustomerService = {
  searchUsers,
  getCivilities,
  getCustomerInfo,
  getCustomerRoles,
  getCustomersWithContract,
  getDebugInfos,
  getRoles,
  getAccountStatuses,
  postBugReport,
  removeImpersonation,
  setImpersonation
};