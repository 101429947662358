/* eslint-disable no-useless-escape */
import { makeStyles } from '@material-ui/core';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  requiredEnd: {
    color: 'red'
  }
}));

export const TextLabel = ({text, required, className, ...props}) => {
  const classes = useStyles();

  return (
    <div className={[classes.label, className].join(' ')} {...props}>
      <LocalizedText align='right' isTextLabel>
        {text}
      </LocalizedText>
      { required &&
        <span className={classes.requiredEnd}>*</span>
      }
    </div>
  )
};