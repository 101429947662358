import { faDownload, faUpload } from '@fortawesome/pro-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from 'commons/Button/CustomButton';
import { DialogType } from 'commons/Dialog/CustomDialog';
import CustomIcon from 'commons/Icon/CustomIcon';
import { LanguagePicker } from 'commons/LanguagePicker/LanguagePicker';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { useDialog } from 'hook/DialogProvider';
import { useLocales } from 'hook/LocalesProvider';
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import React, { useEffect, useState } from 'react';
import { LanguageService } from 'services/LanguageService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  languagePicker: {
    maxWidth: 400,
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  noMarginLeftMobile: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: 0
    }
  },
  buttonContainer: {
    maxWidth: 400
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  }
}));

export const ManageLanguages = () => {
  const classes = useStyles();
  const showDialog = useDialog();
  const showSnackbar = useLocalizedSnackBar();
  const locales = useLocales();
  const [adminLanguage, setAdminLanguage] = useState(null);

  useEffect(() => {
    setAdminLanguage(locales[locales.findIndex((locale) => locale.current)]);
  }, [locales]);

  const importLanguageFile = () => {
    const fileData = document.querySelector('input[type="file"]').files[0];
    const data = new FormData();
    data.append('file', fileData);
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: localize('importSelectedFileConfirmationForLang', {
        fileName: fileData.name,
        lang: adminLanguage.name
      }),
      onSubmit: () => LanguageService.importLanguageFile(adminLanguage.languageCode, data)
        .then(() => showSnackbar('languageImportTakenIntoAccount', { variant: 'success' }))
        .catch(() => showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: 'errorWhileImportingFile'
        }))
    });
  };

  const exportLanguage = () => {
    LanguageService.exportLanguageFile(adminLanguage.languageCode)
      .catch(() => showDialog({
        variant: DialogType.ERROR,
        title: 'error',
        description: 'errorWhileExportingFile'
      }));
  };

  return (
    <div className={classes.marginTop}>
      <LocalizedText className={classes.sectionTitle} component="h5" variant="h5">
              languageManagement
      </LocalizedText>
      <Grid
        alignItems="center"
        className={`${classes.margin} ${classes.noMarginLeftMobile}`}
        container
        direction="row"
      >
        <LanguagePicker
          className={classes.languagePicker}
          id="admin-language-selection"
          label="chooseLanguageForImportExport"
          locales={locales}
          onChange={setAdminLanguage}
          value={adminLanguage}
        />
        <Grid
          alignItems="center"
          className={classes.buttonContainer}
          container
          direction="row"
        >
          <CustomButton
            className={classes.margin}
            onClick={exportLanguage}
          >
            <CustomIcon icon={faDownload} />
            <span>&nbsp;</span>
                  exportFile
          </CustomButton>
          <CustomButton
            className={classes.margin}
            component="label"
          >
            <CustomIcon icon={faUpload} />
            <span>&nbsp;</span>
                  uploadFile
            <input
              onChange={importLanguageFile}
              style={{ display: 'none' }}
              type="file"
            />
          </CustomButton>
        </Grid>
      </Grid>
    </div>

  );
};