import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import CustomIcon from 'commons/Icon/CustomIcon';
import Fab from '@material-ui/core/Fab';
import {CustomTooltip} from 'commons/Tooltip/CustomTooltip';
import {ConditionalWrapper} from 'hook/ConditionalWrapper';

const useStyles = makeStyles((theme) => ({
  button: (isMainButton) => ({
    cursor: 'pointer',
    height:  isMainButton ? 90 : 45,
    width: isMainButton ? 90 : 45,
    fontSize: '200%',
    backgroundColor: isMainButton ? theme.palette.primary.main : theme.palette.background.paper
  }),
  icon: (isMainButton) => ({
    color: isMainButton ? theme.palette.background.paper : theme.palette.primary.main
  })
}));

export const FloatingButton = ({
  onClick, tooltip = null, icon, disabled, isMainButton = false
}) => {
  const classes = useStyles(isMainButton);

  const size = isMainButton ? 'lg' : 'xs';

  return (
    <ConditionalWrapper
      condition={tooltip && !disabled}
      wrapper={(childrenNodes) => (
        <CustomTooltip title={tooltip}>
          {childrenNodes}
        </CustomTooltip>
      )}
    >
      <Fab
        aria-label="add"
        className={classes.button}
        disabled={disabled}
        onClick={onClick}
      >
        <CustomIcon className={classes.icon} icon={icon} size={size}/>
      </Fab>
    </ConditionalWrapper>
  );
}

FloatingButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  scale: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};