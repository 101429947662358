import useFetch from 'hook/UseFetch';
import useRealParams from 'hook/UseRealParams';
import React, { createContext, useContext, useMemo } from 'react';
import { ContractService } from 'services/ContractService';

const ContractContext = createContext({});
/**
 * @typedef {Object} ContractInfo
 * @property {string} programCode
 * @property {string[]} subProgramCodes
 * @property {string} companyName
 * @property {number} numberOfContracts
 * @property {boolean} isRangeCertification
 * @property {boolean} isBillsToPay
 * @property {boolean} isContractPointsActivated
 */

// noinspection JSValidateTypes
/**
 * Return object containing contract information apart from the name already available in the url
 * @returns {ContractInfo}
 */
export const useContractInfo = () => useContext(ContractContext);

export const ContractInfoProvider = ({ children }) => {
  const { contract: contractId } = useRealParams();
  const contractInfo = useFetch(() => ContractService.getContractInfo(contractId), {isLoading: true});

  const contextValue = useMemo(() => ({
    ...contractInfo.response,
    isLoading: contractInfo?.isLoading
  }), [contractInfo])

  return (
    <ContractContext.Provider value={contextValue}>
      {children}
    </ContractContext.Provider>
  );
};