/* eslint-disable no-param-reassign */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import TestStatusDisplay from 'commons/StatusDisplay/TestStatusDisplay';
import React, { Fragment } from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  separator: {
    borderRight: `2px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      borderRight: 'none'
    }
  },
  afterSeparator: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      paddingLeft: theme.spacing(1)
    }
  },
  generalContainer: {
    display: '-webkit-inline-box',
    flexWrap: 'wrap'
  },
  generalText: {
    fontWeight: 'bold'
  },
  gridPadding: {
    padding: theme.spacing(1)
  }
}
));

const colorTestStatusCustomer = (data) => {
  const { testStatusCustomer, testStatus } = data;
  if (testStatusCustomer && testStatus) {
    const testOk = testStatus === 'CLARIS_OK';
    data.testStatusCustomer = <TestStatusDisplay isSuccess={testOk} status={testStatusCustomer} />;
  }
};

const convertFieldsToDate = (data, fields) => {
  fields.forEach((field) => {
    if (data[field]) {
      data[field] = timestampToDate(data[field]);
    }
  });
};

const translateFields = (data, fields) => {
  fields.forEach((field) => {
    if (data[field]) {
      data[field] = localize(data[field]);
    }
  });
};

export const GeneralTab = ({ data, separator }) => {
  const classes = useStyles();
  const dataCopy = { ...data };
  convertFieldsToDate(dataCopy, ['resultSendDate']);
  colorTestStatusCustomer(dataCopy);
  translateFields(dataCopy, ['productStatus', 'testStatus', 'testType']);
  return (
    <>
      <Grid className={classes.generalContainer} container item sm={6} xs={12}>
        {
          Object.entries(dataCopy).map(([key, value]) => (
            <Fragment key={key}>
              {value && (
                <>
                  <Grid className={separator ? classes.gridPadding : classes.afterSeparator} container item xs={6}>
                    <Typography className={classes.generalText}>
                      <LocalizedText text={key} />
                      {': '}
                    </Typography>
                  </Grid>
                  <Grid className={separator ? classes.separator : classes.gridPadding} container item xs={6}>
                    {
                      React.isValidElement(value)
                        ? value : (
                          <Typography>
                            {value}
                          </Typography>
                        )
                    }
                  </Grid>
                </>
              )}
            </Fragment>
          ))
        }
      </Grid>
    </>
  );
};