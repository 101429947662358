/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType } from 'commons/Dialog/CustomDialog';
import { useDialog } from 'hook/DialogProvider';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';

/**
 * Base hook to wrap basic useEffect fetch (with no dependencies) and handle error popup
 * @param serviceRequest a Service function (GET, POST...) returning a json promise
 * @param initialState optional parameter to have an initial response
 * @returns {{isLoading: boolean, response: json, error: json}}
 */
export default function useFetch(serviceRequest, initialState = null) {
  const [response, setResponse] = useState(initialState);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const showDialog = useDialog();
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const json = await serviceRequest();
        setResponse(json);
        setIsLoading(false);
      } catch (errorMessage) {
        setIsLoading(false);
        // If there is one of these two errors
        // it means the customer cannot acquire anything (not in ECC Database) so we can't use translations
        if (errorMessage === 'notEccCustomer') {
          showDialog({
            variant: DialogType.ERROR,
            title: 'Authentication error',
            description: 'You are not registered as an ECC customer'
          });
          history.push(GENERATE_ROUTE(ROUTES.ERROR));
        } else if (errorMessage === 'notActivated') {
          showDialog({
            variant: DialogType.ERROR,
            title: 'Authentication error',
            description: 'Your account is disabled'
          });
          history.push(GENERATE_ROUTE(ROUTES.ERROR));
        } else {
          showDialog({
            variant: DialogType.ERROR,
            title: 'error',
            description: errorMessage
          });
        }
        setError(errorMessage);
      }
    };
    fetchData();
  }, []);
  return {
    response,
    error,
    isLoading
  };
}