/* eslint-disable react-hooks/exhaustive-deps */
import useQueryState from 'hook/UseQueryState';
import * as PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {localize} from 'commons/LocalizedText/LocalizedText';
import {ParamTypes} from 'utils/constants/param.type';

/**
 * Checkbox filter to apply a filter or not, the selected value is synchronized in the query string
 * @param label translated label of the field
 * @param valueName identifier of the field name
 * @param filter curried function to filter a row by a value
 * @param updateFilter callback to call when value changed
 */
export const CheckBoxFilter = ({
  label, valueName, filter, updateFilter
}) => {
  const [checked, setChecked] = useQueryState(valueName, false, ParamTypes.BOOLEAN);

  useEffect(() => {
    updateFilter(valueName, checked ? filter(checked) : null);
  }, [checked, valueName]);

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          color="primary"
          name={valueName}
          onChange={(e) => setChecked(e.target.checked)}
        />
      )}
      label={localize(label)}
    />
  );
};

CheckBoxFilter.propTypes = {
  filter: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  valueName: PropTypes.string.isRequired
};

CheckBoxFilter.defaultProps = {};