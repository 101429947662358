/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import useQueryState from 'hook/UseQueryState';
import PropTypes from 'prop-types';
import React from 'react';
import { ParamTypes } from 'utils/constants/param.type';
import { CustomTable, INITIAL_PAGE, INITIAL_ROWS_PER_PAGE, SORTING } from 'commons/Table/CustomTable';

export const CustomTableWithQuerySort = (props) => {
  const {headers} = props;
  const [order, setOrder] = useQueryState('order', SORTING.ASC, ParamTypes.STRING);
  const [orderBy, setOrderBy] = useQueryState('orderBy', headers?.orderBy || '', ParamTypes.STRING);
  const [page, setPage] = useQueryState('page', INITIAL_PAGE, ParamTypes.NUMBER);
  const [rowsPerPage, setRowsPerPage] = useQueryState('rowsPerPage', INITIAL_ROWS_PER_PAGE, ParamTypes.NUMBER);

  const orderParams = {order, orderBy, setOrder, setOrderBy};
  const paginationParams = {page, rowsPerPage, setPage, setRowsPerPage};

  return (
    <CustomTable
      paginationParams={paginationParams}
      sortParams={orderParams}
      {...props}
    />
  );
};

CustomTableWithQuerySort.propTypes = {
  filters: PropTypes.object,
  headers: PropTypes.arrayOf(PropTypes.shape({
    isOnMobile: PropTypes.bool,
    isSortable: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  isBackend: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onRowCLick: PropTypes.func,
  rowKey: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stickyHeader: PropTypes.bool,
  total: PropTypes.number
};

CustomTableWithQuerySort.defaultProps = {
  filters: {},
  isBackend: false,
  isLoading: false,
  onChange: () => {},
  onRowCLick: null,
  rowKey: null,
  stickyHeader: false,
  total: null
};