import {PageContainer} from 'components/PageContainer/PageContainer';
import TabsMenu from 'components/TabsMenu/TabsMenu';
import useFetch from 'hook/UseFetch';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import {ContractRoutes} from 'routes/ContractRoutes';
import {ContractService} from 'services/ContractService';
import {GET_CONTRACT_TABS} from 'utils/constants/tabs';

export const ContractPage = () => {
  const {contract: contractId} = useRealParams();
  const contractData = useFetch(() => ContractService.getContractDetails(contractId), {}).response;

  return (
    <PageContainer>
      <TabsMenu tabs={GET_CONTRACT_TABS()}/>
      <ContractRoutes contract={contractData}/>
    </PageContainer>
  );
};