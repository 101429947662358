import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AutoCompleteWithLabel } from 'commons/AutoComplete/AutoCompleteWithLabel';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { TextFieldWithLabel } from 'commons/TextFieldWithLabel/TextFieldWithLabel';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React, { useEffect, useState } from 'react';
import { ContractService } from 'services/ContractService';
import { CustomerService } from 'services/CustomerService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { isAdmin, isSuperAdmin } from 'utils/constants/roles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    marginTop: '30px',
    marginBottom: '10px',
    '&::before': {
      content: '"-"',
      backgroundColor: theme.palette.secondary.main,
      marginRight: '10px'
    }
  },
  dropdown: {
    width: '90%',
    margin: 0
  },
  root: () => ({
    flexGrow: 1,
    width: '60%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    }
  })
}));

const FIELDS = {
  PHONE_NUMBER: {
    name: 'phoneNumber',
    label: 'contact.phoneNumber',
    required: false
  }
}

export const ContactInformationSection = ({
  civility, companyName = null, errors, firstname, lastname, phoneNumber,
  setCivility, setCompany, setError, setFirstname, setLastname, setPhoneNumber, userPrograms
}) => {
  const classes = useStyles();
  const {role} = useCustomerInfo();

  const [availableCivilities, setAvailableCivilities] = useState([]);
  const [isLoadingCivilities, setIsLoadingCivilities] = useState(false);
  const [availableCompanyNamesAndId, setAvailableCompanyNamesAndId] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  const [availableCompanyNames, setAvailableCompanyNames] = useState([]);

  useEffect(() => {
    setError(FIELDS.PHONE_NUMBER, false);
  }, [ // should only run on init
    // reactive values that should not change
    setError // non reactive prop from parent
  ]);

  useEffect(() => { // TODO: process data on event handling instead
    if (availableCompanyNamesAndId) {
      setAvailableCompanyNames(availableCompanyNamesAndId.map(company => company.companyName));
      setIsLoadingCompanies(false);
    }
  }, [
    // reactive values
    availableCompanyNamesAndId
  ]);

  useEffect(() => {
    const handleResponse = (response) => {
      let availableCompanyNamesAndIdsFromResponse = response.map(contract => ({
        companyName: contract.companyName,
        companyEncryptedId: contract.companyEncryptedId
      }));
      setAvailableCompanyNamesAndId(availableCompanyNamesAndIdsFromResponse);
    }

    setIsLoadingCompanies(true);
    if (isSuperAdmin(role)) {
      ContractService.getCustomerContracts("").then(response => {
        handleResponse(response);
      });
    } else if (isAdmin(role)) {
      ContractService.getCustomerContracts(userPrograms).then(response => {
        handleResponse(response);
      });
    }
  }, [
    // reactive values
    userPrograms,
    // reactive values that should not change
    role // from useFetch
  ]);

  useEffect(() => {
    setIsLoadingCivilities(true);
    CustomerService.getCivilities().then(response => {
      setAvailableCivilities(response);
      setIsLoadingCivilities(false);
    })
  }, []);

  const lastnameField = (
    <TextFieldWithLabel
      classes={{
        root: classes.root
      }}
      label="lastname"
      labelXS={3}
      margin="normal"
      onChange={(event) => setLastname(event)}
      required
      textFieldXS={9}
      value={lastname}
    />
  );

  const firstnameField = (
    <TextFieldWithLabel
      classes={{
        root: classes.root
      }}
      label="firstname"
      labelXS={3}
      margin="normal"
      onChange={(event) => setFirstname(event)}
      required
      textFieldXS={9}
      value={firstname}
    />
  );

  const civilityField = (
    <AutoCompleteWithLabel
      autoCompleteXS={9}
      className={classes.dropdown}
      isLoading={isLoadingCivilities}
      isTranslatingOptions
      label="civility"
      labelXS={3}
      noOptionsText="noOptionText"
      onChange={(event) => setCivility(event)}
      options={availableCivilities}
      value={civility}
      valueName="civility"
    />
  );

  const phoneNumberField = (
    <TextFieldWithLabel
      classes={{
        root: classes.root
      }}
      enableColorChangeOnModification
      hideErrorLabel={true}
      label="phoneNumber"
      labelXS={3}
      margin="normal"
      onChange={(event) => setPhoneNumber(event)}
      setError={error => setError(FIELDS.PHONE_NUMBER, error)}
      textFieldXS={9}
      type='phone'
      value={phoneNumber}
    />
  );

  const companyNameField = (
    <AutoCompleteWithLabel
      autoCompleteXS={9}
      className={classes.dropdown}
      isLoading={isLoadingCompanies}
      isTranslatingOptions
      label="company"
      labelXS={3}
      noOptionsText="noOptionText"
      onChange={(event) => setCompany(availableCompanyNamesAndId.filter(company => company.companyName === event)[0])}
      options={availableCompanyNames}
      required
      value={companyName}
      valueName="company"
    />
  );

  return (
    <>
      {
        isAdmin(role)
        && (
          <Grid container direction="column">
            <Grid item>
              <LocalizedText className={classes.title}>
                contactInformation
              </LocalizedText>
            </Grid>
            <Grid item>
              {lastnameField}
            </Grid>
            <Grid item>
              {firstnameField}
            </Grid>
            <Grid item>
              {civilityField}
            </Grid>
            <Grid item>
              {phoneNumberField}
            </Grid>
            <Grid item>
              {companyNameField}
            </Grid>
          </Grid>
        )
      }
    </>
  );
};