import CustomIcon from "commons/Icon/CustomIcon";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  bigSpinner: {
    color: 'light-gray'
  },
  centeredContent: {
    position: 'fixed',
    top: '50%',
    left: '50%'
  }
}));

export const Spinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.centeredContent}>
      <CustomIcon className={classes.bigSpinner} icon={faCircleNotch} size="6x" spin/>
    </div>
  )
}