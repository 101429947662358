/* eslint-disable max-len */
import AuditRoute from 'hook/AuditRoute';
import { useFeatures } from 'hook/FeatureProvider';
import { PrestationBillPage } from 'pages/PrestationBillPage';
import { AuditFilesPage } from 'pages/audit/AuditFilesPage';
import { AuditGeneralPage } from 'pages/audit/AuditGeneralPage';
import { AuditLaboratoryPage } from 'pages/audit/AuditLaboratoryPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

export const AuditRoutes = ({ audit }) => {
  const billActivated = useFeatures();
  const { general, laboratories, auditFiles } = audit;
  return (
    <Switch>
      <AuditRoute exact path={ROUTES.AUDIT.GENERAL} render={(props) => <AuditGeneralPage {...props} general={general} />} />
      <AuditRoute exact path={ROUTES.AUDIT.FILES} render={(props) => <AuditFilesPage {...props} auditFiles={auditFiles} />} />
      <AuditRoute exact path={ROUTES.AUDIT.LAB} render={(props) => <AuditLaboratoryPage {...props} laboratories={laboratories} />} />
      {billActivated && <AuditRoute component={PrestationBillPage} exact path={ROUTES.AUDIT.BILLS} />}
      <Redirect from={ROUTES.AUDIT.BASE} to={ROUTES.AUDIT.GENERAL} />
    </Switch>
  );
};