import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import {
  faAt, faEnvelope, faIdCard, faPhone
} from '@fortawesome/pro-solid-svg-icons';
import CustomIcon from 'commons/Icon/CustomIcon';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 525,
    margin: 10,
    padding: 0
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 2
    }
  },
  iconPrimary: {
    color: theme.palette.text.disabled
  },
  gridPadding: {
    padding: theme.spacing(1)
  },
  generalContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

const ContractContactsCard = ({ data }) => {
  const classes = useStyles();
  const {
    address, civility, email, firstName, lastName, phoneNumber, role
  } = data;

  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.cardHeader}>
        <LocalizedText component="h5" variant="h6">
          {role}
        </LocalizedText>
      </div>
      <CardContent className={classes.cardContent}>
        <Grid className={classes.generalContainer}>
          <Grid className={classes.gridPadding}>
            <CustomIcon className={classes.iconPrimary} icon={faIdCard} />
          </Grid>
          <Grid className={classes.gridPadding}>
            <Typography>
              {`${civility} ${firstName} ${lastName}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.generalContainer}>
          <Grid className={classes.gridPadding}>
            <CustomIcon className={classes.iconPrimary} icon={faPhone} />
          </Grid>
          <Grid className={classes.gridPadding}>
            <Typography>
              {phoneNumber || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.generalContainer}>
          <Grid className={classes.gridPadding}>
            <CustomIcon className={classes.iconPrimary} icon={faAt} />
          </Grid>
          <Grid className={classes.gridPadding}>
            <Typography>
              {email || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.generalContainer}>
          <Grid className={classes.gridPadding}>
            <CustomIcon className={classes.iconPrimary} icon={faEnvelope} />
          </Grid>
          <Grid className={classes.gridPadding}>
            <Typography>
              {address || '-'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContractContactsCard;