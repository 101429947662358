/* eslint-disable max-len */
import { useFeatures } from 'hook/FeatureProvider';
import ProductTestRoute from 'hook/ProductTestRoute';
import { PrestationBillPage } from 'pages/PrestationBillPage';
import { TestFilesPage } from 'pages/producttest/TestFilesPage';
import { TestGeneralPage } from 'pages/producttest/TestGeneralPage';
import { TestLaboratoryPage } from 'pages/producttest/TestLaboratoryPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

export const ProductTestRoutes = ({ test }) => {
  const billActivated = useFeatures();
  const { general, laboratories, files } = test;
  return (
    <Switch>
      <ProductTestRoute exact path={ROUTES.TEST.GENERAL} render={(props) => <TestGeneralPage {...props} general={general} />} />
      <ProductTestRoute exact path={ROUTES.TEST.FILES} render={(props) => <TestFilesPage {...props} files={files} status={general?.generalTestInfo?.testStatusCustomer} />} />
      <ProductTestRoute exact path={ROUTES.TEST.LAB} render={(props) => <TestLaboratoryPage {...props} laboratories={laboratories} />} />
      {billActivated && <ProductTestRoute component={PrestationBillPage} exact path={ROUTES.TEST.BILLS} />}
      <Redirect from={ROUTES.TEST.BASE} to={ROUTES.TEST.GENERAL} />
    </Switch>
  );
};