/* eslint-disable max-len */
import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const newsServiceUrl = `${API_URL}/news`;
const newsUserServiceUrl = `${API_URL}/news/user`;

const getAllNewsForUser = () => RequestHelper.GET(`${newsUserServiceUrl}?origin=A`);

const getOngoingNews = () => RequestHelper.GET(`${newsServiceUrl}/ongoingNews/?source=A`);
const getUpcommingNews = () => RequestHelper.GET(`${newsServiceUrl}/upcomingNews/?source=A`);
const getOutdatedNews = () => RequestHelper.GET(`${newsServiceUrl}/outdatedNews/?source=A`);
const getSingleNews = (recid) => RequestHelper.GET(`${newsServiceUrl}/${recid}`);
const getLastUnreadNews = () => RequestHelper.GET(`${newsUserServiceUrl}/unread/last?origin=A`);
const getUnreadNewsCount = () => RequestHelper.GET(`${newsUserServiceUrl}/unread/count?origin=A`);

const createNews = (news) => RequestHelper.POST(`${newsServiceUrl}/`, news);
const updateNews = (recid, news) => RequestHelper.POST(`${newsServiceUrl}/${recid}`, news);
const deleteNews = (recid, news) => RequestHelper.DELETE(`${newsServiceUrl}/${recid}`, news);
const readNews = (recid) => RequestHelper.POST(`${newsUserServiceUrl}/read/${recid}`);

export const NewsService = {
  getAllNewsForUser,
  getOngoingNews,
  getUpcommingNews,
  getOutdatedNews,
  getSingleNews,
  getLastUnreadNews,
  getUnreadNewsCount,
  createNews,
  updateNews,
  deleteNews,
  readNews
};