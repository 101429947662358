import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralTab } from 'components/GeneralTab/GeneralTab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  generalPadding: {
    paddingTop: theme.spacing(5)
  }
}));

export const TestGeneralPage = ({ general }) => {
  const classes = useStyles();
  const { generalInfo, generalTestInfo } = general ?? {};
  return (
    <div className={classes.generalPadding}>
      <Grid>
        <>
          {general && <GeneralTab data={generalInfo} separator />}
          {general && <GeneralTab data={generalTestInfo} />}
        </>
      </Grid>
    </div>
  );
};