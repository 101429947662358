import { useParams, useRouteMatch } from 'react-router-dom';
import { useCurrentRoute } from 'components/ActiveRoute/ActiveRouteContext';
import { useMemo } from "react";

/**
 * Hook which returns an object containing all url params and their values
 * (working with nested routes unlike base useParams)
 * @returns {*}
 */
export default function useRealParams() {
  const currentRoute = useCurrentRoute();
  const matchRoute = useRouteMatch(currentRoute);
  const params = useParams();

  return useMemo(() =>
    matchRoute ? matchRoute.params ?? {} : params ?? {}
  , [matchRoute, params]);
}