import {faBinoculars} from '@fortawesome/pro-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {CustomButton} from 'commons/Button/CustomButton';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText, {localize} from 'commons/LocalizedText/LocalizedText';
import useFetch from 'hook/UseFetch';
import React, {useState} from 'react';
import useRealParams from 'hook/UseRealParams';
import {useHistory} from 'react-router-dom';
import {CustomerService} from 'services/CustomerService';
import {MOBILE_BREAKPOINT} from 'utils/constants/responsive';
import {GENERATE_ROUTE, ROUTES} from 'utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  impersonationPicker: {
    maxWidth: 400,
    margin: theme.spacing(1),
    flexGrow: 1
  }
}));

export const ManageImpersonation = () => {
  const classes = useStyles();
  const history = useHistory();
  const {contract: contractId} = useRealParams();
  const customers = useFetch(() => CustomerService.getCustomersWithContract(contractId), []).response;
  const [impersonatingCustomer, setImpersonatingCustomer] = useState(null);

  return (
    <>
      <LocalizedText className={classes.sectionTitle} component="h5" variant="h5">
        impersonationManagement
      </LocalizedText>
      <Grid
        alignItems="center"
        container
        direction="row"
      >
        <Autocomplete
          className={classes.impersonationPicker}
          getOptionLabel={(option) => option.text}
          id="customer-impersonation-selection"
          onChange={async (e, value) => {
            if (value) {
              setImpersonatingCustomer(value);
            }
          }}
          options={customers}
          renderInput={(params) => (
            <TextField
              {...params}
              label={localize('chooseCustomerToImpersonate')}
              variant="outlined"
            />
          )}
          value={impersonatingCustomer}
        />
        <CustomButton
          className={classes.margin}
          onClick={async () => {
            if (impersonatingCustomer) {
              await CustomerService.setImpersonation(impersonatingCustomer.id);
              history.replace(GENERATE_ROUTE(`${ROUTES.CHOICE}`));
              window.location.reload();
            }
          }}
        >
          <CustomIcon icon={faBinoculars} />
          <span>&nbsp;</span>{localize('impersonate')}
        </CustomButton>
      </Grid>
    </>
  );
};