import { ROUTES } from './routes';

export const PRESTATION_TYPE = {
  SAMPLING_ONLY: ({
    name: 'samplingOnlyAudits',
    route: ROUTES.AUDITS,
    type: 'SAMPLING_ONLY'
  }),
  ON_SITE_AUDIT: ({
    name: 'onSiteAudits',
    route: ROUTES.AUDITS,
    type: 'ON_SITE_AUDIT'
  }),
  CHECKING: ({
    name: 'checkingAudits',
    route: ROUTES.AUDITS,
    type: 'CHECKING'
  }),
  SOFTWARE_AUDIT: ({
    name: 'softwareAudits',
    route: ROUTES.AUDITS,
    type: 'SOFTWARE_AUDIT'
  }),
  PRODUCT_TEST: ({
    name: 'productTests',
    route: ROUTES.TESTS,
    type: 'PRODUCT_TEST'
  })
};