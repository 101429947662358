import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React from 'react';
import { CustomerService } from 'services/CustomerService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    height: 40,
    bottom: 60,
    right: 30,
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    alignItems: 'center',
    boxShadow: theme.palette.shadow.hover,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      right: 0,
      padding: theme.spacing(1)
    }
  },
  exitButton: {
    color: theme.palette.error.dark
  }
}));

const ImpersonatingUserOverlay = () => {
  const { firstName, lastName, personNumber } = useCustomerInfo();
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <LocalizedText>
        impersonating
      </LocalizedText>
      &nbsp;
      <Typography>
        {firstName}
        {' '}
        {lastName}
        {' '}
        (
        {personNumber}
        )
      </Typography>
      <IconButton
        className={classes.exitButton}
        onClick={() => {
          CustomerService.removeImpersonation()
            .then(() => {
              window.location.reload()
            }
            );
        }}
      >
        <CustomIcon icon={faTimes} />
      </IconButton>
    </div>
  );
};

export default ImpersonatingUserOverlay;