import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AutoComplete } from 'commons/AutoComplete/AutoComplete';
import { CustomButton } from 'commons/Button/CustomButton';
import { DialogType } from 'commons/Dialog/CustomDialog';
import { localize } from 'commons/LocalizedText/LocalizedText';
import { CustomTable } from 'commons/Table/CustomTable';
import { useTablePaginationParams, useTableSortParams } from 'commons/Table/CustomTableHooks';
import ExcelExportButton from 'components/ExcelExportButton/ExcelExportButton';
import { useContractInfo } from 'hook/ContractInfoProvider';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import { useDialog } from 'hook/DialogProvider';
import { layoutCustomerAdminView, layoutEccAdminView } from 'pages/administration/AdministrationUsersPageRows';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContractService } from 'services/ContractService';
import { CustomerService } from 'services/CustomerService';
import { ProgramService } from 'services/ProgramService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { isAdmin, isSuperAdmin, Roles } from 'utils/constants/roles';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';
import { convertJsonToXlsx } from 'utils/excelConverter';

const useStyles = makeStyles((theme) => ({
  inputFields: {
    maxWidth: 600,
    width: '-webkit-fill-available',
    marginLeft: '20px'
  },
  marginFilterInput: {
    marginLeft: '24px',
    marginRight: '24px'
  },
  marginFilterAutocomplete: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(3)
    }
  },
  addButton: {
    margin: '10px'
  },
  tableFooter: {
    marginTop: '20px'
  }
}));

export const AdministrationUsersPage = () => {
  const classes = useStyles();
  const showDialog = useDialog();
  const { contract: contractId } = useParams();
  const { role } = useCustomerInfo();
  const contractInfo = useContractInfo();
  const {
    companyEncryptedId: companyDefaultValue,
    isLoading: isContractInfoLoading
  } = contractInfo;

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const previousController = useRef();

  const sortParams = useTableSortParams();
  const paginationParams = useTablePaginationParams();
  const { page, rowsPerPage } = paginationParams;
  const { order, orderBy } = sortParams;

  const [emailSSOFilter, setEmailSSOFilter] = useState();
  const [lastnameFilter, setLastnameFilter] = useState();
  const [firstnameFilter, setFirstnameFilter] = useState();
  const [contractFilter, setContractFilter] = useState([]);
  const [contractNumberFilter, setContractNumberFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [companyNameFilter, setCompanyNameFilter] = useState([]);
  const [profileFilter, setProfileFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState();

  const [availableProgramNames, setAvailableProgramNames] = useState([]);
  const [availableContractNamesAndId, setAvailableContractNamesAndId] = useState([]);
  const [availableCompanyNamesAndId, setAvailableCompanyNamesAndId] = useState([]);
  const [availableProfiles, setAvailableProfiles] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const loadAvailableProgramNames = () => {
      ProgramService.getPrograms().then(response =>
        setAvailableProgramNames(response.map(program => program.programCode)));
    };
    loadAvailableProgramNames();
  }, [ // should only run on init
    // non reactive values
    setAvailableProgramNames
  ]);

  useEffect(() => {
    const loadAvailableContractAndCompanyNames = () => {
      ContractService.getCustomerContracts("").then(response => {
        setAvailableContractNamesAndId(response.map(contract =>
          ({contractNumber: contract.contractNumber, contractEncryptedId: contract.contractEncryptedId})));
        setAvailableCompanyNamesAndId(response.map(contract =>
          ({companyName: contract.companyName, companyEncryptedId: contract.companyEncryptedId})));
      });
    };
    loadAvailableContractAndCompanyNames();
  }, [ // should only run on init
    // non reactive values
    setAvailableContractNamesAndId,
    setAvailableCompanyNamesAndId
  ])

  useEffect(() => {
    const loadAvailableProfiles = () => {
      if (role === 'ECC_ADMIN') {
        CustomerService.getRoles().then(response => {
          setAvailableProfiles(response);
        });
      } else if (role === 'CUSTOMER_ADMIN') {
        CustomerService.getCustomerRoles().then(response => {
          setAvailableProfiles(response);
        });
      }
    }
    loadAvailableProfiles();
  }, [ // should only run on init
    // reactive values that should not change
    role, // from useFetch
    // non reactive values
    setAvailableProfiles
  ])

  useEffect(() => {
    const loadAvailableStatuses = () => {
      CustomerService.getAccountStatuses().then(response => {
        setAvailableStatuses(response);
      });
    };
    loadAvailableStatuses();
  }, [ // should only run on init
    // non reactive values
    setAvailableStatuses
  ]);

  useEffect(() => { // TODO: process data on event handling (render) instead
    setIsLoadingOptions(
      availableProfiles?.length === 0
      || availableCompanyNamesAndId?.length === 0
      || availableContractNamesAndId?.length === 0
      || availableStatuses?.length === 0
      || availableProgramNames?.length === 0
    );
  }, [
    // reactive values
    availableContractNamesAndId,
    availableProgramNames,
    availableStatuses,
    availableProfiles,
    availableCompanyNamesAndId
  ]);

  useEffect(() => { // TODO: process data on event handling (render) instead
    if (!isLoadingOptions) {
      const selectedContracts = availableContractNamesAndId
        .filter(contract => contractNumberFilter.includes(contract.contractNumber));
      setContractFilter(selectedContracts)
    }
  }, [
    // reactive values
    isLoadingOptions,
    contractNumberFilter,
    availableContractNamesAndId
  ]);

  useEffect(() => { // TODO: process data on event handling (render) instead
    if (!isLoadingOptions) {
      const selectedCompanies = availableCompanyNamesAndId
        .filter(company => companyNameFilter.includes(company.companyName));
      setCompanyFilter(selectedCompanies);
    }
  }, [
    // reactive values
    isLoadingOptions,
    companyNameFilter,
    availableCompanyNamesAndId
  ]);

  const buildSearchParams = useCallback((usePaging = true) => {
    let searchParams = {};
    if (usePaging) {
      searchParams = { page, rowsPerPage, order, orderBy };
    }
    searchParams = {
      emailSSO: emailSSOFilter,
      lastname: lastnameFilter,
      firstname: firstnameFilter,
      contracts: contractFilter?.map(contract => contract.contractEncryptedId) ?? [],
      companies: companyFilter?.map(company => company.companyEncryptedId) ?? [],
      profiles: profileFilter,
      status: statusFilter,
      ...searchParams
    };
    if (role === Roles.CUSTOMER_ADMIN) {
      searchParams = {
        ...searchParams,
        contracts: [contractId],
        companies: [companyDefaultValue]
      };
    }
    return searchParams;
  }, [
    // reactive values
    companyFilter, contractFilter, emailSSOFilter, firstnameFilter, lastnameFilter, profileFilter, statusFilter,
    order, orderBy, page, rowsPerPage,
    // reactive values that should not change
    contractId, // from path
    role, // from useFetch
    companyDefaultValue // derived from useFetch
  ]);

  const updateData = useCallback(() => {
    if (isContractInfoLoading || isLoadingOptions) {
      return;
    }
    if (contractId == null) {
      return;
    }
    setIsLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    previousController.current = controller;

    let searchParams = buildSearchParams();
    CustomerService.searchUsers(searchParams, signal)
      .then((response) => {
        if (response) {
          setRows([...response.results]);
          setTotal(response.total);
        }
        setIsLoading(false);
      })
      .catch((errorMessage) => {
        if (errorMessage !== 'AbortError') {
          showDialog({
            variant: DialogType.ERROR,
            title: 'error',
            description: errorMessage
          });
        }
      });
  }, [
    // reactive values
    isContractInfoLoading, isLoadingOptions,
    buildSearchParams, // updated on filter or pagination change
    // reactive values that should not change
    contractId, // from route
    // non reactive values
    showDialog
  ]);

  useEffect(() => {
    updateData();
  }, [
    // reactive values
    updateData // updated on infoLoaded or filter update or paginationChange
  ]);

  const openCreateUser = () => {
    return history.push(GENERATE_ROUTE(ROUTES.ADMINISTRATION.USERS.CREATE,
      {
        "contract": contractId,
        "userPrograms": availableProgramNames
      }
    ));
  };

  const openEditUser = (row) => {
    const editUserLink = GENERATE_ROUTE(
      ROUTES.ADMINISTRATION.USERS.EDIT,
      {
        "contract": contractId,
        "user": row.personId
      }
    );
    history.push(editUserLink);
  };

  const emailSSOField = (
    <TextField
      className={classes.marginFilterInput}
      label={localize("emailSSO")}
      margin="normal"
      onChange={(event) => setEmailSSOFilter(event.target.value)}
    />
  );

  const firstnameField = (
    <TextField
      className={classes.marginFilterInput}
      label={localize("firstname")}
      margin="normal"
      onChange={(event) => setFirstnameFilter(event.target.value)}
    />
  );

  const lastnameField = (
    <TextField
      className={classes.marginFilterInput}
      label={localize("lastname")}
      margin="normal"
      onChange={(event) => setLastnameFilter(event.target.value)}
    />
  );

  const contractField = (
    <AutoComplete
      className={classes.marginFilterAutocomplete}
      isMultiple
      label="contractNames"
      onChange={(event, valueList) => {
        setContractNumberFilter(valueList);
      }}
      options={availableContractNamesAndId.map(contract => contract.contractNumber)}
      valueName="contracts"
    />
  );

  const companyField = (
    <AutoComplete
      className={classes.marginFilterAutocomplete}
      isMultiple
      label="companyName"
      onChange={(event, valueList) => {
        setCompanyNameFilter(valueList);
      }}
      options={availableCompanyNamesAndId.map(company => company.companyName)}
      valueName="company"
    />
  );

  const profileField = (
    <AutoComplete
      className={classes.marginFilterAutocomplete}
      isMultiple
      isTranslatingOptions
      label="profileNames"
      onChange={(event, value) => setProfileFilter(value)}
      options={availableProfiles}
      valueName="profiles"
    />
  );

  const statusField = (
    <AutoComplete
      className={classes.marginFilterAutocomplete}
      defaultValue='Enabled'
      isTranslatingOptions
      label="statusesNames"
      onChange={(event, value) => setStatusFilter(value)}
      options={availableStatuses}
      valueName="statuses"
    />
  );

  const excelExport = () => {
    setIsLoadingExport(true);
    if (contractId == null) {
      return;
    }

    let searchParams = buildSearchParams(false);
    CustomerService.searchUsers(searchParams)
      .then((response) => {
        if (!response) {
          throw new Error("Missing data")
        }

        const date = new Date();
        const time = date.getTime();
        let results = response.results.map(customer => ({
          usersMailOrLogin: customer?.userName,
          usersLastName: customer?.lastName,
          usersFirstName: customer?.firstName,
          usersContracts: customer?.contractNumbers,
          usersCompanies: customer?.companiesNames,
          usersStatus: localize(customer?.status),
          usersProfile: localize(customer?.role)
        }));

        let hiddenColumns = isSuperAdmin(role) ? [] : ['usersContracts', 'usersCompanies']
        let filename = `${localize('usersExport')}_${date.toLocaleDateString()}-${time}`;
        convertJsonToXlsx(results, filename, hiddenColumns);
      })
      .catch((errorMessage) => {
        if (errorMessage !== 'AbortError') {
          showDialog({
            variant: DialogType.ERROR,
            title: 'error',
            description: errorMessage
          });
        }
      }).finally(() => {
        setIsLoadingExport(false);
      });
  }

  return (
    <Grid columns={{ sm: 8, md: 12 }} container spacing={4}>
      <Grid item>
        <Grid alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
        >
          <Grid item md={4} sm={8}>
            {emailSSOField}
          </Grid>
          <Grid item md={8} sm={8}>
            <Grid container direction={'row'} justifyContent={'flex-start'}>
              <Grid item >{lastnameField}</Grid>
              <Grid item >{firstnameField}</Grid>
            </Grid>
          </Grid>
          {role === 'ECC_ADMIN' && (
            <>
              <Grid item md={4} sm={8}>
                {contractField}
              </Grid>
              <Grid item md={4} sm={8}>
                {companyField}
              </Grid>
            </>
          )}
          <Grid item md={4} sm={8}>
            {profileField}
          </Grid>
          <Grid item md={4} sm={8}>
            {statusField}
          </Grid>
        </Grid>
        {isAdmin(role) && <Grid item>
          <Grid
            alignItems="flex-end"
            container
            direction="column"
            justifyContent="space-evenly"
            spacing={10}
            wrap='nowrap'
            xs
          >
            <CustomButton
              className={classes.addButton}
              onClick={() => openCreateUser()}
            >
              {localize('addUserButton')}
            </CustomButton>
          </Grid>
        </Grid>}
      </Grid>
      <Grid item>
        <CustomTable
          headers={ isSuperAdmin(role) ? layoutEccAdminView : layoutCustomerAdminView }
          isBackend
          isLoading={isLoading}
          onChange={updateData}
          onRowCLick={(row) => openEditUser(row)}
          paginationParams={paginationParams}
          rowKey={'usersPageRow'}
          rows={rows}
          sortParams={sortParams}
          total={total}
        />

        <ExcelExportButton
          handleExport={() => excelExport()}
          isLoadingExport={isLoadingExport}>
        </ExcelExportButton>
      </Grid>
    </Grid>
  )
}