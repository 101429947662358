import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { getRandomIntBetween } from 'utils/processing/numbers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 220,
    width: 350,
    margin: 10,
    padding: 0,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: 190,
      width: 300
    }
  },
  category: {
    padding: 3,
    display: 'flex'
  },
  content: {
    paddingLeft: 5,
    paddingRight: 5
  },
  subCategory: {
    display: 'flex',
    padding: 2,
    marginLeft: theme.spacing(6)
  },
  completion: {
    marginRight: theme.spacing(2)
  }
}));


export default function SkeletonCampaignCard() {
  const classes = useStyles();
  const lineHeight = 27;
  const lineWidth = () => getRandomIntBetween(170, 250);
  return (
    <Skeleton className={classes.root} variant="rect">
      <Skeleton height={38} variant="rect" width="100%" />
      <div className={classes.content}>
        <div className={classes.category}>
          <Skeleton className={classes.completion} height={lineHeight} variant="circle" width={lineHeight} />
          <Skeleton height={lineHeight} variant="text" width={lineWidth()} />
        </div>
        {[...Array(getRandomIntBetween(1, 3)).keys()].map((key) => (
          <div className={classes.subCategory} key={key}>
            <Skeleton className={classes.completion} height={lineHeight} variant="circle" width={lineHeight} />
            <Skeleton height={lineHeight} variant="text" width={lineWidth()} />
          </div>
        ))}
      </div>
    </Skeleton>
  );
}