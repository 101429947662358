import React, {useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import * as PropTypes from 'prop-types'; // ES6
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export const RichTextDisplay = ({value="", simplified=false, classNames}) => {
  const quillRef = useRef(null);
  const [text, setText] = useState("");

  useEffect(() => {
    let quillEditor = quillRef.current.getEditor();
    quillEditor = quillRef.current.makeUnprivilegedEditor(quillEditor);
    setText(() => quillEditor.getText())
  }, [])

  return (
    <>
      { (!simplified || !text) &&
        <ReactQuill
          classNames={classNames?.editor}
          readOnly={true}
          ref={quillRef}
          theme={'bubble'}
          value={value}
        />
      }
      { simplified &&
        <>
          {text}
        </>
      }
    </>
  )
};

RichTextDisplay.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  simplified: PropTypes.bool,
  value: PropTypes.string
}