import { faFile, faPlus, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { localize } from 'commons/LocalizedText/LocalizedText';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles(() => ({
  fileContainer: {
    borderRadius: '8px',
    border: `1px dashed gray`,
    padding: '20px 40px',
    marginBottom: '10px',
    width: '600px',
    cursor: 'pointer',
    height: '215px',
    textAlign: 'center'
  },
  instructions: {
    color: 'grey'
  },
  replaceWarning: {
    color: 'red'
  }
}));

export const FileDropZone = ({
  acceptedFormats,
  initialFileName = null,
  onFileDrop,
  fileTypeToAdd
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [file, setFile] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);

  const loadFile = (files) => {
    if (!files) return
    const file = files[0];
    setIsLoadingFile(true);
    setFile(file);
    onFileDrop(file);
  }

  const clearFile = () => {
    setFile(null);
    onFileDrop(null);
  }

  useEffect(() => setIsLoadingFile(false), [file]);

  return (
    <Dropzone
      accept={acceptedFormats}
      multiple={false}
      onDragEnter={() => setIsDragActive(true)}
      onDragLeave={() => setIsDragActive(false)}
      onDrop={(acceptedFiles) => {
        loadFile(acceptedFiles);
        setIsDragActive(false);
      }}
      onFileDialogCancel={clearFile}
    >
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isLoadingFile ?
              <div className={classes.fileContainer}>
                <CircularProgress size={100}/>
              </div>
              :
              <Grid
                alignItems='center'
                className={classes.fileContainer}
                container
                direction='column'
                justifyContent='center'
                spacing={1}
                wrap="nowrap"
              >
                { isDragActive ? (
                  <Grid item>
                    <FontAwesomeIcon color={theme.palette.primary.main} icon={faPlus} size="5x"/>
                  </Grid>
                ):(
                  <>
                    <Grid item>
                      { file ?? initialFileName ?
                        <Grid alignItems="center" container direction="column" justifyContent="center">
                          <Grid item>
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faFile} size="5x"/>
                          </Grid>
                          <Grid item>
                            {file?.name ?? initialFileName}
                          </Grid>
                        </Grid>
                        :
                        <FontAwesomeIcon color={theme.palette.primary.main} icon={faUpload} size="5x"/>
                      }
                    </Grid>
                    <Grid className={classes.instructions} item>
                      {localize('dropzoneDragNDrop')}
                    </Grid>
                    { fileTypeToAdd !== "TechnicalDataSheet" ? (
                      <Grid className={classes.replaceWarning} item>
                        {localize('dropzoneFileWillBeReplaced')}
                      </Grid>
                    ):('')}
                  </>
                )}
              </Grid>
            }
          </div>
        </section>
      )}
    </Dropzone>


  );
};

FileDropZone.propTypes = {
  acceptedFormats: PropTypes.string,
  onFileDrop: PropTypes.func.isRequired
};

FileDropZone.defaultProps = {
  acceptedFormats: '*'
};