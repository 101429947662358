import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'commons/Button/CustomButton';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import useMobile from 'hook/UseMobile';
import * as PropTypes from 'prop-types';
import React from 'react';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';


const useStyles = makeStyles((theme) => ({
  title: {
    background: `linear-gradient(130deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: theme.palette.common.white
  },
  cookieImage: {
    height: 250,
    width: 250
  },
  cookieButtonsContainer: {
    justifyContent: "space-between"
  }
}));

export default function CookieDialog({
  onAgree,
  onClose,
  onDecline,
  onMoreOptions,
  open
}) {
  const classes = useStyles();
  const isMobile = useMobile();


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle className={classes.title}>
        {localize('welcomeToCustomerApp')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {
            !isMobile && (
              <Grid item style={{paddingTop: 25}}>
                <img alt="cookie popup illustration" className={classes.cookieImage} src={getAssetUrl(`/assets/images/Cookie.png`)} />
              </Grid>
            )
          }
          <Grid container item sm xs={12}>
            <Grid container direction="column" item spacing={2} xs>
              <Grid item>
                <Typography>
                  <strong>{localize('cookiePopupLine1Part1')}</strong>&nbsp;{localize('cookiePopupLine1Part2')}
                </Typography>
                <br/>
                <Typography>
                  <strong>{localize('cookiePopupLine2Part1')}</strong>&nbsp;{localize('cookiePopupLine2Part2')}
                </Typography>
                <br/>
                {
                  !isMobile && (
                    <>
                      <LocalizedText>cookiePopupLine3</LocalizedText>
                      <br/>
                    </>
                  )
                }
              </Grid>
              <Grid item>
                <Typography>
                  <strong>{localize('cookiePopupLine4')}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.cookieButtonsContainer}>
        <CustomButton onClick={onMoreOptions} variant="primaryLight">
              moreOptions
        </CustomButton>
        <DialogActions>
          <CustomButton onClick={onDecline} variant="primaryLight">
            declineAll
          </CustomButton>
          <CustomButton onClick={onAgree} variant="primary">
            acceptAll
          </CustomButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}

CookieDialog.propTypes = {
  onAgree: PropTypes.func,
  onClose: PropTypes.func,
  onDecline: PropTypes.func,
  onMoreOptions: PropTypes.func,
  open: PropTypes.bool
};