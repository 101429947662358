import { makeStyles } from '@material-ui/core/styles';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { DataStatus } from 'utils/constants/data.status';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.dark
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.dark
  },
  rightAlign: {
    float: 'right'
  }
}));

export const ColoredText = ({
  status, value, rightAlign
}) => {
  const classes = useStyles();
  let className = '';
  switch (status) {
  case DataStatus.SUCCESS:
    className = classes.success;
    break;
  case DataStatus.WARNING:
    className = classes.warning;
    break;
  case DataStatus.ERROR:
    className = classes.error;
    break;
  default:
  }
  if (rightAlign) {
    className += ` ${classes.rightAlign}`;
  }
  return (
    <LocalizedText className={className} variant="body2">{value}</LocalizedText>
  );
};