import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    height: 335,
    width: 525,
    margin: 10,
    padding: 0
  },
  category: {
    padding: 3,
    display: 'flex'
  },
  content: {
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 5
  },
  separatorSmall: {
    width: 10,
    height: 10
  },
  separatorBig: {
    width: 10,
    height: 20
  }
}));


export default function SkeletonLaboratoryCard() {
  const classes = useStyles();
  return (
    <Skeleton className={classes.root} variant="rect">
      <Skeleton height={38} variant="rect" width={525} />
      <div className={classes.content}>
        <div className={classes.separatorBig} />
        <Skeleton height={30} variant="rect" width={290} />
        <div className={classes.separatorSmall} />
        <Skeleton height={60} variant="rect" width={450} />
        <div className={classes.separatorBig} />
        <Skeleton height={30} variant="rect" width={280} />
        <div className={classes.separatorSmall} />
        <Skeleton height={60} variant="rect" width={420} />
      </div>
    </Skeleton>
  );
}