import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React, { useEffect, useState } from 'react';
import { isSuperAdmin } from 'utils/constants/roles';
import { NewsService } from 'services/NewsService';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import { NewsForm } from 'components/News/NewsForm'
import { DialogType } from 'commons/Dialog/CustomDialog';
import { useDialog } from 'hook/DialogProvider';
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';

export const AdministrationNewsEditPage = (props) => {
  const {role} = useCustomerInfo();
  const {contract} = useParams();
  const history = useHistory();
  const showDialog = useDialog();
  const showSnackbar = useLocalizedSnackBar();
  const newsId = props.match.params.news;
  const [news, setNews] = useState()

  useEffect(() => {
    if (newsId) {
      const fetchNews = async () => {
        const fetchedNews = await NewsService.getSingleNews(newsId);
        setNews(fetchedNews);
      }
      fetchNews().catch(console.error)
    }
  }, [newsId]);

  const saveNews = async (news) => {
    news = {...news, origin: "A"}
    if (newsId) {
      NewsService.updateNews(newsId, news)
        .then(() => showSnackbar('saveNews', {variant: 'success'}))
        .catch((error) => showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: error
        }));
    } else {
      await (NewsService.createNews(news)
        .then((createdNews) => {
          setNews(createdNews);
          const recid = createdNews.recid;
          history.replace(generatePath(ROUTES.ADMINISTRATION.NEWS.EDIT, {contract: contract, news: recid}));
        })
        .then(() => showSnackbar('saveNews', {variant: 'success'}))
        .catch((error) => showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: error
        })));
    }
  };

  return (
    <>
      {
        isSuperAdmin(role)
        && (
          <NewsForm initialValue={news} saveAction={saveNews}/>
        )
      }
    </>
  );
};