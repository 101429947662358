import Typography from '@material-ui/core/Typography';
import React from 'react';

export const generateRow = ({
  name, label, template, width, mobileWidth, isSortable = true, isOnDesktop=true, isOnMobile = true
}) => ({
  name,
  label: label ?? name,
  isSortable,
  isOnMobile,
  isOnDesktop,
  width,
  mobileWidth,
  template: (row) => (
    (template && template(row)) || <Typography variant="body2">{row[name]}</Typography>
  )
});

export const limitResults = (resultsArr, threshold, divider) => {
  return (resultsArr.length > threshold)
    ? resultsArr.slice(0, threshold).join(divider) + '... +' + (resultsArr.length - threshold)
    : resultsArr.join(divider)
}