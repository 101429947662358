import { CustomDialog } from 'commons/Dialog/CustomDialog';
import React, { createContext, useCallback, useContext, useState } from 'react';

const DialogContext = createContext(null);

/**
 * Hook which returns a function to open a dialog
 * @returns {function({}): void}
 */
export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({});
  const [open, setOpen] = useState(false);

  const openDialog = useCallback((options) => {
    setOpen(true);
    setDialogState(options);
  }, [setOpen, setDialogState]);

  const { onSubmit, onClose, ...dialogProps } = dialogState;

  const handleSubmit = () => {
    onSubmit && onSubmit();
    setOpen(false);
  };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <>
      <DialogContext.Provider
        value={openDialog} // should be considered non reactive
      >
        {children}
      </DialogContext.Provider>

      <CustomDialog
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        {...dialogProps}
      />
    </>
  );
};