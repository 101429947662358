import {
  faAddressBook,
  faBriefcase,
  faCookie,
  faDollarSign,
  faFile,
  faFileInvoiceDollar,
  faFileSignature,
  faFlask,
  faHome,
  faList,
  faMedal,
  faNewspaper,
  faSignOutAlt,
  faThList,
  faTools,
  faUser,
  faVial
} from '@fortawesome/pro-solid-svg-icons';
import { ROUTES } from 'utils/constants/routes';
import { Roles } from 'utils/constants/roles';

const LABEL_HOME = 'home';
const LABEL_TESTS = 'tests';
const LABEL_BILLS = 'billing';
const LABEL_DIPLOMAS = 'diplomas';
const LABEL_CONTRACT = 'contract';
const LABEL_AUDITS = 'audits';
const LABEL_ADMINISTRATION = 'administration';
const LABEL_LOGOUT = 'logout';
const LABEL_GENERAL = 'generalPage';
const LABEL_FILES = 'files';
const LABEL_PROGRAM_FILES = 'programFiles';
const LABEL_LABORATORIES = 'laboratory';
const LABEL_BODY = 'auditBody';
const LABEL_BILLS_DETAILS = 'bills';
const LABEL_CONTACTS = 'contacts';
const LABEL_ACCOUNT = 'account';
const LABEL_COOKIES = 'cookiesPage';
const LABEL_MY_PROFILE = 'profilePage';
const LABEL_NEWS = 'newsPage';
const LABEL_USERS = 'usersPage';

const MENU_TABS_LABEL = [LABEL_HOME, LABEL_TESTS, LABEL_AUDITS, LABEL_BILLS, LABEL_DIPLOMAS, LABEL_CONTRACT];
const FOOTER_TABS_LABEL = [LABEL_HOME, LABEL_TESTS, LABEL_BILLS, LABEL_DIPLOMAS];

export const MENU_TABS_OBJECT = {
  HOME: {
    LABEL: LABEL_HOME,
    ICON: faHome,
    ROUTE: ROUTES.HOME
  },
  TESTS: {
    LABEL: LABEL_TESTS,
    ICON: faVial,
    ROUTE: ROUTES.TESTS
  },
  AUDITS: {
    LABEL: LABEL_AUDITS,
    ICON: faBriefcase,
    ROUTE: ROUTES.AUDITS
  },
  BILLS: {
    LABEL: LABEL_BILLS,
    ICON: faDollarSign,
    ROUTE: ROUTES.BILLS
  },
  DIPLOMAS: {
    LABEL: LABEL_DIPLOMAS,
    ICON: faMedal,
    ROUTE: ROUTES.DIPLOMAS
  },
  CONTRACT: {
    LABEL: LABEL_CONTRACT,
    ICON: faFileSignature,
    ROUTE: ROUTES.CONTRACT.BASE
  },
  ADMINISTRATION: {
    LABEL: LABEL_ADMINISTRATION,
    ICON: faTools,
    ROUTE: ROUTES.ADMINISTRATION.BASE
  },
  LOGOUT: {
    LABEL: LABEL_LOGOUT,
    ICON: faSignOutAlt
  },
  ACCOUNTS: {
    LABEL: LABEL_ACCOUNT,
    ICON: faUser,
    ROUTE: ROUTES.ACCOUNT.BASE
  }
};

export const ACCOUNT_TABS_OBJECT = {
  PROFILE: {
    LABEL: LABEL_MY_PROFILE,
    ICON: faUser,
    ROUTE: ROUTES.ACCOUNT.PROFILE
  },
  COOKIES: {
    LABEL: LABEL_COOKIES,
    ICON: faCookie,
    ROUTE: ROUTES.ACCOUNT.COOKIES
  }
}

export const GENERAL_ACCOUNT_TABS_OBJECT = {
  PROFILE: {
    LABEL: LABEL_MY_PROFILE,
    ICON: faUser,
    ROUTE: ROUTES.GENERAL_ACCOUNT.PROFILE
  },
  COOKIES: {
    LABEL: LABEL_COOKIES,
    ICON: faCookie,
    ROUTE: ROUTES.GENERAL_ACCOUNT.COOKIES
  }
}

export const PRODUCT_TEST_TABS_OBJECT = {
  GENERAL: {
    LABEL: LABEL_GENERAL,
    ICON: faThList,
    ROUTE: ROUTES.TEST.GENERAL
  },
  FILES: {
    LABEL: LABEL_FILES,
    ICON: faFile,
    ROUTE: ROUTES.TEST.FILES
  },
  LABORATORIES: {
    LABEL: LABEL_LABORATORIES,
    ICON: faFlask,
    ROUTE: ROUTES.TEST.LAB
  },
  BILLS: {
    LABEL: LABEL_BILLS_DETAILS,
    ICON: faFileInvoiceDollar,
    ROUTE: ROUTES.TEST.BILLS
  }
};

export const PRODUCT_AUDIT_TABS_OBJECT = {
  GENERAL: {
    LABEL: LABEL_GENERAL,
    ICON: faThList,
    ROUTE: ROUTES.AUDIT.GENERAL
  },
  FILES: {
    LABEL: LABEL_FILES,
    ICON: faFile,
    ROUTE: ROUTES.AUDIT.FILES
  },
  LABORATORIES: {
    LABEL: LABEL_BODY,
    ICON: faFlask,
    ROUTE: ROUTES.AUDIT.LAB
  },
  BILLS: {
    LABEL: LABEL_BILLS_DETAILS,
    ICON: faFileInvoiceDollar,
    ROUTE: ROUTES.AUDIT.BILLS
  }
};

export const CONTRACT_TABS_OBJECT = {
  GENERAL: {
    LABEL: LABEL_GENERAL,
    ICON: faThList,
    ROUTE: ROUTES.CONTRACT.GENERAL
  },
  CONTACTS: {
    LABEL: LABEL_CONTACTS,
    ICON: faAddressBook,
    ROUTE: ROUTES.CONTRACT.CONTACTS
  },
  FILES: {
    LABEL: LABEL_FILES,
    ICON: faFile,
    ROUTE: ROUTES.CONTRACT.FILES
  },
  PROGRAM_FILES: {
    LABEL: LABEL_PROGRAM_FILES,
    ICON: faFile,
    ROUTE: ROUTES.CONTRACT.PROGRAM_FILES
  }
};

export const ADMINISTRATION_TABS_OBJECT = {
  GENERAL: {
    LABEL: LABEL_GENERAL,
    ICON: faList,
    ROUTE: ROUTES.ADMINISTRATION.GENERAL,
    AUTHORIZED: [Roles.ECC_ADMIN]
  },
  NEWS: {
    LABEL: LABEL_NEWS,
    ICON: faNewspaper,
    ROUTE: ROUTES.ADMINISTRATION.NEWS.BASE,
    AUTHORIZED: [Roles.ECC_ADMIN]
  },
  USERS: {
    LABEL: LABEL_USERS,
    ICON: faUser,
    ROUTE: ROUTES.ADMINISTRATION.USERS.BASE,
    AUTHORIZED: [Roles.CUSTOMER_ADMIN, Roles.ECC_ADMIN]
  }
};

export const GET_MENU_TABS = (features) => Object.values(MENU_TABS_OBJECT)
  .filter((tab) => MENU_TABS_LABEL.indexOf(tab.LABEL) !== -1)
  .filter((tab) => (features.auditsActivated ? true : tab.LABEL !== LABEL_AUDITS));

export const GET_FOOTER_TABS = (features) => Object.values(MENU_TABS_OBJECT)
  .filter((tab) => FOOTER_TABS_LABEL.indexOf(tab.LABEL) !== -1)
  .filter((tab) => (features.auditsActivated ? true : tab.LABEL !== LABEL_AUDITS));

export const GET_PRODUCT_TEST_TABS = () => Object.values(PRODUCT_TEST_TABS_OBJECT);

export const GET_AUDIT_TABS = () => Object.values(PRODUCT_AUDIT_TABS_OBJECT);

export const GET_CONTRACT_TABS = () => Object.values(CONTRACT_TABS_OBJECT);

export const GET_ACCOUNT_TABS = (general = false, cookiesPopinActivated = false) => {
  if (!cookiesPopinActivated){
    delete GENERAL_ACCOUNT_TABS_OBJECT.COOKIES
    delete ACCOUNT_TABS_OBJECT.COOKIES
  }
  return general ? Object.values(GENERAL_ACCOUNT_TABS_OBJECT) : Object.values(ACCOUNT_TABS_OBJECT);
}

export const GET_ADMINISTRATION_TABS = () => Object.values(ADMINISTRATION_TABS_OBJECT);