import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  controlsContainer: {
    position: 'fixed',
    left: '90%',
    bottom: '15%',
    textAlign: 'center',
    width: '5%',
    zIndex: '1'
  }
}));

export const FloatingButtonMenu = ({
  children
}) => {
  const classes = useStyles();

  return (
    <Grid alignContent='space-around' className={classes.controlsContainer} container direction='column' spacing={3}>
      {React.Children.map(children, (child, index) => (
        <Grid item>
          { child }
        </Grid>
      ))}
    </Grid>
  );
}