import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext, useAuthToken } from "hook/AuthProvider";
import { initI18N } from "locales/i18n";

const CustomI18NContext = createContext({});

export const useI18NContext = () => useContext(CustomI18NContext);


export const I18NProvider = ({children}) => {
  const {isLoggedIn} = useAuthContext();
  const {token} = useAuthToken();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const onInitI18N = () => {
    setTranslationsLoaded(true)
  }

  useEffect(() => {
    try {
      if (!translationsLoaded && isLoggedIn && token) {
        initI18N(token, onInitI18N);
      }
    } catch (error) {
      //Acquire token failure
      window.location.reload(false);
    }
  }, [translationsLoaded, isLoggedIn, token])

  const contextValue = useMemo(() => ({
    translationsLoaded
  }), [translationsLoaded])

  return (
    <CustomI18NContext.Provider value={contextValue}>
      {children}
    </CustomI18NContext.Provider>
  );

};