/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import { useState } from 'react';
import { INITIAL_PAGE, INITIAL_ROWS_PER_PAGE, SORTING } from 'commons/Table/CustomTable';

export const useTableSortParams = (defaultOrderBy, defaultOrder) => {
  const [order, setOrder] = useState(defaultOrder ?? SORTING.ASC);
  const [orderBy, setOrderBy] = useState(defaultOrderBy ?? '');
  return { order, orderBy, setOrder, setOrderBy };
}

export const useTablePaginationParams = () => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);
  return { page, rowsPerPage, setPage , setRowsPerPage };
}