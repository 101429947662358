/* eslint-disable react/forbid-prop-types */
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import useFileDownload from 'hook/UseFileDownload';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    color: theme.palette.primary.main,
    // User negative margin to counteract padding and make a larger selection circle than the original div with padding
    padding: 7,
    margin: '-7px -7px -7px -7px'
  }
}
));

const FileDownloader = ({ fileId, className }) => {
  const classes = useStyles();
  const downloadFile = useFileDownload();
  return (
    <IconButton
      className={`${className} ${classes.downloadButton}`}
      onClick={() => downloadFile(fileId)}
      size="small"
    >
      <CustomIcon icon={faDownload}/>
    </IconButton>
  );
};

FileDownloader.propTypes = {
  className: PropTypes.string,
  fileId: PropTypes.string.isRequired
};

FileDownloader.defaultProps = {
  className: ''
};

export default FileDownloader;