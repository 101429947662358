import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    width: 430,
    minHeight: 260,
    margin: 10,
    boxShadow: theme.palette.shadow.card,
    transition: '0.3s',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: theme.palette.shadow.hover
    },
    padding: 0
  },
  buttonBase: {
    display: 'block',
    width: '100%'
  },
  cardContent: {
    minHeight: 240
  },
  program: {
    color: theme.palette.primary.main
  },
  contract: {
    marginBottom: theme.spacing(1)
  },
  content: {
    margin: 0,
    marginTop: theme.spacing(1),
    padding: 0,
    width: '100%',
    color: theme.palette.text.secondary
  },
  subProgramItem: {
    padding: 2
  },
  programItem: {
    padding: 2,
    marginBottom: 4
  },
  code: {
    minWidth: 75,
    marginRight: 5
  },
  company: {
    fontWeight: '700'
  }
}));

export default function ContractCard({ contract, onClick }) {
  const classes = useStyles();
  const {
    contractNumber, companyName, programLabel, programCode, subPrograms
  } = contract;
  return (
    <Card className={classes.root}>
      <ButtonBase className={classes.buttonBase} onClick={onClick}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.company} component="h5" variant="h6">
            {companyName}
          </Typography>
          <Typography className={classes.contract} variant="h6">
            <LocalizedText text="contractNum" />
            {contractNumber}
          </Typography>
          <ListItem className={classes.programItem}>
            <ListItemIcon>
              <Chip className={classes.code} color="primary" label={programCode} size="small" />
            </ListItemIcon>
            <Typography className={classes.program} variant="body1">
              {programLabel}
            </Typography>
          </ListItem>
          {Array.isArray(subPrograms) && subPrograms.length
            ? (
              <>
                <Divider />
                <List className={classes.content}>
                  {subPrograms.map((subProgram) => (
                    <ListItem className={classes.subProgramItem} key={subProgram.code}>
                      <ListItemIcon>
                        <Chip className={classes.code} label={subProgram.code} size="small" />
                      </ListItemIcon>
                      <LocalizedText>
                        {subProgram.label}
                      </LocalizedText>
                    </ListItem>
                  ))}
                </List>
              </>
            )
            : (
              <>
                <Divider className={classes.dividerTop} />
                <List className={classes.content}>
                  <ListItem className={classes.subProgramItem}>
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <LocalizedText>
                      noSubprograms
                    </LocalizedText>
                  </ListItem>
                </List>
              </>
            )}
        </CardContent>
      </ButtonBase>
    </Card>
  );
}