/* eslint-disable max-len */
import { API_URL } from 'utils/constants/app';
import { RequestHelper } from 'utils/helpers/RequestHelper';

const fileServiceUrl = `${API_URL}/files`;

const downloadFile = (recid) => RequestHelper.GET(`${fileServiceUrl}/download/${recid}`);

export const FileService = {
  downloadFile
};