/* eslint-disable react-hooks/exhaustive-deps */
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {Collapse, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomIcon from 'commons/Icon/CustomIcon';
import {CustomLinkRouter} from 'commons/Link/CustomLink';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import {Triangle} from 'commons/Triangle/Triangle';
import useRealParams from 'hook/UseRealParams';
import React, {useEffect, useState} from 'react';
import {BillService} from 'services/BillService';
import {MOBILE_BREAKPOINT} from 'utils/constants/responsive';
import {ROUTES} from 'utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },
  redBackground: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: '100%',
    backgroundColor: theme.palette.error.dark,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: 50
    }
  },
  routerLink: {
    display: 'flex',
    color: theme.palette.common.white,
    textDecorationColor: theme.palette.common.white,
    margin: 'auto'
  },
  billsToPay: {
    flexShrink: 0
  },
  bills: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));

const REFRESH_BILLS_TO_PAY_TIMEOUT = 30000;

const HeaderWarning = () => {
  const classes = useStyles();
  const theme = useTheme();
  const {contract: contractId} = useRealParams();
  const [bills, setBills] = useState(0);

  const refreshBillsToPay = (id) => BillService.getBillsToPay(contractId)
    .then((result) => setBills(result))
    .catch(() => clearInterval(id));

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refreshBillsToPay(0);
    const id = setInterval(() => refreshBillsToPay(id), REFRESH_BILLS_TO_PAY_TIMEOUT);
    return () => clearInterval(id);
  }, []);


  return (
    <Collapse in={bills !== 0}>
      <div className={classes.root}>
        <div className={classes.redBackground}>
          <CustomIcon icon={faExclamationCircle} size="lg" />
        </div>
        <Triangle backgroundColor={theme.palette.error.dark} height={30} width={30} />
        <CustomLinkRouter className={classes.routerLink} to={`${ROUTES.BILLS}?isPaid=no`}>
          <LocalizedText className={classes.billsToPay}>
            billsToPay
          </LocalizedText>
          <Typography className={classes.billsToPay}>
            &nbsp;
            {bills}
          </Typography>
        </CustomLinkRouter>
      </div>
    </Collapse>
  );
};

export default HeaderWarning;