import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useFetch from 'hook/UseFetch';
import React from 'react';
import { CustomerService } from 'services/CustomerService';
import { GIT_BRANCH } from 'utils/constants/app';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    color: theme.palette.text.disabledLight,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(6)
    }
  },
  textAlignRight: {
    textAlign: 'right'
  }
}));

const trimOrigin = (branch) => branch && branch.replace('origin/', '');

const DebugFooter = () => {
  const classes = useStyles();
  const debugInfos = useFetch((() => CustomerService.getDebugInfos()), {}).response;

  const { databaseName, profile, branchName } = debugInfos;

  return (
    <div className={classes.root}>
      <Typography className={classes.textAlignRight} variant="body2">
        {`Frontend Version: ${trimOrigin(GIT_BRANCH)} - Backend Version: ${trimOrigin(branchName)}`}
        <br />
        {`Backend Profile: ${profile} - Database: ${databaseName}`}
      </Typography>
    </div>
  );
};

export default DebugFooter;