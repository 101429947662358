import { ErrorPage } from 'pages/ErrorPage';
import * as React from 'react';
import { CustomerService } from 'services/CustomerService';
import { ErrorType } from 'utils/constants/error.type';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // info must be here to properly override
  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    CustomerService.postBugReport({ name: error.name, stack: error.stack, code: error.code });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorPage errorType={ErrorType.ERROR} />
      );
    }
    return children;
  }
}