/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CircularProgress, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { localize } from 'commons/LocalizedText/LocalizedText';

const useStyles = makeStyles((theme) => ({
  exportExcel: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: '110%',
    textDecoration: 'underline'
  },
  exportExcelDisabled: {
    color: theme.palette.text.disabled,
    fontSize: '110%'
  }
}));

const ExcelExportButton = ({ isLoadingExport, handleExport }) => {
  const classes = useStyles();

  return (
    <div onClick={!isLoadingExport && handleExport}>
      <Grid className={isLoadingExport ? classes.exportExcelDisabled : classes.exportExcel} container spacing={1}>
        {!isLoadingExport ?
          <Grid item>
            <FontAwesomeIcon icon={faFileExcel}/>
          </Grid>
          :
          <Grid item>
            <CircularProgress size={15}/>
          </Grid>
        }
        <Grid item>
          {localize('excelExport')}
        </Grid>
      </Grid>
    </div>
  );
};

export default ExcelExportButton;