import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ROUTE_PARAMS } from 'utils/constants/routes';

const BreadCrumbContext = createContext({});
export const useBreadCrumbContext = () => useContext(BreadCrumbContext);

const defaultBreadcrumbValue = {};
Object.entries(ROUTE_PARAMS).forEach((key, value) => {
  defaultBreadcrumbValue[value] = null
});

export const BreadCrumbContextProvider = ({ children }) => {
  const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumbValue);

  const getBreadcrumb = useCallback((param) => {
    return breadcrumb?.[param];
  }, [breadcrumb]);

  const updateBreadcrumb = useCallback((param, value) => {
    setBreadcrumb((breadcrumb) => ({...breadcrumb, [param]: value}))
  }, [setBreadcrumb]);

  const contextValue = useMemo(() => ({
    getBreadcrumb,
    updateBreadcrumb
  }), [getBreadcrumb, updateBreadcrumb]);

  return (
    <BreadCrumbContext.Provider value={contextValue}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export const useBreadCrumbParam = (param) => {
  const {getBreadcrumb, updateBreadcrumb} = useBreadCrumbContext();
  const breadcrumbParam = useMemo(() => getBreadcrumb(param), [param, getBreadcrumb])
  const updateBreadcrumbValue = useCallback((value) => updateBreadcrumb(param, value), [param, updateBreadcrumb]);

  return [
    breadcrumbParam,
    updateBreadcrumbValue // should be considered non reactive
  ];
}