import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import {ManageFeatures} from 'components/Administration/ManageFeatures';
import {ManageImpersonation} from 'components/Administration/ManageImpersonation';
import {ManageLanguages} from 'components/Administration/ManageLanguages';
import {useCustomerInfo} from 'hook/CustomerInfoProvider';
import React from 'react';
import {MOBILE_BREAKPOINT} from 'utils/constants/responsive';
import {isSuperAdmin} from 'utils/constants/roles';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  divider: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  }
}));

export const AdministrationGeneralPage = (props) => {
  const classes = useStyles();
  const { role } = useCustomerInfo();

  return (
    <>
      {
        isSuperAdmin(role)
        && (
          <>
            <ManageLanguages />
            <div className={classes.marginTop}>
              <Divider className={classes.divider} />
              <ManageFeatures />
              <Divider className={classes.divider} />
              <ManageImpersonation />
            </div>
          </>
        )
      }
    </>
  );
};