/* eslint-disable max-len */
import ContractRoute from 'hook/ContractRoute';
import { ContractContactsPage } from 'pages/contract/ContractContactsPage';
import { ContractFilesPage } from 'pages/contract/ContractFilesPage';
import { ContractGeneralPage } from 'pages/contract/ContractGeneralPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

export const ContractRoutes = ({ contract }) => {
  const { general, contacts, files, programFiles } = contract;
  return (
    <Switch>
      <ContractRoute exact path={ROUTES.CONTRACT.GENERAL} render={(props) => <ContractGeneralPage {...props} general={general} />} />
      <ContractRoute exact path={ROUTES.CONTRACT.CONTACTS} render={(props) => <ContractContactsPage {...props} contacts={contacts} />} />
      <ContractRoute exact path={ROUTES.CONTRACT.FILES} render={(props) => <ContractFilesPage {...props} files={files} />} />
      <ContractRoute exact path={ROUTES.CONTRACT.PROGRAM_FILES} render={(props) => <ContractFilesPage {...props} files={programFiles} />} />
      <Redirect from={ROUTES.CONTRACT.BASE} to={ROUTES.CONTRACT.GENERAL} />
    </Switch>
  );
};