import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import { DiplomasCertifyAll } from 'components/Diploma/DiplomasCertifyAll';
import { DiplomasCertifyRange } from 'components/Diploma/DiplomasCertifyRange';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { useContractInfo } from 'hook/ContractInfoProvider';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  bigSpinner: {
    color: theme.palette.text.disabledLight
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const DiplomaPage = () => {
  const classes = useStyles();
  const { isRangeCertification } = useContractInfo();

  return (
    <PageContainer>
      {
        isRangeCertification !== undefined
          ? (isRangeCertification
            ? <DiplomasCertifyRange />
            : <DiplomasCertifyAll />)
          : (
            <div className={classes.centeredContent}>
              <CustomIcon className={classes.bigSpinner} icon={faCircleNotch} size="6x" spin />
            </div>
          )
      }
    </PageContainer>
  );
};