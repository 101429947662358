/* eslint-disable max-len */
import { UserAdministrationContactInformation } from 'components/Account/UserAdministrationContactInformation';
import { UserAdministrationCookies } from 'components/Account/UserAdministrationCookies';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';

export const AccountRoutes = ({ general }) => {

  const baseURL = general ? ROUTES.GENERAL_ACCOUNT : ROUTES.ACCOUNT;

  return (
    <Switch>
      <Route exact path={baseURL.PROFILE} render={(props) => <UserAdministrationContactInformation {...props}/>} />
      <Route exact path={baseURL.COOKIES} render={(props) => <UserAdministrationCookies {...props}/>} />
      <Redirect from={baseURL.BASE} to={baseURL.PROFILE} />
    </Switch>
  );
};