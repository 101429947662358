/* eslint-disable react-hooks/exhaustive-deps */
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import useRealParams from 'hook/UseRealParams';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuditService } from 'services/AuditService';
import { ROUTES } from 'utils/constants/routes';

/**
 * Route which requires a contract and an audit to be defined and valid,
 * enhanced route which will check if the contract and audit provided are valid and coherent,
 * if not it will redirect to contract choice
 * @param props for the route which will be forwarded
 * @returns enhanced route
 */
const AuditRoute = (props) => {
  // Forced to use this because useParams is FUCKED (nested routes)
  const {contract: contractId, audit: auditId} = useRealParams();
  const [isValid, setValid] = useState(true);
  const enqueueSnackbar = useLocalizedSnackBar();

  useEffect(() => {
    AuditService.getAuditNumberAllowed(contractId, auditId)
      .then(({ isAuthorized }) => {
        if (!isAuthorized) {
          enqueueSnackbar('notAuthorizedAudit', { variant: 'error' });
        }
        setValid(isAuthorized);
      })
      .catch(() => {
        enqueueSnackbar('backendError', { variant: 'error' });
        setValid(false);
      });
  }, []);

  if (isValid) {
    return <Route {...props} />;
  }
  return <Redirect to={ROUTES.CHOICE} />;
};

export default AuditRoute;