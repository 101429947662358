/* eslint-disable no-case-declarations */
import saveAs from 'file-saver';
import { MethodEnum } from 'utils/constants/http.enum';
import { httpSettings } from 'utils/request';

const defaultResponseHandler = async (response) => {
  const { status } = response;
  switch (status) {
  case 201: // eslint-disable-line no-fallthrough
  case 200:
    // Handle Json content
    if (response.headers.get('content-type') === 'application/json') {
      return response.json();
    }
    // Handle file content
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      const [, filename = ''] = contentDisposition.split('=');
      return new Promise((resolve, reject) => response.blob()
        .then((blob) => {
          try {
            saveAs(blob, filename.replaceAll('"', ''));
            resolve();
          } catch (e) {
            reject(e);
          }
        }));
    }
    // handle other content (probably empty response)
    return Promise.resolve();
  case 500:
    // Handle Json content
    if (response.headers.get('content-type') === 'application/json') {
      throw response.json();
    }
  case 403: // eslint-disable-line no-fallthrough
  case 401: // eslint-disable-line no-fallthrough
  default:
    throw response.json();
  }
};

const defaultErrorHandler = (error) => {
  if (error.name === 'AbortError') {
    throw error.name;
  }
  // If the error has the message, directly send it
  if (error && error.message) {
    throw error.message;
  }

  // If the error is a promise
  if (error && typeof error?.then === 'function') {
    return error.then((response) => {
      // If the promise has the message, directly send it
      if (response && response.message) {
        throw response.message;
      } else if (typeof (response) === 'object') {
        // Otherwise it can be an object with multiple errors inside
        let messageFormatted = '';
        Object.keys(response)
          .map((id) => {
            let messagePerLine = '';

            response[id].map((errorLine) => {
              messagePerLine += `${errorLine} <br />`;
              return messagePerLine;
            });

            messageFormatted += `${id}: ${messagePerLine} <br />`;
            return messageFormatted;
          });
        throw messageFormatted;
      }
    });
  }
  return null;
};


const GET = async (url, settings) => fetch(url, await httpSettings({
  ...settings,
  method: MethodEnum.GET
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const POST = async (url, body, settings) => fetch(url, await httpSettings({
  method: MethodEnum.POST,
  ...settings,
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const POST_FORMDATA = async (url, body, settings) => fetch(url, await httpSettings({
  method: MethodEnum.POST,
  ...settings,
  body
}, true))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PUT = async (url, body, settings) => fetch(url, await httpSettings({
  method: MethodEnum.PUT,
  ...settings,
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PATCH = async (url, body, settings) => fetch(url, await httpSettings({
  method: MethodEnum.PATCH,
  ...settings,
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const DELETE = async (url, settings) => fetch(url, await httpSettings({
  method: MethodEnum.DELETE,
  ...settings
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

export const queryStringFromDict = queryParamDict => Object.entries(queryParamDict)
  .filter(([, value]) => value !== undefined)
  .filter(([, value]) => value !== null)
  .map(([key, value]) => `${key}=${value}`)
  .join('&');

// Regroup all functions inside a single const to simplify
export const RequestHelper = {
  GET,
  POST,
  POST_FORMDATA,
  PUT,
  PATCH,
  DELETE
};