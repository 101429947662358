import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { DialogType } from 'commons/Dialog/CustomDialog';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { useDialog } from 'hook/DialogProvider';
import useFetch from 'hook/UseFetch';
import React, { useEffect, useState } from 'react';
import { FeatureService } from 'services/FeatureService';
import { Features } from 'utils/constants/features';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  noMarginLeftMobile: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: 0
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  }
}));

export const ManageFeatures = () => {
  const classes = useStyles();
  const showDialog = useDialog();
  const features = useFetch(() => FeatureService.getFeatures(true), []).response;
  const [auditsActivated, setAuditsActivated] = useState(false);
  const [customerExportsActivated, setCustomerExportsActivated] = useState(false);
  const [cookiesPopinActivated, setCookiesPopinActivated] = useState(false);

  useEffect(() => {
    setAuditsActivated(features.auditsActivated ?? false);
    setCustomerExportsActivated(features.exportsActivated ?? false);
    setCookiesPopinActivated(features.cookiesPopinActivated ?? false);
  }, [features]);

  const changeFeatureActivation = (e, enableTranslation, disableTranslation, feature) => {
    const { checked } = e.target;
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: checked ? enableTranslation : disableTranslation,
      onSubmit: async () => {
        await FeatureService.activateFeature(feature, checked);
        window.location.reload();
      }
    });
  };

  return (
    <>
      <LocalizedText className={classes.sectionTitle} component="h5" variant="h5">
        featuresManagement
      </LocalizedText>
      <Grid
        alignItems="center"
        className={`${classes.margin} ${classes.noMarginLeftMobile}`}
        container
        direction="row"
      >
        <FormControlLabel
          control={(
            <Switch
              checked={auditsActivated}
              color="primary"
              name="auditActivationSwitch"
              onChange={(e) => changeFeatureActivation(e,
                'enableAuditsConfirm',
                'disableAuditsConfirm',
                Features.AUDITS_ACTIVATION)}
            />
          )}
          label={localize('customerAuditAccess')}
        />
        <FormControlLabel
          control={(
            <Switch
              checked={customerExportsActivated}
              color="primary"
              name="customerExportsActivationSwitch"
              onChange={(e) => changeFeatureActivation(e,
                'enableCustomerExportsConfirm',
                'disableCustomerExportsConfirm',
                Features.EXPORT_ACTIVATION)}
            />
          )}
          label={localize('customerExportEnabled')}
        />
        <FormControlLabel
          control={(
            <Switch
              checked={cookiesPopinActivated}
              color="primary"
              name="cookiesPopinActivationSwitch"
              onChange={(e) => changeFeatureActivation(e,
                'enableCookiesActivationConfirm',
                'disableCookiesActivationConfirm',
                Features.COOKIES_POPIN_ACTIVATION)}
            />
          )}
          label={localize('cookiesPopinEnabled')}
        />
      </Grid>
    </>
  );
};