/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@material-ui/core/Grid';
import { AutoComplete } from 'commons/AutoComplete/AutoComplete';
import { DialogType } from 'commons/Dialog/CustomDialog';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { useContractInfo } from 'hook/ContractInfoProvider';
import { useDialog } from 'hook/DialogProvider';
import useFetch from 'hook/UseFetch';
import useRealParams from 'hook/UseRealParams';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BillService } from 'services/BillService';
import { ReferenceYearService } from 'services/ReferenceYearService';
import { ProductTestService } from 'services/ProductTestService';
import {
  billNumberRow,
  billTypeRow,
  feeTypeRow,
  issueDateRow,
  paymentReceptionDateRow,
  prestationNumberRow,
  totalAmountRow
} from 'utils/rows/BillRows';
import { INITIAL_PAGE, INITIAL_ROWS_PER_PAGE } from 'commons/Table/CustomTable';

const headers = [
  billNumberRow,
  billTypeRow,
  issueDateRow,
  paymentReceptionDateRow,
  prestationNumberRow,
  feeTypeRow,
  totalAmountRow
];

const isPaidOptions = ['no', 'yes'];
const billTypeOptions = ['bill', 'quotation'];

export const BillPage = () => {
  const {contract: contractId} = useRealParams();
  const history = useHistory();
  const previousController = useRef();
  const showDialog = useDialog();
  const contractInfo = useContractInfo();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const prestations = useFetch(() => ProductTestService.getPrestationsNumbers(contractId), []).response;
  const refYears = useFetch(() => ReferenceYearService.getValidReferenceYears(), []).response;

  useEffect(() => {
    contractInfo.isBillsToPay
      && history.push({
        search: '?isPaid=no'
      });
  }, []);

  const updateData = () => {
    setIsLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    previousController.current = controller;
    let queryString = window.location.search.substring(1);
    if (!queryString.includes('page=')) {
      queryString += `&page=${INITIAL_PAGE}`;
    }
    if (!queryString.includes('rowsPerPage=')) {
      queryString += `&rowsPerPage=${INITIAL_ROWS_PER_PAGE}`;
    }
    BillService.getBills(contractId, queryString, signal)
      .then((response) => {
        setRows(response.bills);
        setTotal(response.count);
        setIsLoading(false);
      })
      .catch((errorMessage) => {
        if (errorMessage !== 'AbortError') {
          showDialog({
            variant: DialogType.ERROR,
            title: 'error',
            description: errorMessage
          });
        }
      });
  };

  return (
    <PageContainer>
      <Grid
        alignItems="center"
        container
        direction="row"
        justify="space-evenly"
      >
        <AutoComplete
          label="year"
          onChange={updateData}
          options={refYears}
          valueName="year"
        />
        <AutoComplete
          isFree
          label="prestationNumber"
          onChange={updateData}
          options={prestations}
          valueName="prestationNumber"
        />
        <AutoComplete
          isDoubleBound
          isMultiple
          isTranslatingOptions
          label="isPaid"
          onChange={updateData}
          options={isPaidOptions}
          valueName="isPaid"
        />
        <AutoComplete
          isTranslatingOptions
          label="billPageType"
          onChange={updateData}
          options={billTypeOptions}
          valueName="billType"
        />
      </Grid>
      <br />
      <CustomTableWithQuerySort
        headers={headers}
        isBackend
        isLoading={isLoading}
        onChange={updateData}
        rowKey="billPageNumber"
        rows={rows}
        total={total}
      />
    </PageContainer>
  );
};