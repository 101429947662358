/* eslint-disable max-len */
import { API_URL } from 'utils/constants/app';
import { RequestHelper } from 'utils/helpers/RequestHelper';

const languageServiceUrl = `${API_URL}/language`;

const localizationUrl = `${languageServiceUrl}/localization`;

const importLanguageFile = (lang, data) => RequestHelper.POST(`${languageServiceUrl}/${lang}/import`, data, { headers: {} });

const exportLanguageFile = (lang) => RequestHelper.GET(`${languageServiceUrl}/${lang}/export`);

const getLocales = () => RequestHelper.GET(`${languageServiceUrl}/locales`);

const changeLocale = (data) => RequestHelper.POST(`${languageServiceUrl}/locale`, data);

export const LanguageService = {
  localizationUrl,
  importLanguageFile,
  exportLanguageFile,
  getLocales,
  changeLocale
};