import { faExclamationSquare } from '@fortawesome/pro-regular-svg-icons';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'commons/Button/CustomButton';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import BasicHeader from 'components/BasicHeader/BasicHeader';
import React from 'react';
import { ErrorType } from 'utils/constants/error.type';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { ROUTES } from 'utils/constants/routes';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: theme.spacing(12)
  },
  headerTitle: {
    marginLeft: 10
  },
  imageContainer: {
    flexGrow: 2,
    minWidth: 80,
    color: theme.palette.error.main
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: theme.spacing(1)
    }
  },
  paragraphItem: {
    margin: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      margin: theme.spacing(1)
    }
  },
  text: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '140%'
    }
  },
  button: {
    maxWidth: 300
  }
}));

export const ErrorPage = ({ errorType = ErrorType.UNAUTHORIZED }) => {
  const classes = useStyles();
  return (
    <>
      <BasicHeader titleMobile={errorType.title} titlePC={errorType.title} />
      <Container
        className={classes.root}
        fixed
      >
        <CustomIcon className={classes.imageContainer} icon={faExclamationSquare} size="10x" />
        <div className={classes.paragraph}>
          <Typography className={classes.paragraphItem} variant="h2">There was an error</Typography>
          <LocalizedText className={`${classes.paragraphItem} ${classes.text}`} variant="body1">
            {errorType.description}
          </LocalizedText>
          {errorType !== ErrorType.UNAUTHORIZED
            && (
              <CustomButton
                className={`${classes.paragraphItem} ${classes.button}`}
                onClick={() => window.location.assign(ROUTES.CHOICE)}
              >
              goBackToContractSelection
              </CustomButton>
            )}
        </div>
      </Container>
    </>
  );
};