import useFetch from 'hook/UseFetch';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { FeatureService } from 'services/FeatureService';
import { GOOGLE_ANALYTICS_TOKEN } from 'utils/constants/app';
import { COOKIES_ACTIVATED } from 'utils/constants/cookies';

const usePageTracking = () => {
  const [initialized, setInitialized] = useState(false);
  const {response} = useFetch(() => FeatureService.getFeatures(true), undefined);
  const [cookies] = useCookies([COOKIES_ACTIVATED]);
  const location = useLocation();
  const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = useState(false);

  useEffect(() => {
    setGoogleAnalyticsEnabled(GOOGLE_ANALYTICS_TOKEN && cookies[COOKIES_ACTIVATED] === 'true' && response?.cookiesPopinActivated);
  }, [cookies, response]);

  useEffect(() => {
    if (googleAnalyticsEnabled) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=" + GOOGLE_ANALYTICS_TOKEN;
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', GOOGLE_ANALYTICS_TOKEN, {
          send_page_view: false
        });
        window.gtag = gtag;
        setInitialized(true);
      };
      document.head.appendChild(script);
    }
  }, [googleAnalyticsEnabled])

  useEffect(() => {
    if (googleAnalyticsEnabled && initialized) {
      window.gtag('event', 'page_view', {
        page_title: location.pathname.split('/').slice(-1)[0],
        page_location: location.pathname + location.search,
        page_path: location.pathname
      });
    }
  }, [location, initialized, cookies, googleAnalyticsEnabled]);
};

export default usePageTracking;