import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  flag: {
    boxShadow: "2px 2px 6px var(--grey)",
    borderRadius: "15%"
  }
}));


export const FlagIcon = (props) => {
  const styles = useStyles();
  const {country, disabled, scale=0} = props;

  const width=20*(2**scale)
  const height=16*(2**scale)
  const flagURL = `https://flagcdn.com/w${width}/${country}.png`;

  let style = props?.style || {};
  if (disabled) {
    style = {...style, filter: "grayscale(1) brightness(2.2)"}
  }

  return (
    <img 
      alt={`flag-${country}`}
      className={styles.flag}
      height={height}
      src={flagURL}
      style={style}
      width={width}
    />
  );
};