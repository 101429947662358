import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Completion from 'commons/Completion/Completion';
import { CustomLinkRouter } from 'commons/Link/CustomLink';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import { useFeatures } from 'hook/FeatureProvider';
import React from 'react';
import { PRESTATION_TYPE } from 'utils/constants/prestation';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 220,
    width: 350,
    margin: 10,
    padding: 0,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: 190,
      width: 300
    }
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  inlineCompletion: {
    display: 'flex',
    marginTop: 4,
    alignItems: 'center'
  },
  flex: {
    display: 'flex'
  },
  indent: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(2)
    }
  },
  campaignText: {
    marginLeft: theme.spacing(1)
  },
  campaignTextBold: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold'
  },
  cardContent: {
    fontSize: '120%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const checkPrestationsDone = (prestations) => prestations
  .filter((prestation) => prestation)
  .map((prestation) => prestation.done === prestation.total)
  .reduce((acc, val) => acc && val, true);

export default function CampaignCard({ campaign }) {
  const classes = useStyles();
  const { auditsActivated } = useFeatures();
  const prestations = [];
  const {
    referenceYear, softwareAudits, onSiteAudits, checkingAudits, samplingOnlyAudits, productTests
  } = campaign;
  softwareAudits && prestations.push({
    ...softwareAudits, ...PRESTATION_TYPE.SOFTWARE_AUDIT
  });
  onSiteAudits && prestations.push({
    ...onSiteAudits, ...PRESTATION_TYPE.ON_SITE_AUDIT
  });
  checkingAudits && prestations.push({
    ...checkingAudits, ...PRESTATION_TYPE.CHECKING
  });
  samplingOnlyAudits && prestations.push({
    ...samplingOnlyAudits, ...PRESTATION_TYPE.SAMPLING_ONLY
  });
  productTests && prestations.push({
    ...productTests, ...PRESTATION_TYPE.PRODUCT_TEST
  });

  const arePrestationsDone = checkPrestationsDone(prestations);

  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.cardHeader}>
        <LocalizedText component="h6" variant="h6">
          campaignHeader
        </LocalizedText>
        &nbsp;
        <Typography component="h6" variant="h6">
          {referenceYear}
        </Typography>
      </div>
      <CardContent className={classes.cardContent}>
        <div className={classes.inlineCompletion}>
          <Completion
            isDone={arePrestationsDone}
            isFull
          />
          <LocalizedText className={classes.campaignTextBold}>
            {arePrestationsDone ? 'workDone' : 'workInProgress'}
          </LocalizedText>
        </div>
        <div className={classes.indent}>
          {
            prestations.map((prestation) => prestation
              && (
                <div className={classes.inlineCompletion} key={prestation.name}>
                  <Completion
                    hasAction={prestation.hasAction}
                    isDone={prestation.done === prestation.total}
                  />
                  {/* TODO AUDIT remove this ConditionalWrapper once audit is always activated */}
                  <ConditionalWrapper
                    condition={prestation.type === PRESTATION_TYPE.PRODUCT_TEST.type || auditsActivated}
                    wrapper={(childrenNodes) => (
                      <CustomLinkRouter to={`${prestation.route}?${prestation.type
                        ? `auditType=${prestation.type}&` : ''}year=${referenceYear}`}
                      >
                        {childrenNodes}
                      </CustomLinkRouter>
                    )}
                  >
                    <div className={classes.flex}>
                      <LocalizedText className={classes.campaignText}>
                        {prestation.name}
                      </LocalizedText>
                      <Typography>
                        {`: ${prestation.done}/${prestation.total}`}
                      </Typography>
                    </div>
                  </ConditionalWrapper>
                </div>
              ))
          }
        </div>
      </CardContent>
    </Card>
  );
}