import { faVial } from '@fortawesome/pro-solid-svg-icons';
import { Step as MatStep, StepLabel, Typography } from '@material-ui/core';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomStepConnector, StepIcon, useCustomStepperStyles } from 'commons/CustomStepper/CustomStepper.styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

export const StepModel = {
  id: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool,
  label: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({}))
};

const purpleMain = '#692984';
const purpleLighter = 'rgb(237, 220, 245)';
const purpleDark = '#3F1A50';

const ParentStepTitle = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  color: ${purpleMain};
  font-weight: 600;
  transform: ${(props) => `translateX(-${Number(props.multiStepWidth / 2)}px)`};
`;

const StyledMultiSteps = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  top: 10px;
  padding: 5px 25px;
  border-radius: 20px;
  border: 1px solid ${purpleMain};
  background-color: #eddcf5;
  transition: all 0.3s;
  z-index: 10;

  ${(props) => props.isCollapsed && props.isInteractive && css`
    background-color: ${purpleLighter};

    .MuiStepLabel-label {
      height: 22px;
    }
  `}
`;

// eslint-disable-next-line object-curly-newline
const StyledStep = styled(({ isCollapsed, isInteractive, ...props }) => <MatStep {...props} />)`
  position: relative;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  ${(props) => !props.isInteractive && css`pointer-events:none;`}

  ${(props) => !props.isCollapsed && css`
    &:hover {
      > .MuiStepLabel-root .stepIcon {
        background-color: ${purpleDark};
        border: '2px solid ${purpleDark}';
      }

      > .MuiStepLabel-root .MuiTypography-body1 {
        font-weight: 600
      }
    }
  `}

  ${(props) => props.isCollapsed && css`
    &:hover ${StyledMultiSteps} {
      background-color: ${purpleMain};

      .MuiTypography-body1 {
        font-weight: 600
      }
    }
  `}
`;

/**
 * This is absolute dogshit but it's the only way to approximately animate a single step by adding a class with animation and
 * removing it at the end of the animation
 */
function handlePulseAnimation(event) {
  const eventTarget = event.currentTarget;
  eventTarget.classList.add('shadow-pulse');
  setTimeout(() => eventTarget.classList.remove('shadow-pulse'), 500);
}

const Step = ({
  id,
  label,
  isCollapsed,
  steps,
  allSteps,
  setAllSteps,
  onClick,
  statuses,
  allStepsFlat,
  statusesNumber,
  isInteractive
}) => {
  const classes = useCustomStepperStyles();
  const hasSubsteps = steps && steps.length > 0;
  const statusesId = statuses.map((status) => allStepsFlat.find((step) => step.label === status))
    .filter((stat) => stat !== -1);
  const failureItem = allStepsFlat.find((step) => step.label === 'FAILURE');
  const isBeforeLastStep = Boolean(allSteps[allSteps.length - 2].id === id);
  const isLastStep = Boolean(allSteps[allSteps.length - 1].id === id);

  const completed = statusesId ? Boolean(statusesId.find((stat) => stat?.id === id)) : false;
  const disabled = failureItem ? Boolean(id === failureItem?.id) : false;

  const multiStepDiv = document.getElementById(`multiStep_${id}`);
  const multiStepWidth = hasSubsteps && multiStepDiv ? multiStepDiv.offsetWidth : 0;

  const handleClick = useCallback(({
    event,
    id,
    substeps,
    isCollapsed,
    isInteractive
  }) => {
    !isCollapsed && event.stopPropagation();

    if (!isCollapsed && isInteractive) {
      handlePulseAnimation(event);
    }

    if (substeps) {
      const newSteps = [...allSteps]
        .map((step) => {
          if (step.id === id) {
            return ({
              ...step,
              isCollapsed: !step.isCollapsed
            });
          }

          return step;
        });

      return setAllSteps(newSteps);
    }

    return !isCollapsed && onClick(id);
  }, [onClick, setAllSteps, allSteps]);

  const StepInterior = ({
    label = '', disabled, completed,
    isCollapsed = false, multiSteps = false
  }) => (
    <StepLabel
      alternativeLabel
      className={`${completed ? classes.completedLabel : classes.basicLabel}`}
      completed={completed}
      disabled={disabled}
      error={disabled}
      StepIconComponent={null}
    >
      {!multiSteps && <StepIcon completed={completed} error={disabled} />}

      {((multiSteps && isCollapsed) || !multiSteps) && (
        <>
          <LocalizedText>{label}</LocalizedText>

          {
            statusesNumber[label] && (
              <>
                <CustomIcon className={classes.statusesNumberIcon} icon={faVial} />
                <Typography component="span" variant="body1">{statusesNumber[label]}</Typography>
              </>
            )
          }

        </>
      )}
    </StepLabel>
  );

  const SubStep = ({
    label = '', disabled, isFirstSubStep,
    completed, isCollapsed = false
  }) => (
    <StepLabel
      className={`${completed ? classes.completedLabel : classes.basicLabel}`}
      disabled={disabled}
      StepIconComponent={null}
      style={{ textAlign: 'center' }}
    >
      <StepIcon completed={completed} error={disabled} />

      {!isCollapsed && (
        <>
          {isFirstSubStep && (
            <CustomStepConnector isFirstSubStep />
          )}
          <LocalizedText>{label}</LocalizedText>
          <CustomStepConnector isSubStep />
        </>
      )}
    </StepLabel>
  );

  return (
    <StyledStep
      disabled={disabled}
      isCollapsed={isCollapsed}
      isInteractive={isInteractive}
      onClick={(e) => handleClick({
        event: e, id, substeps: hasSubsteps, isCollapsed, isInteractive
      })}
    >
      {hasSubsteps && (
        <StyledMultiSteps isCollapsed={isCollapsed}>
          {!isCollapsed && (
            <ParentStepTitle id={`multiStep_${id}`} multiStepWidth={multiStepWidth}>
              <LocalizedText>{label}</LocalizedText>
            </ParentStepTitle>
          )}

          {steps.map((subStep, index) => (
            <div key={subStep.id}>
              <StyledStep
                isInteractive={isInteractive}
                key={subStep.id}
                onClick={(e) => handleClick({
                  event: e, id: subStep.id, substeps: false, isCollapsed, isInteractive
                })}
              >
                <SubStep
                  {...subStep}
                  completed={Boolean(statusesId.find((stat) => stat?.id === subStep.id))}
                  disabled={disabled}
                  isCollapsed={isCollapsed}
                  isFirstSubStep={index === 0}
                />
              </StyledStep>
            </div>
          ))}
        </StyledMultiSteps>
      )}

      {!isLastStep && (
        <CustomStepConnector
          alternativeLabel
          disabled={(disabled || (id === Number(failureItem?.id - 1)))}
          multiSteps={hasSubsteps}
          stepCollapsed={isCollapsed}
          withArrow={isBeforeLastStep}
        />
      )}

      <StepInterior
        completed={completed}
        disabled={disabled}
        isCollapsed={isCollapsed}
        label={label}
        multiSteps={hasSubsteps}
      />
    </StyledStep>
  );
};

Step.propTypes = StepModel;

Step.defaultProps = {
  isCollapsed: null,
  steps: null
};

export default Step;