/* eslint-disable react/forbid-prop-types */
import { faCheckCircle, faClock } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as faCheckCircleSolid, faClock as faClockSolid } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@material-ui/core';
import { BadgePulse } from 'commons/BadgePulse/BadgePulse';
import CustomIcon from 'commons/Icon/CustomIcon';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import * as PropTypes from 'prop-types';
import React from 'react';

const Completion = ({
  isDone, hasAction, isFull
}) => {
  const theme = useTheme();
  return (
    <ConditionalWrapper
      condition={hasAction}
      wrapper={(childrenNodes) => (
        <BadgePulse>
          {childrenNodes}
        </BadgePulse>
      )}
    >
      <CustomIcon
        color={isDone ? theme.palette.success.main : theme.palette.warning.main}
        icon={isDone ? (isFull ? faCheckCircleSolid : faCheckCircle) : (isFull ? faClockSolid : faClock)}
        size={isFull ? 'lg' : '1x'}
      />
    </ConditionalWrapper>
  );
};

Completion.propTypes = {
  hasAction: PropTypes.bool,
  isDone: PropTypes.bool.isRequired,
  isFull: PropTypes.bool
};

Completion.defaultProps = {
  hasAction: false,
  isFull: false
};

export default Completion;