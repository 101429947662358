import {faCircleNotch} from '@fortawesome/pro-regular-svg-icons';
import {faSave} from '@fortawesome/pro-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {FloatingButton} from 'commons/Button/FloatingButton';
import {FloatingButtonMenu} from 'commons/Button/FloatingButtonMenu';
import {DialogType} from 'commons/Dialog/CustomDialog';
import CustomIcon from 'commons/Icon/CustomIcon';
import PageContent from 'components/PageContent/PageContent';
import {useCustomerInfo} from 'hook/CustomerInfoProvider';
import {useDialog} from 'hook/DialogProvider';
import useMobile from 'hook/UseMobile';
import {AccountSection} from 'pages/administration/useradministration/AccountSection';
import {ContractsSection} from 'pages/administration/useradministration/ContractsSection';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ContractService} from 'services/ContractService';
import {UserService} from 'services/UserService';
import {isAdmin} from 'utils/constants/roles';
import {GENERATE_ROUTE, ROUTES} from 'utils/constants/routes';
import LocalizedText, {localize} from '../../commons/LocalizedText/LocalizedText';
import useLocalizedSnackBar from '../../hook/UseLocalizedSnackBar';
import {ContactInformationSection} from './useradministration/ContactInformationSection';

const useStyles = makeStyles(() => ({
  bigSpinner: {
    color: 'grey'
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    top: '50%',
    left: '50%'
  },
  gridItem:{
    minInlineSize: 'fit-Content'
  }
}));

export const AdministrationCreateUserPage = (props) => {
  const { contract, user : userId } = useParams();
  const history = useHistory();
  const { role } = useCustomerInfo();
  const isMobile = useMobile();
  const showDialog = useDialog();
  const showSnackbar = useLocalizedSnackBar();
  const classes = useStyles();
  // eslint-disable-next-line max-len
  const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [lastname, setLastname] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [civility, setCivility] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [company, setCompany] = useState(null);
  const [profile, setProfile] = useState(null);
  const [status, setStatus] = useState('Enabled');
  const [language, setLanguage] = useState(null);
  const [seeAllContracts, setSeeAllContracts] = useState(null);
  const [contractList, setContractList] = useState([]);
  const [emailSSO, setEmailSSO] = useState(null);
  const [availableContracts, setAvailableContracts] = useState([]);
  const [isLoadingContracts, setIsLoadingContracts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const setError = useCallback((field, value) => {
    setErrors(oldErrors => {
      const newErrors = { ...oldErrors };
      newErrors[field.name] = value;
      return newErrors;
    });
  }, []);

  const validateFormatFields = () => {
    return Object.values(errors).every(v => !!v === false)
  }

  const areAllRequiredFieldsFilled = () => {
    return lastname && firstname && company
      && emailSSO && profile
      && status && language
      && (seeAllContracts || (!seeAllContracts && contractList.length > 0));
  };

  const openEditUser = (contractid, personRecid) => {
    const editUserLink = GENERATE_ROUTE(
      ROUTES.ADMINISTRATION.USERS.EDIT,
      {
        "contract": contractid,
        "user": personRecid
      }
    );
    history.push(editUserLink);
  };


  useEffect(() => {
    const fillCustomerContactInformation = (customerContactInformation) => {
      setFirstname(customerContactInformation.firstname);
      setLastname(customerContactInformation.lastname);
      setCivility(customerContactInformation.civility);
      setPhoneNumber(customerContactInformation.phoneNumber);
      setCompany(customerContactInformation.companyNameAndId);
    };

    const fillCustomerContractInformation = (customerContractInformation) => {
      setSeeAllContracts(customerContractInformation.seeAllContracts);
      setContractList(customerContractInformation.contractList);
    };

    const fillCustomerAccountInformation = (customerAccountInformation) => {
      setEmailSSO(customerAccountInformation.emailSSO);
      setProfile(customerAccountInformation.profile);
      setStatus(customerAccountInformation.status);
      setLanguage(customerAccountInformation.language);
    };

    const setAresUserInfos = (response) => {
      fillCustomerContactInformation(response.customerContactInformation);
      fillCustomerContractInformation(response.customerContractInformation);
      fillCustomerAccountInformation(response.customerAccountInformation);
    }

    if (userId) { // if edit mode
      setIsLoading(true)
      UserService.getAresUserDetailedInfo(userId).then((resp) => {
        setAresUserInfos(resp);
      })
        .catch((error)=> showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: localize(error)
        }))
        .finally(() => setIsLoading(false));
    } else { // if create mode
      setSeeAllContracts(true);
    }
  }, [
    // reactive values that should not change
    userId, // from route
    // non reactive values
    showDialog
  ]);

  useEffect(() => {
    if (company && !isLoading) {
      setIsLoadingContracts(true);
      ContractService.getContractAssociatedToCompany(company.companyEncryptedId).then(response => {
        setAvailableContracts(response);
        setContractList([])
        setIsLoadingContracts(false);
      });
    }
  }, [
    // reactive values
    company,
    isLoading
  ]);

  const isMailValid = (toValidateMail)  => {
    return toValidateMail.match(mailRegex);
  }

  const saveUserAction = () => {
    if (areAllRequiredFieldsFilled() && validateFormatFields() && isMailValid(emailSSO)) {
      saveUser();
    } else if (!isMailValid(emailSSO)) {
      showDialog({
        variant: DialogType.ERROR,
        title: 'Error',
        description: 'errorInvalidMail'
      });
    } else {
      showDialog({
        variant: DialogType.ERROR,
        title: 'Error',
        description: 'errorMissingRequiredFields'
      });
    }
  }

  const saveUser = () => {
    setIsSaveLoading(true);
    let contactInformation = {
      lastname,
      firstname,
      civility,
      phoneNumber,
      companyNameAndId: company
    };
    let account = {
      emailSSO,
      profile,
      status,
      language
    };
    let contracts = {
      seeAllContracts,
      contractList
    }
    let customerUserInformation = {
      personContactDetailsRecid: userId,
      customerContactInformation: contactInformation,
      customerAccountInformation: account,
      customerContractInformation: contracts
    };

    if (userId) {
      UserService.updateAresUser(userId, customerUserInformation)
        .then(() => showSnackbar('savedUser', {variant: 'success'}))
        .catch((error)=> showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: localize(error)
        }))
        .finally(() => setIsSaveLoading(false));
    } else {
      UserService.createAresUser(customerUserInformation)
        .then(response => {
          showSnackbar('savedUser', { variant: 'success' });
          openEditUser(contract, response.personRecid);
        })
        .catch((error)=> showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: localize(error)
        }))
        .finally(() => setIsSaveLoading(false));
    }

  };

  return (
    <PageContent>
      {
        (
          isAdmin(role)
          && (
            <>
              <Grid container direction={isMobile ? 'column' : 'row'} xs={12}>
                <Grid className={classes.gridItem} item xs={6} >
                  <ContactInformationSection
                    civility={civility}
                    companyName={company?.companyName}
                    errors={errors}
                    firstname={firstname}
                    lastname={lastname}
                    phoneNumber={phoneNumber}
                    setCivility={setCivility}
                    setCompany={setCompany}
                    setError={setError}
                    setFirstname={setFirstname}
                    setLastname={setLastname}
                    setPhoneNumber={setPhoneNumber}
                    userPrograms={props.userPrograms}
                  />
                </Grid>
                <Grid className={classes.gridItem} item xs={6}>
                  <AccountSection
                    emailSSO={emailSSO}
                    isCreationMode={!userId}
                    language={language}
                    profile={profile}
                    setEmailSSO={setEmailSSO}
                    setLanguage={setLanguage}
                    setProfile={setProfile}
                    setStatus={setStatus}
                    status={status}
                  />
                </Grid>
                <Grid className={classes.gridItem} item xs={6}>
                  <ContractsSection
                    availableContracts={availableContracts}
                    contractList={contractList}
                    isLoadingContracts={isLoadingContracts}
                    seeAllContracts={seeAllContracts}
                    setContractList={setContractList}
                    setSeeAllContracts={setSeeAllContracts}
                  />
                </Grid>
                <Grid item xs={6}>
                  {isSaveLoading
                    && (
                      <div className={classes.centeredContent}>
                        <CustomIcon className={classes.bigSpinner} icon={faCircleNotch} size="6x" spin/>
                      </div>
                    )
                  }
                </Grid>
              </Grid>
              <FloatingButtonMenu>
                <FloatingButton
                  disabled={!areAllRequiredFieldsFilled() || isSaveLoading || !validateFormatFields()}
                  icon={faSave}
                  isMainButton={true}
                  onClick={() => saveUserAction()}
                  tooltip={<LocalizedText>save</LocalizedText>}
                />
              </FloatingButtonMenu>
            </>
          )
        )
      }
    </PageContent>
  );
};