import CookieDialog from 'commons/CookieDialog/CookieDialog';
import useFetch from 'hook/UseFetch';
import React, { createContext, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { CustomerService } from 'services/CustomerService';
import { FeatureService } from 'services/FeatureService';
import { COOKIES_ACTIVATED } from 'utils/constants/cookies';
import { GENERATE_ROUTE } from 'utils/constants/routes';
import { ACCOUNT_TABS_OBJECT, GENERAL_ACCOUNT_TABS_OBJECT } from 'utils/constants/tabs';

const CustomerInfoContext = createContext(null);
/**
 * @typedef {Object} Privileges
 * @property {String} userName
 * @property {String} role
 * @property {String} firstName
 * @property {String} lastName
 * @property {int} personNumber
 * @property {boolean} connectedAsUser
 */
// noinspection JSValidateTypes
/**
 * Return role of the current logged in customer as string of the enum Roles
 * @returns {Privileges}
 */
export const useCustomerInfo = () => useContext(CustomerInfoContext);

export const CustomerInfoProvider = ({ children }) => {
  const customerInfo = useFetch(() => CustomerService.getCustomerInfo(), {}).response;
  const features = useFetch(() => FeatureService.getFeatures(), {}).response;
  const [cookies, setCookie] = useCookies([COOKIES_ACTIVATED]);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    if (cookies[COOKIES_ACTIVATED] == null) {
      setOpen(true);
    }
  }, [customerInfo, cookies]);

  const agreeCookies = () => {
    setCookie(COOKIES_ACTIVATED, true);
    handleClose();
  };

  const declineCookies = () => {
    setCookie(COOKIES_ACTIVATED, false);
    handleClose();
  };

  const moreOptions = () => {
    setCookie(COOKIES_ACTIVATED, true);
    const url = getMoreOptionsUrl();
    return history.push(url);
  };

  const getMoreOptionsUrl = () => {
    handleClose();
    const url = window.location.href;

    if (!url.includes('/contract/')) {
      return GENERATE_ROUTE(GENERAL_ACCOUNT_TABS_OBJECT.COOKIES.ROUTE);
    } else {
      const indexOfContractNumber = url.split('/').indexOf('contract') + 1;
      return GENERATE_ROUTE(ACCOUNT_TABS_OBJECT.COOKIES.ROUTE, {
        contract: url.split('/')[indexOfContractNumber]
      });
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomerInfoContext.Provider
      value={customerInfo}
    >
      {
        features?.cookiesPopinActivated &&
        <CookieDialog
          onAgree={agreeCookies}
          onClose={handleClose}
          onDecline={declineCookies}
          onMoreOptions={moreOptions}
          open={open}/>
      }
      {children}
    </CustomerInfoContext.Provider>
  );
};