/* eslint-disable max-len */
import { API_URL } from 'utils/constants/app';
import { queryStringFromDict, RequestHelper } from 'utils/helpers/RequestHelper';

const testServiceUrl = `${API_URL}/test`;

const getTestNumberAllowed = (contractId, testId) => RequestHelper.GET(`${testServiceUrl}/${contractId}/${testId}/authorize`);

const getTests = (contractId) => RequestHelper.GET(`${testServiceUrl}/${contractId}/tests`);

const getTestDetails = (contractId, testId) => RequestHelper.GET(`${testServiceUrl}/${contractId}/${testId}/details`);

const getTestDocuments = (testId, { order, orderBy }, { page, rowsPerPage }) =>
  RequestHelper.GET(`${testServiceUrl}/${testId}/documents?${
    queryStringFromDict({order, orderBy, page, rowsPerPage})
  }`);

const getStepperStatuses = () => RequestHelper.GET(`${testServiceUrl}/stepperStatuses`);

const getTestStatuses = () => RequestHelper.GET(`${testServiceUrl}/statuses`);

const getPrestationsNumbers = (contractId) => RequestHelper.GET(`${testServiceUrl}/${contractId}/prestations`);
const getUploadableFileTypes = (testId) => RequestHelper.GET(`${testServiceUrl}/${testId}/uploadableFileTypes`);

const uploadProductTestDocument = (testId, formData) => RequestHelper.POST_FORMDATA(`${testServiceUrl}/${testId}/uploadDocument`, formData);

const removeProductTestDocument = (testId, fileId, isForDeletion) => RequestHelper.POST(`${testServiceUrl}/${testId}/removeDocument/${fileId}`, isForDeletion);

export const ProductTestService = {
  getTestNumberAllowed,
  getTests,
  getTestDetails,
  getPrestationsNumbers,
  getStepperStatuses,
  getTestStatuses,
  getTestDocuments,
  getUploadableFileTypes,
  removeProductTestDocument,
  uploadProductTestDocument
};