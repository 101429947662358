import { AutoComplete } from 'commons/AutoComplete/AutoComplete';
import * as PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'utils/processing/filters';

/**
 * Autocomplete filter to filter value on a grid the selected value(s) is(are) synchronized in the query string
 * @param options list of available options
 * @param label translated label of the field
 * @param isFree is selection of items not in the list possible
 * @param valueName identifier of the field name
 * @param filter curried function to filter a row by a value
 * @param updateFilter callback to call when value changed
 * @param isDoubleBound does the component need to react to url change
 * @param isMultiple is the field accepting multiple selects
 * @param className is used to apply style
 * @param isTranslatingOptions function to translate options if needed
 * @param optionService service to load options from
 */
export const AutocompleteFilter = ({
  options, label, isFree, valueName, filter, updateFilter, isDoubleBound,
  isMultiple, className, isTranslatingOptions, optionService
}) => {
  const handleOnChange = (event, newValue) => {
    if (isEmpty(newValue)) {
      updateFilter(valueName, null);
    } else {
      updateFilter(valueName, filter(newValue));
    }
  };

  return (
    <AutoComplete
      className={className}
      isDoubleBound={isDoubleBound}
      isFree={isFree}
      isMultiple={isMultiple}
      isTranslatingOptions={isTranslatingOptions}
      label={label}
      onChange={handleOnChange}
      options={options}
      optionService={optionService}
      valueName={valueName}
    />
  );
};

AutocompleteFilter.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.func.isRequired,
  isFree: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isTranslatingOptions: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  optionService: PropTypes.func,
  updateFilter: PropTypes.func.isRequired,
  valueName: PropTypes.string.isRequired
};

AutocompleteFilter.defaultProps = {
  className: '',
  isFree: false,
  isMultiple: false,
  isTranslatingOptions: false,
  options: [],
  optionService: null
};