import { faExternalLink, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { DialogType } from 'commons/Dialog/CustomDialog';
import CustomIcon from 'commons/Icon/CustomIcon';
import { CustomLink } from 'commons/Link/CustomLink';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { useDialog } from 'hook/DialogProvider';
import { useFeatures } from 'hook/FeatureProvider';
import useFetch from 'hook/UseFetch';
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import React, { useState } from 'react';
import { DashboardService } from 'services/DashboardService';

const useStyles = makeStyles((theme) => ({
  listingButtonWrapper: {
    marginLeft: theme.spacing(4)
  },
  listingButton: {
    padding: 4,
    color: theme.palette.primary.main
  }
}));

export default function ListingInfo({ contract }) {
  const showDialog = useDialog();
  const enqueueSnackbar = useLocalizedSnackBar();
  const classes = useStyles();
  const {exportsActivated, prodbimActivated} = useFeatures();
  const [isListingLoading, setIsListingLoading] = useState(false);
  const contractListingInfo = useFetch(() => DashboardService.getContractListingInfo(contract), {}).response;
  const { contractId, sendByMail } = contractListingInfo;

  const downloadListingFile = () => {
    setIsListingLoading(true);
    DashboardService.exportContract(contractId, sendByMail)
      .finally(() => setIsListingLoading(false));
  };

  const sendListingByMail = () => {
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: 'confirmExportSendByMail',
      onSubmit: () => {
        setIsListingLoading(true);
        enqueueSnackbar('contractExportTakenIntoAccount', { variant: 'success' });
        DashboardService.exportContract(contractId, sendByMail)
          .finally(() => setIsListingLoading(false));
      }
    });
  };

  return (
    <div>
      {
        exportsActivated
        && (
          <CustomTooltip title="downloadListingTooltip">
            <span className={classes.listingButtonWrapper}>
              <IconButton
                className={classes.listingButton}
                onClick={sendByMail ? sendListingByMail : downloadListingFile}
              >
                <CustomIcon icon={isListingLoading ? faSpinner : faFileDownload} spin={isListingLoading} />
              </IconButton>
            </span>
          </CustomTooltip>
        )
      }
      {
        prodbimActivated
        && (
          <CustomTooltip title="prodbimLinkTooltip">
            <CustomLink
              className={classes.listingButtonWrapper}
              href="https://prodbim.riversand.com/home"
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton
                className={classes.listingButton}
              >
                <CustomIcon icon={faExternalLink} />
              </IconButton>
            </CustomLink>
          </CustomTooltip>
        )
      }
    </div>
  );
}