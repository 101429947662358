import Grid from '@material-ui/core/Grid';
import { AutocompleteFilter } from 'commons/Filter/AutocompleteFilter/AutocompleteFilter';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import useFilters from 'hook/UseFilters';
import React from 'react';
import { valueContained } from 'utils/processing/filters';
import { billNumberRow, issueDateRow, paymentReceptionDateRow, totalAmountRow } from 'utils/rows/BillRows';

export const BillsList = ({
  bills, isLoading
}) => {
  const [filters, updateFilters] = useFilters();

  const headers = [
    billNumberRow,
    issueDateRow,
    paymentReceptionDateRow,
    totalAmountRow
  ];

  return (
    <>
      <Grid
        alignItems="center"
        container
        direction="row"
        justify="space-evenly"
      >
        <AutocompleteFilter
          filter={(value) => (row) => String(row.year) === value}
          label="year"
          options={bills && bills.map((r) => String(r.year))}
          updateFilter={updateFilters}
          valueName="year"
        />
        <AutocompleteFilter
          filter={(value) => (row) => valueContained(value, row.billNumber)}
          label="billNumber"
          options={bills && bills.map((r) => r.billNumber)}
          updateFilter={updateFilters}
          valueName="billNumber"
        />
      </Grid>
      <br />
      <CustomTableWithQuerySort
        filters={filters}
        headers={headers}
        isLoading={isLoading}
        rowKey="billNumber"
        rows={bills}
      />
    </>
  );
};