/* eslint-disable max-len */
import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const billServiceUrl = `${API_URL}/bills`;

const getBills = (contractId, query, signal) => RequestHelper.GET(`${billServiceUrl}/${contractId}/billsInfo?${query}`, {signal});

const getBillsToPay = (contractId) => RequestHelper.GET(`${billServiceUrl}/${contractId}/topay`);

export const BillService = {
  getBills,
  getBillsToPay
};