import * as PropTypes from 'prop-types';
import React from 'react';

import LocalizedText from 'commons/LocalizedText/LocalizedText';
import {format} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {ColumnDisplay} from 'utils/helpers/ColumnDisplay';
import {useStyles} from 'components/News/NewsForm';

export const AccountSection = ({news, dateFormat}) => {
  const styles = useStyles();

  const creationDict = {
    "Creation user": news?.creationUser || "",
    "Creation date": (news?.creationDate ? format(new Date(news.creationDate), dateFormat) : "")
  }

  const modificationDict = {
    "Creation user": news?.modificationUser || "",
    "Creation date": (news?.modificationDate ? format(new Date(news.modificationDate), dateFormat) : "")
  }

  return (
    <div className={styles.section}>
      <Grid
        container
        direction="column" 
        justifyContent="space-between"
        spacing={5}
      >
        <Grid item>
          <LocalizedText className={styles.title}>Creation details</LocalizedText>
          <ColumnDisplay valueDict={creationDict} verticalSpacing={2}/>
        </Grid>
        <Grid item>
          <LocalizedText className={styles.title}>Modification details</LocalizedText>
          <ColumnDisplay valueDict={modificationDict} verticalSpacing={2}/>
        </Grid>
      </Grid>

    </div>
  )
}

AccountSection.propTypes = {
  dateFormat: PropTypes.shape({}).isRequired,
  news: PropTypes.shape({}).isRequired
}