/* eslint-disable max-len */
import { API_URL } from 'utils/constants/app';
import { RequestHelper } from 'utils/helpers/RequestHelper';

const auditServiceUrl = `${API_URL}/audit`;

const getAudits = (contractId) => RequestHelper.GET(`${auditServiceUrl}/${contractId}/audits`);

const getAuditNumberAllowed = (contractId, auditId) => RequestHelper.GET(`${auditServiceUrl}/${contractId}/${auditId}/authorize`);

const getAuditDetails = (contractId, auditId) => RequestHelper.GET(`${auditServiceUrl}/${contractId}/${auditId}/details`);

const getAuditStatuses = () => RequestHelper.GET(`${auditServiceUrl}/statuses`);

const getStepperStatuses = () => RequestHelper.GET(`${auditServiceUrl}/stepperStatuses`);

const getAuditTypes = () => RequestHelper.GET(`${auditServiceUrl}/types`);

export const AuditService = {
  getAudits,
  getAuditStatuses,
  getStepperStatuses,
  getAuditTypes,
  getAuditNumberAllowed,
  getAuditDetails
};