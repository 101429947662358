import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AutoCompleteWithLabel } from 'commons/AutoComplete/AutoCompleteWithLabel';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { TextFieldWithLabel } from 'commons/TextFieldWithLabel/TextFieldWithLabel';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React, { useEffect, useState } from 'react';
import { CustomerService } from 'services/CustomerService';
import { LanguageService } from 'services/LanguageService';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { isAdmin } from 'utils/constants/roles';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  dropdown: {
    width: '90%',
    margin: 0
  },
  divider: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: '30px',
    marginBottom: '10px',
    '&::before': {
      content: '"-"',
      backgroundColor: theme.palette.secondary.main,
      marginRight: '10px'
    }
  },
  root: () => ({
    flexGrow: 1,
    width: '60%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    }
  })
}));

export const AccountSection = ({
  emailSSO,
  language,
  isCreationMode,
  profile,
  setEmailSSO,
  setLanguage,
  setProfile,
  setStatus,
  status
}) => {
  const classes = useStyles();
  const { role } = useCustomerInfo();

  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [availableProfiles, setAvailableProfiles] = useState([]);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);

  useEffect(() => {
    setIsLoadingLanguages(true);
    LanguageService.getLocales()
      .then(response => {
        setAvailableLanguages(response.map(languageView => languageView.name));
        setIsLoadingLanguages(false);
      });
  }, []);

  useEffect(() => {
    if (isAdmin(role)) {
      setIsLoadingProfiles(true);
      CustomerService.getCustomerRoles()
        .then(response => {
          setAvailableProfiles(response);
          setIsLoadingProfiles(false);
        });
    }
  }, [ // should only run on init
    // reactive values that shouldn't change
    role // from useFetch
  ]);

  useEffect(() => {
    setIsLoadingStatuses(true);
    CustomerService.getAccountStatuses().then(response => {
      setAvailableStatuses(response);
      setIsLoadingStatuses(false);
    })
  }, []);

  const emailSSOField = (
    <TextFieldWithLabel
      classes={{
        root: classes.root
      }}
      disabled={!isCreationMode}
      label="emailSSO"
      labelXS={3}
      margin="normal"
      onChange={(event) => setEmailSSO(event)}
      required
      textFieldXS={9}
      value={emailSSO}
    />
  );

  const profileField = (
    <AutoCompleteWithLabel
      autoCompleteXS={9}
      className={classes.dropdown}
      isLoading={isLoadingProfiles}
      isTranslatingOptions
      label="profile"
      labelXS={3}
      onChange={(event) => setProfile(event)}
      options={availableProfiles}
      required
      value={profile}
      valueName="profile"/>
  );

  const statusField = (
    <AutoCompleteWithLabel
      autoCompleteXS={9}
      className={classes.dropdown}
      disabled={isCreationMode}
      isLoading={isLoadingStatuses}
      isTranslatingOptions
      label="status"
      labelXS={3}
      onChange={(event) => setStatus(event)}
      options={availableStatuses}
      required
      value={status}
      valueName="status"/>
  );


  const languageField = (
    <AutoCompleteWithLabel
      autoCompleteXS={9}
      className={classes.dropdown}
      isLoading={isLoadingLanguages}
      isTranslatingOptions
      label="language"
      labelXS={3}
      onChange={(event) => setLanguage(event)}
      options={availableLanguages}
      required
      value={language}
      valueName="language"/>
  );

  return (
    <>
      {
        isAdmin(role)
        && (
          <Grid container direction="column">
            <Grid item>
              <LocalizedText className={classes.title}>
                account
              </LocalizedText>
            </Grid>
            <Grid item>
              {emailSSOField}
            </Grid>
            <Grid item>
              {profileField}
            </Grid>
            <Grid item>
              {statusField}
            </Grid>
            <Grid item>
              {languageField}
            </Grid>
          </Grid>
        )
      }
    </>
  );
};