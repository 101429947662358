import React from 'react';
import useRealParams from 'hook/UseRealParams';
import { BillService } from 'services/BillService';
import useFetch from 'hook/UseFetch';
import { BillsList } from 'components/BillsList/BillsList';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rightText: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  boldText: {
    fontWeight: 'bold'
  },
  marginText: {
    marginLeft: theme.spacing(5)
  }
}
));

export const PrestationBillPage = () => {
  const {contract: contractId, audit: auditId, test: testId} = useRealParams();
  const classes = useStyles();
  const prestationNumber = auditId ?? testId;
  const {
    response,
    isLoading
  } = useFetch(() => BillService.getBills(contractId, `prestationNumber=${prestationNumber}`), {bills: []});
  const {bills} = response;
  const total = bills.reduce((sum, cur) => sum + cur.totalAmount, 0)?.toFixed(2);
  return (
    <>
      <BillsList bills={bills} isLoading={isLoading} />
      <br />
      <div className={classes.rightText}>
        <Typography className={classes.boldText}>
          <LocalizedText text="total" />
                :
        </Typography>
        <Typography className={classes.marginText}>
          {`${total} €`}
        </Typography>
      </div>
    </>
  );
};