export const NewsAdminProjection = {
  recid: 'recid',
  creationDate: 'creationDate',
  creationUser: 'creationUser',
  modificationDate: 'modificationDate',
  modificationUser: 'modificationUser',
  startDate: 'startDate',
  endDate: 'endDate',
  number: 'number',
  origin: 'origin',
  translations: ['translation'],
  state: 'state'
}

export const NewsTranslations = {
  languageCode: 'languageCode',
  title: 'title',
  content: 'content'
}

export const NewsState = {
  UPCOMING: 'UPCOMING',
  ONGOING: 'ONGOING',
  OUTDATED: 'OUTDATED'
}