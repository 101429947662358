import { makeStyles } from '@material-ui/core/styles';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import React from 'react';
import { TestStatuses } from 'utils/constants/test.status';

const useStyles = makeStyles((theme) => ({
  Ok: {
    fontWeight: 'bold',
    color: theme.palette.success.dark
  },
  notOk: {
    fontWeight: 'bold',
    color: theme.palette.error.dark
  },
  cancelled: {
    fontWeight: 'bold',
    color: theme.palette.common.black
  }
}));

const TestStatusDisplay = ({ status, isSuccess }) => {
  const classes = useStyles();
  let color = '';
  if (isSuccess != null) {
    color = (isSuccess ? classes.Ok : classes.notOk);
  }
  if (status === TestStatuses.CANCELLED) {
    color = classes.cancelled;
  }
  return (
    <ConditionalWrapper
      condition={status === TestStatuses.COMPLETED}
      wrapper={(childrenNodes) => (
        <CustomTooltip title={isSuccess ? 'success' : 'failure'}>
          <div>
            {childrenNodes}
          </div>
        </CustomTooltip>
      )}
    >
      <LocalizedText className={color} variant="body2">{status}</LocalizedText>
    </ConditionalWrapper>
  );
};

export default TestStatusDisplay;