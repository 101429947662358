import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 0,
    animation: 'error-pulse 10s infinite'
  }
}));

export const BadgePulse = ({ children }) => {
  const classes = useStyles();
  return (
    <CustomTooltip title="itemAwaitingAction">
      <Badge classes={{ badge: classes.root }} color="error" variant="dot">
        {children}
      </Badge>
    </CustomTooltip>
  );
};