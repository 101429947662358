/* eslint-disable max-len */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileDownloader from 'commons/Icon/FileDownloader';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import React from 'react';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';

const headers = [
  generateRow({
    name: 'fileType',
    template: (row) => (
      <LocalizedText>
        {' '}
        {row.fileType}
        {' '}
      </LocalizedText>
    )
  }),
  generateRow({
    name: 'file',
    template: (row) => (
      <CustomTooltip title="downloadFileToolip">
        <span>
          <FileDownloader fileId={row.recId} />
        </span>
      </CustomTooltip>
    )
  }),
  generateRow({
    label: 'receivedOn',
    name: 'creationDate',
    template: (row) => (
      <Typography variant="body2">{timestampToDate(row.creationDate)}</Typography>
    )
  })
];

export const AuditFilesTab = ({ auditFiles }) => (
  <Grid container>
    <CustomTableWithQuerySort
      headers={headers}
      rowKey="downloadLink"
      rows={auditFiles?.auditsFiles ?? []}
    />
  </Grid>
);