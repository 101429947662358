import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';
import * as PropTypes from 'prop-types';
import React from 'react';

export const localize = (key, options) => i18n.t(key, options) || key;

/**
 * Localized text with custom typography
 * @param children children elements, all pure strings will be translated, components will be ignored
 * @param text text to translate if no Typography needs to be rendered
 * @param props props for the typography elements (mainly variant and component)
 * @returns {*} Translated text with style
 */
const LocalizedText = ({ children, text, ...props }) => (
  children ? (
    React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return (
          <Typography {...props}>
            {i18n.t(child) ?? child}
          </Typography>
        );
      }
      return child;
    })
  )
    : i18n.t(text) ?? text
);

LocalizedText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  component: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string
};

LocalizedText.defaultProps = {
  children: null,
  component: 'p',
  text: null,
  variant: 'body1'
};

export default LocalizedText;