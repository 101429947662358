import { PageContainer } from 'components/PageContainer/PageContainer';
import { useFeatures } from 'hook/FeatureProvider';
import React from 'react';
import TabsMenu from 'components/TabsMenu/TabsMenu';
import { AccountRoutes } from 'routes/AccountRoutes';
import { GET_ACCOUNT_TABS} from 'utils/constants/tabs';

export const AccountPage = () => {

  const general = !window.location.href.includes('contract');
  const {cookiesPopinActivated} = useFeatures();
  
  return (
    <PageContainer>
      <TabsMenu tabs={GET_ACCOUNT_TABS(general, cookiesPopinActivated)}/>
      <AccountRoutes general={general}/>
    </PageContainer>
  );
};