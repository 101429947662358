import Grid from '@material-ui/core/Grid';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import LaboratoryCard from 'components/LaboratoryCard/LaboratoryCard';
import SkeletonLaboratoryCard from 'components/Skeletons/SkeletonLaboratoryCard/SkeletonLaboratoryCard';
import React from 'react';

export const TestLaboratoryPage = ({ laboratories }) => (
  <Grid
    alignItems="center"
    container
    direction="row"
    justify="space-evenly"
  >
    {
      laboratories ? (
        <>
          {laboratories.length
            ? laboratories.map((laboratory) => {
              const { name } = laboratory;
              return (
                <LaboratoryCard
                  key={name}
                  laboratory={laboratory}
                />
              );
            })
            : <LocalizedText>noLaboratoryFound</LocalizedText>}
        </>
      )
        : (
          <SkeletonLaboratoryCard />
        )
    }
  </Grid>
);