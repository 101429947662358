import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: (lowPaddingTop) => ({
    padding: theme.spacing(3),
    minHeight: 200,
    boxShadow: theme.palette.shadow.card,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(6)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5),
      paddingTop: lowPaddingTop ? theme.spacing(1) : theme.spacing(3)
    }
  })
}));

const PageContent = ({ children, lowPaddingTop }) => {
  const classes = useStyles(lowPaddingTop);
  return (
    <Container
      className={classes.root}
      fixed
    >
      {children}
    </Container>
  );
};

PageContent.propTypes = {
  lowPaddingTop: PropTypes.bool
};

PageContent.defaultProps = {
  lowPaddingTop: false
};

export default PageContent;