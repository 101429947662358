import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    minWidth: 250,
    margin: theme.spacing(1),
    padding: 0,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1),
      minWidth: 180,
      height: 40
    }
  },
  skeletonText: {
    marginLeft: theme.spacing(1)
  }
}));


export default function SkeletonRangeCard() {
  const classes = useStyles();
  return (
    <Skeleton className={classes.root} variant="rect">
      <Skeleton height={40} variant="circle" width={40} />
      <Skeleton className={classes.skeletonText} height={30} variant="rect" width={150} />
    </Skeleton>
  );
}