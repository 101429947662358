import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useFeatures } from 'hook/FeatureProvider';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GENERATE_ROUTE, GENERATE_TAB_VALUE } from 'utils/constants/routes';
import { GET_FOOTER_TABS } from 'utils/constants/tabs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.shadow.footer
  },
  tab: {
    padding: '3px 10px 6px',
    fontSize: '110%',
    color: theme.palette.primary.main
  },
  tabActive: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 'bold'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { contract } = useParams();
  const footerTabs = GET_FOOTER_TABS(useFeatures());

  return (
    <BottomNavigation
      className={classes.root}
      showLabels
      value={0}
    >
      {footerTabs.map((currentTab) => (
        <BottomNavigationAction
          classes={{
            root: classes.tab,
            selected: classes.tabActive
          }}
          icon={<CustomIcon icon={currentTab.ICON} size="lg" />}
          key={currentTab.LABEL}
          label={<LocalizedText className="bold" text={(currentTab.LABEL)} />}
          onClick={() => history.push(GENERATE_ROUTE(currentTab.ROUTE, { contract }))}
          value={GENERATE_TAB_VALUE(location, currentTab.ROUTE)}
        />
      ))}
    </BottomNavigation>
  );
};

export default Footer;