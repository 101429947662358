/* eslint-disable no-param-reassign,react/no-unused-prop-types */
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: 0,
    width: 0,
    borderLeft: `${props.width / 2}px solid ${props.backgroundColor}`,
    borderRight: `${props.width / 2}px solid transparent`,
    borderBottom: `${props.height / 2}px solid transparent`,
    borderTop: `${props.height / 2}px solid ${props.isDiagonal ? props.backgroundColor : 'transparent'}`,
    // borderLeftColor: props.backgroundColor,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      display: props.notOnMobile ? 'none' : 'block'
    }
  })
}));

export const Triangle = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root} />
  );
};

Triangle.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  isDiagonal: PropTypes.bool,
  notOnMobile: PropTypes.bool,
  width: PropTypes.number.isRequired
};

Triangle.defaultProps = {
  isDiagonal: false,
  notOnMobile: false
};