/* eslint-disable no-param-reassign */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow } from 'commons/DataRow/DataRow';
import AuditStatusDisplay from 'commons/StatusDisplay/AuditStatusDisplay';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  separatorRight: {
    borderRight: `2px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      borderRight: 'none'
    }
  },
  container: {
    padding: theme.spacing(1)
  }
}
));

export const AuditGeneralPage = ({ general }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid className={`${classes.separatorRight} ${classes.container}`} container direction="column" item sm={6} xs={12}>
        <DataRow label="concernedEntity" value={general?.concernedEntity} />
        <DataRow isTranslate label="isIncludingSampling" value={general?.hasSampling ? 'yes' : 'no'} />
        <DataRow isTranslate label="hasFeasibility" value={general?.hasFeasibility ? 'yes' : 'no'} />
        {
          general?.feasibilityResult
          && <DataRow isTranslate label="feasibilityResult" value={general?.feasibilityResult} />
        }
        <br />
        <br />
        <DataRow isTranslate label="referenceYear" value={general?.year} />
      </Grid>
      <Grid className={classes.container} item sm={6} xs={12}>
        <DataRow isTranslate label="auditType" value={general?.auditType} />
        <DataRow label="auditStatus" value={<AuditStatusDisplay completion={general?.completion} status={general?.status} />} />
        <DataRow label="auditNumber" value={general?.auditNumber} />
        <DataRow label="resultSendDate" value={timestampToDate(general?.resultSendDate)} />
      </Grid>
    </Grid>
  );
};