import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles(() => ({
  cell: {
    padding: '3px 0px 3px 0px',
    '&:last-child': {
      paddingRight: 0
    }
  }
}));

export default function SkeletonTable({ rows, columns }) {
  const classes = useStyles();
  return (
    <TableBody>
      {[...Array(rows).keys()].map((keyRow) => (
        <tr key={keyRow}>
          {[...Array(columns).keys()].map((keyCol) => (
            <td className={classes.cell} key={keyCol}>
              <Skeleton height={28} variant="rect" width="100%" />
            </td>
          ))}
        </tr>
      ))}
    </TableBody>
  );
}