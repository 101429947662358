/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@material-ui/core/Grid';
import { BadgePulse } from 'commons/BadgePulse/BadgePulse';
import CustomStepper from 'commons/CustomStepper/CustomStepper';
import { AutocompleteFilter } from 'commons/Filter/AutocompleteFilter/AutocompleteFilter';
import { CheckBoxFilter } from 'commons/Filter/CheckBoxFilter/CheckBoxFilter';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import TestStatusDisplay from 'commons/StatusDisplay/TestStatusDisplay';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { BasicPageContainer } from 'components/PageContainer/BasicPageContainer';
import PageContent from 'components/PageContent/PageContent';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import useFetch from 'hook/UseFetch';
import useFilters from 'hook/UseFilters';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProductTestService } from 'services/ProductTestService';
import { ParamTypes } from 'utils/constants/param.type';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';
import { StakeHolders } from 'utils/constants/stakeholders';
import { generateRow } from 'utils/helpers/RowHelper';
import { groupByCount, valueContained, valueContainedWithMatching } from 'utils/processing/filters';
import { parseQueryStringValue } from 'utils/processing/url';

const headers = [
  generateRow({
    name: 'selectionNumber',
    width: '20%',
    isOnMobile: false
  }),
  generateRow({
    name: 'testNumber',
    width: '20%',
    isOnMobile: false
  }),
  generateRow({
    name: 'machineName',
    width: '30%',
    mobileWidth: '33%'
  }),
  generateRow({
    name: 'status',
    width: '15%',
    mobileWidth: '33%',
    template: (row) => (
      <TestStatusDisplay isSuccess={row.testOK} status={row.status} />
    )
  }),
  generateRow({
    name: 'awaitingAction',
    label: 'awaitingActionFrom',
    width: '15%',
    mobileWidth: '33%',
    template: (row) => (
      <ConditionalWrapper
        condition={row.awaitingAction === StakeHolders.CUSTOMER}
        wrapper={(childrenNodes) => (
          <BadgePulse>
            {childrenNodes}
          </BadgePulse>
        )}
      >
        <LocalizedText variant="body2">{row.awaitingAction}</LocalizedText>
      </ConditionalWrapper>
    )
  })
];

export const TestListPage = () => {
  const [filters, updateFilters] = useFilters();
  const { contract: contractId } = useRealParams();
  const year = parseQueryStringValue('year', window.location.search, ParamTypes.NUMBER);
  const history = useHistory();
  const { response: rows, isLoading } = useFetch(() => ProductTestService.getTests(contractId), []);
  const { response: steps } = useFetch(() => ProductTestService.getStepperStatuses(), []);

  const selectProductTest = (productTestId) => {
    history.push(GENERATE_ROUTE(ROUTES.TEST.BASE, {
      contract: contractId,
      test: productTestId
    }));
  };

  return (
    <BasicPageContainer>
      <CustomStepper
        filter={(value) => (row) => valueContained(value, row.status)}
        isDoubleBound
        isInteractive
        statusesNumber={rows.map((row) => (Number.isNaN(year) ? row.status
          : (row.year === year ? row.status : ''))).reduce(groupByCount, {})}
        steps={steps}
        updateFilter={updateFilters}
        year={year}
      />
      <PageContent>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-evenly"
        >
          <AutocompleteFilter
            filter={(value) => (row) => String(row.year) === value}
            label="year"
            options={rows.map((r) => String(r.year))}
            updateFilter={updateFilters}
            valueName="year"
          />
          <AutocompleteFilter
            filter={(value) => (row) => valueContained(value, row.status)}
            isDoubleBound
            isMultiple
            isTranslatingOptions
            label="status"
            optionService={ProductTestService.getTestStatuses}
            updateFilter={updateFilters}
            valueName="status"
          />
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-evenly"
        >
          <AutocompleteFilter
            filter={(value) => (row) => valueContainedWithMatching(value, row.testNumber)}
            isFree
            isMultiple
            label="testNumber"
            options={rows.map((r) => r.testNumber)}
            updateFilter={updateFilters}
            valueName="testNumber"
          />
          <AutocompleteFilter
            filter={(value) => (row) => valueContainedWithMatching(value, row.machineName)}
            isFree
            isMultiple
            label="machineName"
            options={rows.map((r) => r.machineName)}
            updateFilter={updateFilters}
            valueName="machineName"
          />
          <CheckBoxFilter
            filter={() => (row) => row.awaitingAction === StakeHolders.CUSTOMER}
            label="awaitingAction"
            updateFilter={updateFilters}
            valueName="awaitingAction"
          />
        </Grid>
        <br />
        <CustomTableWithQuerySort
          filters={filters}
          headers={headers}
          isLoading={isLoading}
          onRowCLick={(row) => selectProductTest(row.testId)}
          rowKey="testNumber"
          rows={rows}
        />
      </PageContent>
    </BasicPageContainer>
  );
};