import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import PageContent from 'components/PageContent/PageContent';
import React from 'react';

export const PageContainer = ({ children }) => (
  <>
    <BreadCrumb />
    <PageContent>
      {children}
    </PageContent>
  </>
);