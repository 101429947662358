/* eslint-disable react-hooks/exhaustive-deps */
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import React, {useEffect, useState} from 'react';
import {Redirect, Route, useParams} from 'react-router-dom';
import {ContractService} from 'services/ContractService';
import {ROUTES} from 'utils/constants/routes';

/**
 * Route which requires a contract to be defined,
 * enhanced route which will check if the contract provided is valid, if not it will redirect to contract choice
 * @param props props for the route which will be forwarded
 * @returns enhanced route
 */
const ContractRoute = (props) => {
  const {contract: contractId} = useParams();
  const [isValid, setValid] = useState(true);
  const enqueueSnackbar = useLocalizedSnackBar();

  useEffect(() => {
    ContractService.getContractNumberAllowed(contractId)
      .then(({ isAuthorized }) => {
        if (!isAuthorized) {
          enqueueSnackbar('notAuthorizedContract', { variant: 'error' });
        }
        setValid(isAuthorized);
      })
      .catch(() => {
        enqueueSnackbar('backendError', { variant: 'error' });
        setValid(false);
      });
  }, []);

  if (isValid) {
    return <Route {...props} />;
  }
  return <Redirect to={ROUTES.CHOICE} />;
};
export default ContractRoute;