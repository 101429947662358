import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import {NewsService} from 'services/NewsService';
import Typography from '@material-ui/core/Typography';
import {CustomButton} from 'commons/Button/CustomButton';
import {faNewspaper, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {RichTextDisplay} from 'components/Editor/RichTextDisplay';
import Grid from '@material-ui/core/Grid';
import {TruncatedMultiline} from 'utils/helpers/TruncatedMultiline';
import IconButton from '@material-ui/core/IconButton';
import {useHistory, useLocation} from 'react-router-dom';
import LocalizedText from 'commons/LocalizedText/LocalizedText';


export const useStyles = makeStyles((theme) => ({
  card: {
    alignContent: 'center',
    marginTop: "10px",
    marginLeft: "11%",
    marginRight: "11%",
    backgroundImage: `linear-gradient(175deg,${theme.palette.primary.main},${theme.palette.secondary.main})`,
    borderRadius: '15px',
    padding: '10px'
  },
  newsIcon: {
    color: 'white',
    transform: "scale(1.5, 1)",
    paddingLeft: '15px',
    paddingRight: '5px'
  },
  titleSection: {
    paddingTop: '5px',
    paddingBottom: '10px'
  },
  title: {
    display: 'inline',
    color: 'white'
  },
  content: {
    paddingBottom: '5px',
    color: "white"
  },
  readButton: {
    paddingLeft: '50px',
    paddingRight: '50px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  closeButton: {
    padding: 0,
    color: theme.palette.primary.main
  },
  closeIcon: {
    color: 'white'
  },
  label: {
    display: 'inline',
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    color: 'white',
    padding: '5px',
    marginLeft: '10px'
  },
  caps: {
    textTransform: 'uppercase'
  }
}));

export const NewsPanel = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [isOpen, setOpen] = useState(true);
  const [lastNews, setLastNews] = useState(null);
  useEffect(() => {
    NewsService.getLastUnreadNews()
      .then(resp => setLastNews(resp))
  }, []);

  const handleRead = () => {
    const params = new URLSearchParams(location.search)
    params.set("newsMenu", "open")
    history.replace({ pathname: location.pathname, search: params.toString() });
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {isOpen && lastNews && (
        <div className={classes.card}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-around"
            spacing={3}
          >
            <Grid item>
              <CustomIcon className={classes.newsIcon} icon={faNewspaper} size="2x"/>
            </Grid>
            <Grid item xs zeroMinWidth>
              <div className={classes.titleSection}>
                <Typography className={classes.title} variant={'h5'}>{lastNews.title}</Typography>
                <LocalizedText className={classes.label}>lastNews</LocalizedText>
              </div>

              <Typography className={classes.content}>
                <TruncatedMultiline maxLines={2}>
                  <RichTextDisplay classNames={classes} simplified={true} value={lastNews.content}/>
                </TruncatedMultiline>
              </Typography>
            </Grid>
            <Grid item>
              <CustomButton className={classes.readButton} onClick={handleRead}>
                <LocalizedText className={classes.caps}>read</LocalizedText>
              </CustomButton>
            </Grid>
            <Grid item style={{ alignSelf: "flex-start" }}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CustomIcon className={classes.closeIcon} icon={faTimes}/>
              </IconButton>
            </Grid>
          </Grid>
        </div>)
      }

    </>
  );
}

NewsPanel.propTypes = {}