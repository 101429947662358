import { useTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { Triangle } from 'commons/Triangle/Triangle';
import useMobile from 'hook/UseMobile';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RangesStatuses } from 'utils/constants/range.status';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minWidth: 250,
    margin: theme.spacing(1),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      alignItems: 'center',
      margin: 'auto',
      marginBottom: theme.spacing(1),
      minWidth: 180
    }
  },
  hoverable: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: theme.palette.shadow.hover
    }
  },
  textDisabled: {
    color: theme.palette.text.disabled
  }
}));

const RangeCard = ({ range, totalRanges }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { status, count } = range;
  const isMobile = useMobile();
  const history = useHistory();
  const { contract } = useParams();
  const isActive = count > 0;

  const triangleColor = () => {
    switch (status) {
    case RangesStatuses.CERTIFIED_TEST:
      return theme.palette.success.main;
    case RangesStatuses.IN_PROGRESS:
      return theme.palette.warning.main;
    case RangesStatuses.SUSPENDED:
      return theme.palette.error.main;
    case RangesStatuses.EXPELLED:
      return theme.palette.grey.A100;
    default:
      return '';
    }
  };

  function goToDiplomas() {
    if (isActive) {
      history.push(GENERATE_ROUTE(`${ROUTES.DIPLOMAS}?status=${range.status}`, { contract }));
    }
  }

  return (
    <Card className={`${classes.root} ${isActive && classes.hoverable}`} onClick={goToDiplomas} variant="outlined">
      <Triangle backgroundColor={triangleColor()} height={isMobile ? 40 : 60} isDiagonal width={isMobile ? 20 : 40} />
      <LocalizedText className={isActive ? '' : classes.textDisabled}>
        {status}
      </LocalizedText>
      <Typography className={isActive ? '' : classes.textDisabled}>
        {`: ${count}/${totalRanges}`}
      </Typography>
    </Card>
  );
};

export default RangeCard;