import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 525,
    margin: 10,
    padding: 0
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 2
    }
  },
  subCategoryHeader: {
    textAlign: 'center'
  },
  subCategory: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  },
  divider: {
    margin: 4
  },
  doubleColumn: {
    flexBasis: '50%'
  },
  column: {
    flexBasis: '25%'
  }
}));

const MultiColumnsPropertyName = ({ firstClassValue, secondClassValue, propertyName }) => (
  <div className={firstClassValue}>
    <LocalizedText className={secondClassValue}>
      {propertyName}
    </LocalizedText>
  </div>
);

const MultiColumnsValue = ({ classValue, propertyValue }) => (
  <div className={classValue}>
    <Typography>
      {propertyValue ? timestampToDate(propertyValue) : '-'}
    </Typography>
  </div>
);

const AuditLaboratoryCard = ({ laboratory }) => {
  const classes = useStyles();
  const {
    laboratoryName, feasibilityProjectedDate, feasibilityEffectiveDate, scheduledDate, startDate,
    deadlineCaPlanDate, deadlineCaReportDate
  } = laboratory;
  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.cardHeader}>
        <Typography component="h5" variant="h6">
          {laboratoryName}
        </Typography>
      </div>
      <CardContent className={classes.cardContent}>
        <div className={classes.subCategory}>
          <MultiColumnsPropertyName firstClassValue={classes.doubleColumn} propertyName />
          <MultiColumnsPropertyName
            firstClassValue={classes.column}
            propertyName="scheduledProperty"
            secondClassValue={classes.bold}
          />
          <MultiColumnsPropertyName
            firstClassValue={classes.column}
            propertyName="realProperty"
            secondClassValue={classes.bold}
          />
        </div>
        <div className={classes.subCategory}>
          <MultiColumnsPropertyName
            firstClassValue={classes.doubleColumn}
            propertyName="feasibilityProperty"
            secondClassValue={classes.bold}
          />
          <MultiColumnsValue classValue={classes.column} propertyValue={feasibilityProjectedDate} />
          <MultiColumnsValue classValue={classes.column} propertyValue={feasibilityEffectiveDate} />
        </div>
        <div className={classes.subCategory}>
          <MultiColumnsPropertyName
            firstClassValue={classes.doubleColumn}
            propertyName="auditCheckingSampling"
            secondClassValue={classes.bold}
          />
          <MultiColumnsValue classValue={classes.column} propertyValue={scheduledDate} />
          <MultiColumnsValue classValue={classes.column} propertyValue={startDate} />
        </div>
        <Divider className={classes.divider} />
        <Divider className={classes.divider} />
        <LocalizedText className={classes.subCategoryHeader} component="h6" variant="h6">
          CorrectiveActions
        </LocalizedText>
        <div className={classes.subCategory}>
          <MultiColumnsPropertyName
            firstClassValue={classes.doubleColumn}
            propertyName="reportDeadlineProperty"
            secondClassValue={classes.bold}
          />
          <MultiColumnsPropertyName firstClassValue={classes.column} />
          <MultiColumnsPropertyName
            firstClassValue={classes.column}
            propertyName="planDeadlineProperty"
            secondClassValue={classes.bold}
          />
        </div>
        <div className={classes.subCategory}>
          <MultiColumnsValue classValue={classes.doubleColumn} propertyValue={deadlineCaPlanDate} />
          <MultiColumnsPropertyName firstClassValue={classes.column} />
          <MultiColumnsValue classValue={classes.column} propertyValue={deadlineCaReportDate} />
        </div>
      </CardContent>
    </Card>
  );
};

export default AuditLaboratoryCard;