import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ColoredText } from 'commons/ColoredText/ColoredText';
import CustomIcon from 'commons/Icon/CustomIcon';
import FileDownloader from 'commons/Icon/FileDownloader';
import { CustomLinkRouter } from 'commons/Link/CustomLink';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import React from 'react';
import { BillAction } from 'utils/constants/bill.action';
import { DataStatus } from 'utils/constants/data.status';
import { ROUTES } from 'utils/constants/routes';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  billingButtonWrapper: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1)
  },
  inlineCompletion: {
    display: 'flex',
    alignItems: 'center'
  },
  downloadButton: {
    color: theme.palette.primary.main
  },
  exclamation: {
    marginLeft: theme.spacing(1)
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  }
}));

const generateBillColoredText = (action, value, rightAlign) => {
  let status;
  switch (action) {
  case BillAction.BILLS_TO_PAY_FOR_A_LONG_TIME:
    status = DataStatus.ERROR;
    break;
  case BillAction.BILLS_TO_PAY_FOR_A_SHORT_TIME:
    status = DataStatus.WARNING;
    break;
  default:
    break;
  }
  return <ColoredText rightAlign={rightAlign} status={status} value={value} />;
};

const BillNumberRow = ({ row }) => {
  const classes = useStyles();
  return (
    <div className={classes.inlineCompletion}>
      {generateBillColoredText(row.action, row.billNumber)}
      <CustomTooltip title="downloadBillingTooltip">
        <span className={classes.billingButtonWrapper}>
          <FileDownloader fileId={row.fileId}
          />
        </span>
      </CustomTooltip>
    </div>
  );
};

const BillExclamation = ({ action }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.exclamation} ${action === BillAction.BILLS_TO_PAY_FOR_A_LONG_TIME
        ? classes.error
        : classes.warning}`}
    >
      <CustomIcon
        icon={faExclamationTriangle}
        tooltip={action === BillAction.BILLS_TO_PAY_FOR_A_LONG_TIME
          ? 'waitingForPaymentMoreThan30days'
          : 'waitingForPaymentLessThan30days'}
      />
    </div>
  );
};

export const billNumberRow = generateRow({
  name: 'billNumber',
  width: '15%',
  mobileWidth: '35%',
  template: (row) => (
    <BillNumberRow row={row} />
  )
});

export const billTypeRow = generateRow({
  name: 'billType',
  width: '10%',
  isOnMobile: false,
  template: (row) => (
    generateBillColoredText(row.action, row.billType)
  )
});

export const issueDateRow = generateRow({
  name: 'issueDate',
  width: '15%',
  mobileWidth: '35%',
  template: (row) => (
    generateBillColoredText(row.action, timestampToDate(row.issueDate))
  )
});

export const paymentReceptionDateRow = generateRow({
  name: 'paymentReceptionDate',
  label: 'paymentDate',
  width: '15%',
  isOnMobile: false,
  template: (row) => (
    row.paymentReceptionDate
      ? generateBillColoredText(row.action, timestampToDate(row.paymentReceptionDate))
      : (
        row.action ? <BillExclamation action={row.action} /> : '-'
      )
  )
});

export const prestationNumberRow = generateRow({
  name: 'prestationNumber',
  width: '15%',
  isSortable: false,
  isOnMobile: false,
  template: (row) => {
    if (row.prestationNumber && row.feeType === 'Testing') {
      return (
        <CustomLinkRouter testId={row.prestationNumber} to={ROUTES.TEST.BASE}>
          <Typography>
            {row.prestationNumber}
          </Typography>
        </CustomLinkRouter>
      );
    }
    return generateBillColoredText(row.action, row.prestationNumber);
  }
});

export const feeTypeRow = generateRow({
  name: 'feeType',
  width: '15%',
  isOnMobile: false,
  template: (row) => (
    generateBillColoredText(row.action, row.feeType)
  )
});

export const totalAmountRow = generateRow({
  label: 'amount',
  width: '15%',
  mobileWidth: '30%',
  name: 'amountExcludingTaxes',
  template: (row) => (
    generateBillColoredText(row.action, `${row.totalAmount?.toFixed(2)} €`, true)
  )
});