import useFetch from 'hook/UseFetch';
import React, { createContext, useContext } from 'react';
import { FeatureService } from 'services/FeatureService';

const FeatureContext = createContext({});
/**
 * @typedef {Object} Features
 * @property {boolean} auditsActivated
 * @property {boolean} exportsActivated
 * @property {boolean} prodbimActivated
 */

// noinspection JSValidateTypes
/**
 * Return an object containing feature details
 * @returns {Features}
 */
export const useFeatures = () => useContext(FeatureContext);

export const FeatureProvider = ({ children }) => {
  const features = useFetch(() => FeatureService.getFeatures(), {}).response;
  return (
    <FeatureContext.Provider
      value={features}
    >
      {children}
    </FeatureContext.Provider>
  );
};