// Redux store
import { MethodEnum } from 'utils/constants/http.enum';
import { AUTH_TOKEN_KEY } from "hook/AuthProvider";

export const getDefaultHeaders = () => ({
  'Content-Type': 'application/json'
});

export const httpSettings = async (settings = { method: MethodEnum.GET }, noContentType = false) => {
  const headers = settings.headers || getDefaultHeaders();

  if (noContentType) {
    delete headers["Content-Type"];
  }

  const init = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
    case 'application/json':
      if (!(init.body instanceof FormData)) {
        init.body = JSON.stringify(init.body);
      }
      break;
    case 'application/x-www-form-urlencoded': {
      const formDataEntries = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
      const formDataArray = [];
      formDataEntries.forEach(([key, value]) => formDataArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`));
      init.body = formDataArray.join('&');
      break;
    }
    default:
      break;
    }
  }

  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);

  headers.Accept = 'application/json, application/octet-stream, text/plain, */*';
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  init.headers = headers;
  return init;
};