/* eslint-disable max-len */
import { API_URL } from 'utils/constants/app';
import { RequestHelper } from 'utils/helpers/RequestHelper';

const featureServiceUrl = `${API_URL}/feature`;

const getFeatures = (dbvalue = false) => RequestHelper.GET(`${featureServiceUrl}/features?dbvalue=${dbvalue}`);

const activateFeature = (feature, value) => RequestHelper.POST(`${featureServiceUrl}/${feature}/${value}`, {});

export const FeatureService = {
  getFeatures,
  activateFeature
};