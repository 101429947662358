import * as PropTypes from 'prop-types';
import React from 'react';

import TextField from '@material-ui/core/TextField';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FlagIcon } from 'pages/administration/FlagIcon';
import { GridLayout } from 'utils/helpers/GridLayout';
import { RichTextEditor } from 'components/Editor/RichTextEditor';
import { useStyles } from 'components/News/NewsForm';


export const TranslationSection = ({selectedLanguage, setSelectedLanguage, translation, updateTranslation}) => {
  const styles = useStyles();

  const countries = [
    {languageCode: "en", countryCode: "gb"},
    {languageCode: "fr", countryCode: "fr"}
  ];

  return (
    <>
      <LocalizedText className={styles.title}>titleAndMessage</LocalizedText>

      <GridLayout
        direction="column"
        spacing={2}
      >

        <RadioGroup
          name={"language"}
          onChange={(event) => setSelectedLanguage(event.target.value)}
        >
          <GridLayout
            alignItems={'center'}
            direction={'row'}
            justifyContent={'flex-start'}
            spacing={1}
          >
            {countries.map((country) => (
              <FormControlLabel
                checked={selectedLanguage === country.languageCode}
                control={<Radio/>}
                label={<FlagIcon country={country.countryCode} scale={'1'}/>}
                value={country.languageCode}
              />
            ))}
          </GridLayout>
        </RadioGroup>

        <GridLayout
          alignItems={'flex-start'}
          direction={'column'}
          justifyContent={'flex-start'}
          spacing={2}
        >
          <TextField
            id="title-field"
            InputLabelProps={{
              shrink: true
            }}
            label={<LocalizedText>title</LocalizedText>}
            onChange={(event) => updateTranslation({title: event.target.value})}
            required={true}
            value={translation?.title || ""}
            variant={'outlined'}
          />

          <RichTextEditor
            onChange={(value) => updateTranslation({content: value})}
            value={translation?.content || ""}
          />

        </GridLayout>
      </GridLayout>
    </>
  )
}

TranslationSection.propTypes = {
  news: PropTypes.shape({}).isRequired,
  saveTranslation: PropTypes.func.isRequired,
  setTranslation: PropTypes.func.isRequired,
  translation: PropTypes.shape({}).isRequired
}