/* eslint-disable no-param-reassign */
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import useRealParams from 'hook/UseRealParams';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { GENERATE_ROUTE } from 'utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  base: {
    '&:hover': {
      color: theme.palette.primary.hover
    }
  }
}));

/**
 * Material Link with custom style (no routing)
 * @param children children elements
 * @param active if link is active
 * @param className specific classes which will override base classes
 * @param props other props passed to the link
 * @returns Enhanced material link
 */
export const CustomLink = ({
  children, className, ...props
}) => {
  const classes = useStyles();
  return (
    <Link className={className ?? classes.base} {...props}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
};

CustomLink.defaultProps = {
  className: ''
};

/**
 * Router Link wrapped in material link with custom style (with routing)
 * @param children children elements
 * @param active if link is active
 * @param to destination (overriden to keep path params)
 * @param className specific classes which will be merged with base classes
 * @param contractId optional contract Id to set in the path
 * @param testId optional test Id to set in the path
 * @param props other props for the link
 * @returns Enhanced Router link
 */
export const CustomLinkRouter = ({
  children, to, className, contractId, testId, ...props
}) => {
  const params = useRealParams();
  const contract = contractId || params.contract;
  const test = testId || params.test;
  const audit = params.audit;
  const news = params.news;
  props.component = RouterLink;
  props.to = GENERATE_ROUTE(to, { contract, test, audit, news });
  return CustomLink({
    className,
    children,
    ...props
  });
};

CustomLinkRouter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  contractId: PropTypes.string,
  testId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.string.isRequired
};

CustomLinkRouter.defaultProps = {
  className: '',
  contractId: null,
  testId: null
};