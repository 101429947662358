// @ts-nocheck
import {Container, Grid} from '@material-ui/core';
/* eslint-disable react-hooks/exhaustive-deps */
import {makeStyles} from '@material-ui/core/styles';
import {CustomButton} from 'commons/Button/CustomButton';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import BasicHeader from 'components/BasicHeader/BasicHeader';
import useFileDownload from 'hook/UseFileDownload';
import usePageTracking from 'hook/usePageTracking';
import * as qs from 'query-string';
import React, {useEffect} from 'react';
import {MOBILE_BREAKPOINT} from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12)
  },
  margin: {
    padding: 20,
    margin: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      margin: theme.spacing(1)
    }
  }
}));


export const DownloadFilePage = () => {
  const classes = useStyles();
  usePageTracking();
  const downloadFile = useFileDownload();

  const fileIdCrypted = qs.parse(window.location.search).fileId;

  useEffect(() => downloadFile(fileIdCrypted), []);

  return (
    <>
      <BasicHeader titleMobile="downloadFileTitle" titlePC="downloadFileTitle"/>
      <Container
        className={classes.root}
        fixed
      >
        <Grid
          alignItems={'center'}
          container
          direction="column"
          justifyContent={'center'}
        >
          <Grid item>
            <LocalizedText component="h5">
              fileDownloadInformation
            </LocalizedText>
          </Grid>

          <Grid item>
            <CustomButton
              className={`${classes.margin}`}
              onClick={() => downloadFile(fileIdCrypted)}
              tooltip="downloadFileTooltip"
            >
              downloadFile
            </CustomButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};