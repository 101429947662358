import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { CustomLink } from 'commons/Link/CustomLink';
import BurgerMenu from 'components/Header/BurgerMenu';
import HeaderLeft from 'components/Header/HeaderLeft';
import HeaderMenu from 'components/Header/HeaderMenu';
import HeaderWarning from 'components/Header/HeaderWarning';
import { useContractInfo } from 'hook/ContractInfoProvider';
import useMobile from 'hook/UseMobile';
import React, { useState } from 'react';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';
import { NewsMenuButton } from 'components/News/NewsMenuButton';


const useStyles = makeStyles((theme) => ({
  headerRoot: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: theme.palette.shadow.hover
  },
  linkEnd: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  imgEnd: {
    height: 60,
    width: 90
  }
}));

const Header = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const [displayBurger, setDisplayBurger] = useState(false);
  const contractInfo = useContractInfo();

  return (
    <>
      <HeaderWarning />
      <div className={classes.headerRoot}>
        <Drawer onClose={() => setDisplayBurger(false)} open={displayBurger}>
          <BurgerMenu setDisplayBurger={setDisplayBurger} />
        </Drawer>
        <HeaderLeft contractInfo={contractInfo} onBurgerClick={() => setDisplayBurger(true)} />
        {
          !isMobile && (
            <>
              <HeaderMenu />
              <NewsMenuButton/>
            </>
          )
        }
        <CustomLink
          className={classes.linkEnd}
          href="https://www.eurovent-certification.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="logo" className={classes.imgEnd} src={getAssetUrl("/assets/images/ECC.png")} />
        </CustomLink>
      </div>
    </>
  );
};

export default Header;