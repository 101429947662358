/* eslint-disable max-len */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AutocompleteFilter } from 'commons/Filter/AutocompleteFilter/AutocompleteFilter';
import FileDownloader from 'commons/Icon/FileDownloader';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { useContractInfo } from 'hook/ContractInfoProvider';
import useFetch from 'hook/UseFetch';
import useFilters from 'hook/UseFilters';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { DiplomaService } from 'services/DiplomaService';
import { CertificationStatus } from 'utils/constants/certification.status';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';
import { valueContainedWithMatching } from 'utils/processing/filters';

const useStyles = makeStyles((theme) => ({
  diplomaTextContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: '120%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      fontSize: '110%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '130%'
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(1)
    }
  }
}));


const headers = [
  generateRow({
    name: 'rangeName',
    width: '50%',
    mobileWidth: '50%',
    label: 'range'
  }),
  generateRow({
    name: 'rangeNumber',
    width: '50%',
    mobileWidth: '50%',
    label: 'rangeNumber'
  })
];

export const DiplomasCertifyAll = () => {
  const classes = useStyles();
  const {contract: contractId} = useRealParams();
  const contractInfo = useContractInfo();
  const [filters, updateFilters] = useFilters();
  const {response, isLoading} = useFetch(() => DiplomaService.getDiplomasCertifyAll(contractId), {});
  const rows = response.ranges ?? [];
  const { diploma } = response;

  return (
    <>
      <Typography variant="h5">
        {`${contractInfo.programCode} - ${localize('certificationAll')}`}
      </Typography>
      <Grid
        alignItems="center"
        container
        direction="row"
        justify="space-evenly"
      >
        {diploma
          ? (
            <Typography className={classes.diplomaTextContainer}>
              {localize('diplomaProperty')}
              &nbsp;
              <span className={classes.bold}>{diploma.diplomaNumber}</span>
              <span className={classes.marginLeft}>{localize(diploma.status)}</span>
              {diploma.status === CertificationStatus.CERTIFIED
              && (
                <>
                  {` ${localize('until')} ${timestampToDate(diploma.validityDate)}`}
                  <CustomTooltip title="downloadDiplomaTooltip">
                    <span className={classes.marginLeft}>
                      <FileDownloader fileId={diploma?.fileId} />
                    </span>
                  </CustomTooltip>
                </>
              )}
            </Typography>
          )
          : (
            <LocalizedText className={classes.diplomaTextContainer}>
              noValidDiplomaFoundForContract
            </LocalizedText>
          )}
        <AutocompleteFilter
          filter={(value) => (row) => valueContainedWithMatching(value, row.rangeName)}
          isFree
          isMultiple
          label="range"
          options={rows.map((r) => r.rangeName)}
          updateFilter={updateFilters}
          valueName="range"
        />
      </Grid>
      <br />
      <CustomTableWithQuerySort
        filters={filters}
        headers={headers}
        isLoading={isLoading}
        rowKey="rangeNumber"
        rows={rows}
      />
    </>
  );
};