import React, {useRef} from 'react';
import ReactQuill from 'react-quill';
import * as PropTypes from 'prop-types'; // ES6
import 'react-quill/dist/quill.snow.css';
import {CustomEditorToolbar, toolbarModule} from 'components/Editor/CustomEditorToolbar';
import Quill from 'quill/quill';

export const RichTextEditor = ({onBlur, onChange, value}) => {
  const QuillRef = useRef(null);

  const onEditorChange = (content, delta, source, editor) => {
    if (source === Quill.sources.USER) {
      const text = editor.getText();
      if (text.trim() === '') {
        content = ''
      }
      onChange(content)
    }
  }

  return (
    <>
      <CustomEditorToolbar/>
      <ReactQuill
        modules={toolbarModule}
        onBlur={onBlur}
        onChange={onEditorChange}
        ref={QuillRef}
        value={value}
      />
    </>
  )
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}