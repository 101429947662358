import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styled, { css } from 'styled-components';

const purpleMain = '#692984';
const purpleLight = '#D8C0E3';
const purpleLighter = '#eddcf5';

export const useCustomStepperStyles = makeStyles((theme) => ({
  helperText: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  base: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    maxWidth: 1500,
    paddingTop: theme.spacing(2),
    margin: 'auto'
  },
  completedLabel: {
    '& .MuiTypography-body1': {
      minWidth: 90,
      fontWeight: 600,
      marginTop: '7px'
    }
  },
  basicLabel: {
    '& .MuiTypography-body1': {
      minWidth: 90,
      color: theme.palette.text.primary,
      marginTop: '7px'
    }
  },
  statusesNumberIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main
  }
}));

const StyledStepIcon = styled.div`
  height: 22px;
  width: 22px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: ${purpleLight};
  border: 2px solid ${purpleMain};
  transition: all 0.3s;
  z-index: 5;

  ${(props) => props.disabled && css`
    background-color: ${purpleLighter};
    border: 2px solid ${purpleLight};
  `}

  ${(props) => props.completed && css`
    background-color: ${purpleMain};
    border: 2px solid ${purpleLight};
  `}
`;

const StyledStepIconCompleted = styled.span`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 50%;
  height: 30px;
  width: 30px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid ${purpleMain};
  background: transparent;
  transform: translate(-50%, 0);
`;

export const StepIcon = (props) => {
  const { completed, error } = props;

  return (
    <StyledStepIcon
      className="stepIcon"
      completed={completed}
      disabled={error}
    >
      {completed && <StyledStepIconCompleted />}
    </StyledStepIcon>
  );
};

const StyledStepConnector = styled(({
  withArrow, isSubStep, isFirstSubStep, multiSteps, icon, stepCollapsed, ...props
}) => <StepConnector {...props} />)`
  span {
    border-color: ${purpleLight};
    border-top-width: 3px;
  }

  ${(props) => props.alternativeLabel && css`
    top: 24px;
    left: calc(50% + 20px);
    right: calc(-50% + 20px);
    z-index: 0;
  `}

  ${(props) => props.isSubStep && css`
    position: absolute;
    top: 8px;
    left: calc(50% + 20px);
    right: calc(-50% + 20px);
  `}

  ${(props) => props.isFirstSubStep && css`
    position: absolute;
    top: 8px;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
  `}

  ${(props) => props.withArrow && css`
    span {
      position: relative;
      
      &::after {
        content: "▶";
        position: absolute;
        right: -8px;
        top: -12px;
        color: ${purpleLight};
      }
    }
  `}
  
  ${(props) => props.multiSteps && css`
    left: calc(100% - 10px);
    right: ${props.stepCollapsed ? '-53px' : '-33px'};  
  `}  

  ${(props) => props.disabled && css`
    span {
      border-top-style: dashed;
    }
  `}
`;

export const CustomStepConnector = (props) => {
  const {expanded, ...rest} = props;
  return (
    <StyledStepConnector {...rest} />
  );
};