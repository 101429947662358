import { responsiveFontSizes } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const ARES_THEME = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#006C9E',
      light: '#ECF2F8',
      dark: '#233F5F',
      hover: '#2690C0',
      tableHover: '#CADBE7',
      menuHover: 'rgba(0,0,0,0.04)',
      active: '#004869'
    },
    secondary: {
      main: '#692984',
      light: '#D8C0E3',
      lighter: '#eddcf5',
      dark: '#3F1A50'
    },
    warning: {
      main: '#FBA84D',
      dark: '#ce7b21'
    },
    error: {
      main: '#FF7373',
      dark: '#c73b3b'
    },
    success: {
      main: '#26C095',
      dark: '#138a69'
    },
    text: {
      primary: '#070D13',
      secondary: 'rgba(0, 0, 0, 0.66)',
      disabled: '#737373',
      disabledLight: '#E6EAED',
      disabledDark: '#535353'
    },
    divider: '#DFE4ED',
    background: {
      default: '#F7FAFC',
      paper: '#FFFFFF',
      disabled: '#D9D9D9'
    },
    shadow: {
      card: '0 1px 10px 0 rgba(162,189,221,0.35)',
      hover: '0 2px 20px 0 rgba(162,189,221,0.35)',
      footer: '0 5px 20px 0 rgba(122,149,199,0.6)'
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 15
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 1020,
      lg: 1280,
      xl: 1920
    }
  }
}));

ARES_THEME.typography.body1 = {
  fontSize: '1rem',
  [ARES_THEME.breakpoints.down(MOBILE_BREAKPOINT)]: {
    fontSize: '0.9rem'
  }
};

export default ARES_THEME;