import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CustomIcon from 'commons/Icon/CustomIcon';
import { CustomLinkRouter } from 'commons/Link/CustomLink';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import useMobile from 'hook/UseMobile';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { ROUTE_PARAMS, ROUTES } from 'utils/constants/routes';
import { NewsService } from 'services/NewsService';
import useRealParams from 'hook/UseRealParams';
import { ContractService } from 'services/ContractService';
import { ProductTestService } from 'services/ProductTestService';
import { AuditService } from 'services/AuditService';
import { useCurrentRoute } from 'components/ActiveRoute/ActiveRouteContext';
import { useBreadCrumbContext, useBreadCrumbParam } from 'components/BreadCrumb/BreadCrumbContext';
import { UserService } from 'services/UserService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  nav: {
    display: 'inline-block'
  },
  backButton: {
    color: theme.palette.primary.main
  },
  currentPage: {
    color: theme.palette.secondary.main,
    fontSize: '130%',
    fontWeight: 'bold',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      fontSize: '110%'
    }
  },
  disabled: {
    color: theme.palette.text.disabled
  }
}));

const IDENTIFIER_PREFIX = ':';

export default function BreadCrumb() {
  const history = useHistory();
  const isMobile = useMobile();
  const classes = useStyles();
  const route = useCurrentRoute();
  const pathNames = route?.split('/') ?? [];

  const {
    contract: contractId,
    test: testId,
    news: newsId,
    audit: auditId,
    user: userId
  } = useRealParams();

  const [, setContractNumber] = useBreadCrumbParam(ROUTE_PARAMS.CONTRACT);
  const [, setTestNumber] = useBreadCrumbParam(ROUTE_PARAMS.TEST);
  const [, setNewsNumber] = useBreadCrumbParam(ROUTE_PARAMS.NEWS);
  const [, setAuditNumber] = useBreadCrumbParam(ROUTE_PARAMS.AUDIT);
  const [, setUserDisplayName] = useBreadCrumbParam(ROUTE_PARAMS.USER);
  const {getBreadcrumb} = useBreadCrumbContext();

  useEffect(() => {
    if (!contractId) {
      setContractNumber(null);
      return;
    };
    ContractService.getContractInfo(contractId)
      .then((fetched) => {
        setContractNumber(fetched.contractNumber);
      });
  }, [
    // reactive values
    contractId,
    // non reactive values
    setContractNumber
  ]);

  useEffect(() => {
    if (!testId) {
      setTestNumber(null);
      return;
    };
    ProductTestService.getTestDetails(contractId, testId)
      .then((fetched) => {
        setTestNumber(fetched?.general?.generalTestInfo?.testNumber);
      });
  }, [
    // reactive values
    contractId, testId,
    // non reactive values
    setTestNumber
  ]);

  useEffect(() => {
    if (!newsId) {
      setNewsNumber(null);
      return;
    };
    NewsService.getSingleNews(newsId)
      .then((fetched) => {
        setNewsNumber(fetched.number)
      });
  }, [
    // reactive values
    newsId,
    // non reactive values
    setNewsNumber
  ]);

  useEffect(() => {
    if (!auditId) {
      setAuditNumber(null);
      return;
    };
    AuditService.getAuditDetails(contractId, auditId)
      .then((fetched) => {
        setAuditNumber(fetched?.general?.auditNumber);
      });
  }, [
    // reactive values
    contractId,
    auditId,
    // non reactive values
    setAuditNumber
  ]);

  useEffect(() => {
    if (!userId) {
      setUserDisplayName(null);
      return;
    };
    UserService.getAresUserDetailedInfo(userId)
      .then((fetched) => {
        let contactInfo = fetched?.customerContactInformation;
        setUserDisplayName(`${contactInfo?.firstname} ${contactInfo?.lastname}`);
      });
  }, [
    // reactive values
    userId,
    // non reactive values,
    setUserDisplayName
  ]);

  const replacePathElement = (pathElement, index) => {
    if (pathElement?.startsWith(IDENTIFIER_PREFIX)) {
      return getBreadcrumb(pathElement);
    }
    return pathElement;
  }

  return (
    <div className={classes.root}>
      <CustomTooltip title="goBackHistory">
        <IconButton className={classes.backButton} onClick={() => history.goBack()}>
          <CustomIcon icon={faChevronCircleLeft}/>
        </IconButton>
      </CustomTooltip>
      <CustomTooltip title="home">
        <IconButton className={classes.backButton} onClick={() => history.push(ROUTES.CHOICE)}>
          <CustomIcon icon={faHome}/>
        </IconButton>
      </CustomTooltip>
      <Breadcrumbs aria-label="breadcrumb" className={classes.nav} separator={<CustomIcon icon={faChevronRight}/>}>
        {
          pathNames.map((path, index) => {
            const isLast = index === pathNames.length - 1;
            const isTrimmed = isMobile || path === 'contract';
            const pathElement = replacePathElement(path, index)
            let isIdentifier = pathElement !== path;
            if (index === 0 || index === 1) return '';
            const to = `${pathNames.slice(0, index + 1)
              .join('/')}`;
            if (isLast) {
              return (
                <LocalizedText className={classes.currentPage} key={to}>
                  {isIdentifier ? pathElement : `${pathElement}Page`}
                </LocalizedText>
              );
            }
            if (isTrimmed) {
              return '';
            }
            return (
              <CustomLinkRouter key={to} to={to}>
                <LocalizedText>
                  {isIdentifier ? pathElement : `${pathElement}Page`}
                </LocalizedText>
              </CustomLinkRouter>
            );
          })
        }
      </Breadcrumbs>
    </div>
  );
}