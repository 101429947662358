import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  accordionGeneral: {
    minWidth: '100%'
  },
  cookiesDescription: {
    alignSelf: 'center',
    color: 'purple',
    fontWeight: 'bold',
    justify: 'flex-end',
    minWidth: '90%'
  }
}));

export const MandatoryCookiesSelectionLine = () => {
  const classes = useStyles();
  return (
    <Accordion className={classes.accordionGeneral}>
      <AccordionSummary
        aria-controls="mandatory-cookie-accordion-content"
        expandIcon={<ExpandMoreIcon />}
        id="mandatory-cookie-accordion-header"
      >
        <LocalizedText className={classes.cookiesDescription}>mandatoryCookies</LocalizedText>
        <LocalizedText justify="flex-end" width="50%">required</LocalizedText>
      </AccordionSummary>
      <AccordionDetails>
        <LocalizedText>
          {localize('mandatoryCookieAdditionalText')}
        </LocalizedText>
      </AccordionDetails>
    </Accordion>
  );
};