import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    height: 260,
    width: 430,
    margin: 15,
    padding: 20
  },
  centered: {
    margin: 'auto'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  programCode: {
    marginLeft: 10,
    marginRight: 10
  }
}));

export default function SkeletonContractCard() {
  const classes = useStyles();
  return (
    <Skeleton className={classes.root} variant="rect">
      <Skeleton className={classes.centered} height={50} variant="text" width={330} />
      <Skeleton className={classes.centered} height={25} variant="text" width={290} />
      <br />
      <div className={classes.flex}>
        <Skeleton className={classes.programCode} height={30} variant="circle" width={30} />
        <Skeleton className={classes.text} height={25} variant="text" width={270} />
      </div>
    </Skeleton>
  );
}