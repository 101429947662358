/* eslint-disable max-len */
import {API_URL} from 'utils/constants/app';
import {RequestHelper} from 'utils/helpers/RequestHelper';

const dashboardServiceUrl = `${API_URL}/dashboard`;

const getCampaignsInfos = (contractId) => RequestHelper.GET(`${dashboardServiceUrl}/${contractId}/prestationsCount`);

const getContractListingInfo = (contractId) => RequestHelper.GET(`${dashboardServiceUrl}/${contractId}/contractListingInfo`);

const exportContract = (contractId, sendByMail) => RequestHelper.GET(`${dashboardServiceUrl}/${contractId}/${sendByMail}/export`);

const getCustomerRangesByContract = (contractId) => RequestHelper.GET(`${dashboardServiceUrl}/${contractId}/ranges`);

export const DashboardService = {
  getCampaignsInfos,
  exportContract,
  getCustomerRangesByContract,
  getContractListingInfo
};