// eslint-disable import/prefer-default-export
// eslint-disable-next-line no-underscore-dangle
const getEnv = (key) => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

export const API_URL = getEnv('API_URL');
export const MSAL_TENANT = getEnv('MSAL_TENANT');
export const MSAL_CLIENT = getEnv('MSAL_CLIENT');
export const MSAL_REDIRECT = getEnv('MSAL_REDIRECT');
export const GIT_BRANCH = getEnv('GIT_BRANCH');
export const GOOGLE_ANALYTICS_TOKEN = getEnv('GOOGLE_ANALYTICS_TOKEN');
export const ROOT_PATH = getEnv('ROOT_PATH');