/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { useCustomStepperStyles } from 'commons/CustomStepper/CustomStepper.styles';
import Step from 'commons/CustomStepper/Step';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import useQueryState from 'hook/UseQueryState';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ParamTypes } from 'utils/constants/param.type';
import { arraysEqual } from 'utils/processing/filters';
import { parseQueryStringValue } from 'utils/processing/url';

const VALUE_NAME = 'status';

const flatAllSteps = (step) => {
  if (step.steps) {
    return step.steps.map((st) => st);
  }

  return step;
};

function expandSteps(steps) {
  return steps.map((step) => {
    return {
      ...step,
      isCollapsed : step.isCollapsed != null ? false : null
    }
  })
}

const CustomStepper = ({
  initialStatuses, isInteractive, filter, updateFilter, statusesNumber, isDoubleBound, year, steps
}) => {
  const classes = useCustomStepperStyles();
  const [statuses, setStatuses] = useQueryState(VALUE_NAME, initialStatuses ?? [], ParamTypes.ARRAY);
  const [allSteps, setAllSteps] = useState(steps ?? []);
  const [allStepsFlat, setAllStepsFlat] = useState(allSteps.flatMap(flatAllSteps));

  useEffect(() => {
    setAllStepsFlat(allSteps.flatMap(flatAllSteps));
  }, [allSteps]);

  useEffect(() => {
    if (isInteractive) {
      setAllSteps(steps);
    } else {
      setAllSteps(expandSteps(steps));
    }
  }, [steps]);

  useEffect(() => {
    if (isInteractive) {
      if (!statuses || (Array.isArray(statuses) && statuses.length === 0)) {
        updateFilter(VALUE_NAME, null);
      } else {
        updateFilter(VALUE_NAME, filter(statuses));
      }
    }
  }, [statuses]);

  // If is double bound listen to updates and set state to new attribute value if it was modified by someone else
  if (isInteractive && isDoubleBound) {
    const parsedState = parseQueryStringValue(VALUE_NAME, window.location.search, ParamTypes.ARRAY);
    if (!arraysEqual(statuses, parsedState)) {
      setStatuses(parsedState);
    }
  }

  const handleStatusClick = (id) => {
    if (!isInteractive) {
      return;
    }

    const status = allStepsFlat.find((step) => step.id === id);

    if (statuses.includes(status.label)) {
      setStatuses(statuses.filter((elem) => elem !== status.label));
    } else {
      setStatuses(statuses.concat([status.label]));
    }
  };

  return (
    <>
      {isInteractive && (
        <>
          <LocalizedText className={classes.helperText} gutterBottom variant="body1">
            selectStepperHelp
          </LocalizedText>
          {!Number.isNaN(year) && (
            <Typography align="center">
              <LocalizedText text="referenceYear" />
              {`: ${year}`}
            </Typography>
          )}
        </>
      )}

      <Stepper
        alternativeLabel
        className={classes.base}
        nonLinear
      >
        {allSteps.map((step) => (
          <Step
            allSteps={allSteps}
            allStepsFlat={allStepsFlat}
            statuses={statuses}
            {...step}
            isInteractive={isInteractive}
            key={step.id}
            onClick={handleStatusClick}
            setAllSteps={setAllSteps}
            statusesNumber={statusesNumber}
          />
        ))}
      </Stepper>
    </>
  );
};

export default CustomStepper;

CustomStepper.propTypes = {
  filter: PropTypes.func, // required if interactive
  initialStatus: PropTypes.string, // if not interactive
  isDoubleBound: PropTypes.bool,
  isInteractive: PropTypes.bool,
  statusesNumber: PropTypes.object, // required if interactive
  steps: PropTypes.array.isRequired,
  updateFilter: PropTypes.func, // required if interactive
  year: PropTypes.number
};

CustomStepper.defaultProps = {
  filter: () => () => {},
  initialStatus: undefined,
  isDoubleBound: false,
  isInteractive: false,
  statusesNumber: {},
  updateFilter: () => {},
  year: null
};