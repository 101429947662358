// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from 'commons/AutoComplete/AutoComplete';
import { DialogType } from 'commons/Dialog/CustomDialog';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import ContractCard from 'components/ContractCard/ContractCard';
import BasicHeader from 'components/BasicHeader/BasicHeader';
import SkeletonContractCard from 'components/Skeletons/SkeletonContractCard/SkeletonContractCard';
import { useDialog } from 'hook/DialogProvider';
import useFetch from 'hook/UseFetch';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContractService } from 'services/ContractService';
import { ProgramService } from 'services/ProgramService';
import usePageTracking from 'hook/usePageTracking';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';

const useStyles = makeStyles({
  marginTop: {
    marginTop: 90
  },
  filter: {
    maxWidth: 400
  }
});

const CONTRACTS_FILTER_TRESHOLD = 20;

export const ContractSelectionPage = () => {
  const showDialog = useDialog();
  const contractsCount = useFetch(() => ContractService.getCustomerContractsCount()).response;
  const availablePrograms = useFetch(() => ProgramService.getPrograms()).response;
  const [contracts, setContracts] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  usePageTracking();

  const fetchContracts = async () => {
    try {
      await ContractService.getCustomerContracts(window.location.search.substring(1))
        .then((results) => {
          setContracts(results);
          if (results.length === 1) {
            history.push(GENERATE_ROUTE(ROUTES.HOME, {
              contract: results[0].contractEncryptedId
            }));
          }
        });
    } catch (errorMessage) {
      showDialog({
        variant: DialogType.ERROR,
        title: 'error',
        description: errorMessage
      });
    }
  };

  const selectContract = (contractEncryptedId) => {
    history.push(GENERATE_ROUTE(ROUTES.HOME, {
      contract: contractEncryptedId
    }));
  };

  const shouldFetchContracts = () => (
    (contractsCount && contractsCount <= CONTRACTS_FILTER_TRESHOLD)
    || (Object.entries(programs).length !== 0 && contractsCount >= CONTRACTS_FILTER_TRESHOLD)
  );

  useEffect(() => {
    if (contracts && contracts.length === 1) {
      selectContract(contracts[0].contractEncryptedId);
    }
  }, []);

  useEffect(() => {
    availablePrograms && availablePrograms.sort((a, b) => ((a.programCode > b.programCode) ? 1 : -1));
  }, [availablePrograms]);

  useEffect(() => {
    if (shouldFetchContracts()) {
      fetchContracts();
    } else {
      setContracts([]);
    }
  }, [programs, contractsCount]);

  const contractCards = () => {
    const contractsFiltered = contracts.filter(
      (contract) => companyNames.length === 0 || companyNames.includes(contract.companyName)
    );
    return (
      <>
        { contractsFiltered.length < CONTRACTS_FILTER_TRESHOLD
          ? contractsFiltered
            .map((contract) => {
              const {contractEncryptedId} = contract;
              return (
                <ContractCard
                  contract={contract}
                  key={contractEncryptedId}
                  onClick={() => selectContract(contractEncryptedId)}
                />
              );
            })
          : <LocalizedText align="center" variant="h4">chooseCompanies</LocalizedText>}
      </>
    );
  };

  const skeletonCards = (
    <>
      <SkeletonContractCard />
      <SkeletonContractCard />
      <SkeletonContractCard />
      <SkeletonContractCard />
    </>
  );

  const programFilter = (
    <AutoComplete
      className={classes.filter}
      isMultiple
      label="programs"
      onChange={(event, value) => setPrograms(value)}
      options={(availablePrograms && availablePrograms.map((p) => p.programCode)) || []}
      valueName="programs"
    />
  );

  const companyFilter = (
    <AutoComplete
      className={classes.filter}
      isMultiple
      label="companyNames"
      onChange={(event, value) => setCompanyNames(value)}
      options={contracts && contracts.map((c) => c.companyName)}
      valueName="companies"
    />
  );

  const shouldDisplaySkeletons = () => (
    !Array.isArray(contracts)
    || (contractsCount && contracts.length < 1 && contractsCount <= CONTRACTS_FILTER_TRESHOLD)
    || (programs && programs.length > 0 && contracts.length < 1)
  );

  const shouldDisplaySelectionText = () => (
    contractsCount >= CONTRACTS_FILTER_TRESHOLD && programs && programs.length < 1
  );

  return (
    <>
      <BasicHeader titleMobile="chooseContract" titlePC="chooseProgramAndContract" />
      { contractsCount >= CONTRACTS_FILTER_TRESHOLD && (
        <Grid
          alignItems="center"
          className={classes.marginTop}
          container
          direction="row"
          justify="space-evenly"
        >
          {programFilter}
          { contracts && contracts.length >= CONTRACTS_FILTER_TRESHOLD && companyFilter }
        </Grid>
      )}
      <Grid
        alignItems="center"
        className={classes.marginTop}
        container
        direction="row"
        justify="space-evenly"
      >
        { contractsCount < 1 && <LocalizedText align="center" variant="h4">noContractFound</LocalizedText>}
        { shouldDisplaySelectionText() && <LocalizedText align="center" variant="h4">chooseProgram</LocalizedText>}
        { shouldDisplaySkeletons() ? skeletonCards : contractCards() }
      </Grid>

      {/* <Stepper {...stepperModel} /> */}
    </>
  );
};