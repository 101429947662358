/* eslint-disable no-param-reassign */
/**
 * Filter to check if an array of value to match contains a given field from the table
 * @param arrayToMatch array of values to check if at least one matches
 * @param tableValue value to test against it
 * @returns {boolean} true if matched, false otherwise
 */
export const valueContained = (arrayToMatch, tableValue) => arrayToMatch.includes(tableValue);

/**
 * Filter to check if an array of value to match contains one of a given field from the table
 * @param arrayToMatch array of values to check if at least one matches
 * @param values list of values to chefk if at least one is conatined
 * @returns {boolean} true if matched, false otherwise
 */
export const arrayValueContained = (arrayToMatch, values) => (values ? values.some((val) => arrayToMatch.includes(val)) : false);


/**
 * Filter to check if an array of values matches a given field from the table
 * At least one of the selected value must be totally contained in a field for it to work
 * @param arrayToMatch array of values to check if at least one matches
 * @param tableValue value to test against it
 * @returns {boolean} true if at least one the values in the array matched, false otherwise
 */
export const valueContainedWithMatching = (arrayToMatch, tableValue) => arrayToMatch
  .map((arrayValue) => tableValue && tableValue.toLowerCase().includes(arrayValue.toLowerCase()))
  .reduce((acc, val) => acc || val, false);

/**
 * Shallowly compares two array by sorting them and comparing every element
 * @param arr1 the first array
 * @param arr2 the second array
 * @returns {boolean} true if they are the same, false otherwise
 */
export const arraysEqual = (arr1, arr2) => {
  if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length === arr2.length) {
    const arr2sorted = [...arr2].sort();
    return [...arr1].sort().every((element, index) => element === arr2sorted[index]);
  }
  return false;
};

/**
 * Reducer to group array of string into an object of structure : { string : numberOfOccurrence } for each string
 * @param object the object to enhance (typically {})
 * @param item the current item of the list
 * @returns {*} the object with number of occurrence
 */
export const groupByCount = (object, item) => {
  if (item) {
    if (!object[item]) {
      object[item] = 0;
    }
    object[item] += 1;
  }
  return object;
};


/**
 * Reducer to group array of array of string into an object of structure : { string : numberOfOccurrence }
 * for each string in array
 * @param object the object to enhance (typically {})
 * @param array a list of item to reduce
 * @returns {*} the object with number of occurrence
 */
export const groupByCountArray = (object, array) => {
  array && array.forEach((item) => {
    groupByCount(object, item);
  });
  return object;
};


/**
 * Checks if a value is null or is an empty array
 * @param value the value to check
 * @returns {boolean} true if null or empty, false otherwise
 */
export const isEmpty = (value) => (!value || (Array.isArray(value) && value.length === 0)) && value !== 0;