import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { CustomButton } from 'commons/Button/CustomButton';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import useMobile from 'hook/UseMobile';
import useQueryState from 'hook/UseQueryState';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ParamTypes } from 'utils/constants/param.type';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';
import { useAuthContext } from "hook/AuthProvider";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    marginLeft: 10,
    flexGrow: 1
  },
  imgResize: {
    margin: 3,
    marginLeft: 20,
    width: 91,
    height: 66
  },
  backButton: {
    color: theme.palette.common.white
  }
}));

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const BasicHeader = ({ titleMobile, titlePC }) => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useMobile();
  const [canGoBack] = useQueryState('back', false, ParamTypes.BOOLEAN);
  const {logOut} = useAuthContext();

  return (
    <HideOnScroll>
      <AppBar>
        <Toolbar>
          {
            canGoBack && !isMobile && (
              <CustomTooltip title="goBackHistory">
                <IconButton className={classes.backButton} onClick={() => history.goBack()}>
                  <CustomIcon icon={faChevronCircleLeft} />
                </IconButton>
              </CustomTooltip>
            )
          }
          <LocalizedText className={classes.headerTitle} variant="h5">
            {isMobile ? titleMobile : titlePC}
          </LocalizedText>
          <CustomButton
            onClick={() => logOut()}
            variant="header"
          >
            logout
          </CustomButton>
          {
            !isMobile && <img alt="Logo" className={classes.imgResize} 
              src={getAssetUrl("/assets/images/ECC_transparent.png")} />
          }
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default BasicHeader;