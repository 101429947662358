import Typography from '@material-ui/core/Typography';
import FileDownloader from 'commons/Icon/FileDownloader';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import React from 'react';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';

const headers = [
  generateRow({
    name: 'document',
    template: (row) => (
      <CustomTooltip title="downloadDocument">
        <span>
          <FileDownloader fileId={row.id} />
        </span>
      </CustomTooltip>
    ),
    width: '33%',
    mobileWidth: '40%'
  }),
  generateRow({
    name: 'type',
    template: (row) => (
      <LocalizedText>{row.type}</LocalizedText>
    ),
    width: '33%',
    mobileWidth: '60%'
  }),
  generateRow({
    name: 'creationDate',
    isOnMobile: false,
    template: (row) => (
      <Typography variant="body2">{timestampToDate(row.creationDate)}</Typography>
    ),
    width: '33%'
  })
];

export const ContractFilesTab = ({ data = [] }) => (
  <CustomTableWithQuerySort
    headers={headers}
    rows={data}
  />
);