import React from 'react';
import ContractContactsCard from 'components/Contract/ContractContactsCard/ContractContactsCard';
import Grid from '@material-ui/core/Grid';
import SkeletonContractContactCard from 'components/Skeletons/SkeletonContractContactsCard/SkeletonContractContactCard';


export const ContractContactsPage = ({ contacts }) => (
  <Grid
    alignItems="center"
    container
    direction="row"
    justify="space-evenly"
  >
    {
      contacts ? (
        <>
          {
            contacts.map((data) => {
              const { role } = data;
              return (
                <ContractContactsCard
                  data={data}
                  key={role}
                />
              );
            })
          }
        </>
      )
        : (
          <>
            <SkeletonContractContactCard />
            <SkeletonContractContactCard />
            <SkeletonContractContactCard />
          </>
        )
    }
  </Grid>
);