import * as PropTypes from 'prop-types';
import React from 'react';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {GridLayout} from 'utils/helpers/GridLayout';
import {useStyles} from 'components/News/NewsForm';
import {NewsState} from 'components/News/news-admin-projection';


export const PublicationPeriodSection = ({news, updateNews, dateParams}) => {
  const styles = useStyles();

  return (
    <div className={styles.section}>
      <LocalizedText className={styles.title}>publicationPeriod</LocalizedText>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GridLayout 
          alignItems="flex-start"
          direction="row"
          justifyContent="flex-start"
          spacing={5}
        >
          <KeyboardDateTimePicker
            ampm={dateParams?.ampm}
            disabled={news?.state === NewsState.ONGOING}
            disablePast={news?.state === NewsState.UPCOMING}
            format={dateParams?.format}
            InputLabelProps={{
              shrink: true
            }}
            inputVariant="outlined"
            label={<LocalizedText>startDate</LocalizedText>}
            onChange={(newValue) => {
              updateNews({startDate: newValue})
            }}
            required={true}
            value={news?.startDate || null}
          />

          <KeyboardDateTimePicker
            ampm={dateParams?.ampm}
            disablePast={true}
            format={dateParams?.format}
            InputLabelProps={{
              shrink: true
            }}
            inputVariant="outlined"
            label={<LocalizedText>endDate</LocalizedText>}
            minDate={news?.startDate || null}
            minDateMessage={<LocalizedText>newsValidationEndBeforeStart</LocalizedText>}
            onChange={(newValue) => {
              updateNews({endDate: newValue})
            }}
            value={news?.endDate || null}
          />
        </GridLayout>

      </MuiPickersUtilsProvider>
    </div>
  )
}

PublicationPeriodSection.propTypes = {
  dateParams: PropTypes.shape({
    ampm: PropTypes.bool,
    format: PropTypes.string
  }).isRequired,
  news: PropTypes.shape({}).isRequired,
  updateNews: PropTypes.func.isRequired
}