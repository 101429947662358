import React, { createContext, useContext, useMemo } from 'react';
import { findMatchingRoute, ROUTES } from 'utils/constants/routes';
import { useLocation } from 'react-router-dom';

const ActiveRouteContext = createContext({});
export const useCurrentRoute = () => useContext(ActiveRouteContext);

export const ActiveRouteContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const activeRoute = useMemo(() => findMatchingRoute(pathname, ROUTES), [pathname])

  return (
    <ActiveRouteContext.Provider value={activeRoute}>
      {children}
    </ActiveRouteContext.Provider>
  );
};