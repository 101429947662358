import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { localize } from 'commons/LocalizedText/LocalizedText';
import * as PropTypes from 'prop-types';
import React from 'react';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';

const useStyles = makeStyles((theme) => ({
  countryFlag: {
    width: 30,
    marginRight: theme.spacing(1)
  }
}));

export const LanguagePicker = ({
  locales, value, onChange, id, label, className
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      className={className}
      disableClearable
      getOptionLabel={(option) => option.name}
      id={id}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={locales}
      renderInput={(params) => (
        <TextField
          {...params}
          label={localize(label)}
          variant="outlined"
        />
      )}
      renderOption={(locale) => (
        <>
          <img
            alt={`${locale.countryCode} flag`}
            className={classes.countryFlag}
            // icons : https://www.iconfinder.com/iconsets/195-flat-flag-psd-icons
            src={getAssetUrl(`/assets/images/flags/${locale.countryCode}.png`)}
          />
          {locale.name}
        </>
      )}
      value={value}
    />
  );
};

LanguagePicker.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.shape({
    countryCode: PropTypes.string,
    current: PropTypes.bool,
    languageCode: PropTypes.string,
    name: PropTypes.string
  })),
  onChange: PropTypes.func,
  value: PropTypes.shape({
    countryCode: PropTypes.string,
    current: PropTypes.bool,
    languageCode: PropTypes.string,
    name: PropTypes.string
  })
};

LanguagePicker.defaultProps = {
  className: '',
  id: 'language-picker',
  locales: [],
  onChange: () => {},
  value: null
};