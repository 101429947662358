import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIES_ACTIVATED } from 'utils/constants/cookies';

const useStyles = makeStyles((theme) => ({
  accordionGeneral: {
    minWidth: '100%'
  },
  cookiesDescription: {
    alignSelf: 'center',
    color: 'purple',
    fontWeight: 'bold',
    justify: 'flex-end',
    minWidth: '90%'
  }
}));

export const OptionalCookiesSelectionLine = () => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies([COOKIES_ACTIVATED]);
  const [accepted, setAccepted] = useState(cookies[COOKIES_ACTIVATED] === 'true');

  return (
    <Accordion className={classes.accordionGeneral}>
      <AccordionSummary
        aria-controls="optional-cookie-accordion-content"
        expandIcon={
          <ExpandMoreIcon />
        }
        id="optional-cookie-accordion-header"
      >
        <LocalizedText className={classes.cookiesDescription}>
          googleAnalyticsCookies
        </LocalizedText>
        <FormControlLabel
          control={(
            <Switch
              checked={accepted}
              color="primary"
              name="cookieAcceptationSwitch"
              onChange={(e) => {
                setCookie(COOKIES_ACTIVATED, !accepted);
                setAccepted(!accepted);
              }}
            />
          )}
          height="40px"
          label={localize('accept')}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => event.stopPropagation()}
        />
      </AccordionSummary>
      <AccordionDetails>
        <LocalizedText>
          {localize('optionalCookiesAdditionalText')}
        </LocalizedText>
      </AccordionDetails>
    </Accordion>
  );
};