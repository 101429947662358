import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {NewsMenuCard} from 'components/News/NewsMenuCard';
import {useStyles} from 'components/News/NewsMenuButton';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CustomIcon from 'commons/Icon/CustomIcon';
import {faNewspaper, faTimes} from '@fortawesome/pro-solid-svg-icons';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import {FloatingButton} from 'commons/Button/FloatingButton';

export const NewsPreview = ({news, isOpen, onClose}) => {
  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor={'right'}
        classes={{paper: classes.drawerPaper}}
        onClose={onClose}
        open={isOpen}
      >
        <div className={classes.menuHeader}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item>
              <CustomIcon className={classes.newsIcon} icon={faNewspaper} size="2x"/>
            </Grid>
            <Grid item xs zeroMinWidth>
              <LocalizedText className={classes.menuTitle}>preview</LocalizedText>
            </Grid>
            <Grid item>
              <FloatingButton icon={faTimes} onClick={onClose}/>
            </Grid>
          </Grid>
        </div>
        <NewsMenuCard news={news}/>
      </Drawer>
    </>
  );
}

NewsPreview.propTypes = {
  isOpen: PropTypes.bool,
  news: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired
}