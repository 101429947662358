/* eslint-disable no-useless-escape */
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { TextLabel } from 'commons/Label/TextLabel';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { localize } from "commons/LocalizedText/LocalizedText";

const useStyles = makeStyles(() => ({
  generalContainer: props => ({
    height: props.multiline ? '100%' : '70px'
  }),
  text: props => ({
    marginBottom: props.error ? '10%' : '0'
  }),
  modifiedTextField: {
    '& fieldset': {
      borderColor: '#31A597',
      borderWidth: '2px'
    }
  },
  root: props => ({
    "& .MuiInputBase-root": {
      padding: props.multiline ? '5px' : '',
      "& .MuiInputBase-input": {
        padding: 10
      }
    }
  })
}));

export const handleTelRegexValidation = value => {
  return !(value !== '' && !(/^([+]?[(]?\d{2,4}[)]?)?([-\s]?\d)+$/g.test(value)));
}

export const handleFaxRegexValidation = value => {
  return !(value !== '' && !(/^([+]?[(]?\d{2,4}[)]?)?[\d ]*$/g.test(value)));
}

export const handleMailRegexValidation = value => {
  return !(value !== '' && !(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)));
}

export const TextFieldWithLabel = ({
  label,
  required,
  disabled,
  multiline,
  value,
  onBlur,
  onChange,
  startsModified = false,
  enableColorChangeOnModification,
  type,
  error,
  setError,
  labelXS = 6,
  textFieldXS = 6,
  hideErrorLabel= false
}) => {
  const [isModified, setIsModified] = useState(startsModified);
  const [internError, setInternError] = useState(false);
  const classes = useStyles(
    {
      error: !!(type && internError),
      multiline
    }
  );

  const handleOnChange = (value) => {
    onChange(value);
    if(enableColorChangeOnModification) setIsModified(true);
  }

  const handleValidation = () => {
    if (!required && (!value || value === '')) {
      return true;
    }
    if (type === 'phone') {
      return handleTelRegexValidation(value);
    } else if (type === 'email') {
      return handleMailRegexValidation(value);
    } else if (type === 'fax') {
      return handleFaxRegexValidation(value);
    }
  }

  const handleError = errorValue => {
    setInternError(errorValue);
    if(setError) setError(errorValue)
  }

  useEffect(() => {
    if(type){
      handleError(!handleValidation());
    }
  }, [value])

  return (
    <Grid item>
      <Grid alignItems='center' className={classes.generalContainer} container justifyContent='space-between' spacing={2} wrap='nowrap'>
        <Grid item xs={labelXS}>
          <TextLabel className={`${hideErrorLabel ? '' : classes.text}`} required={required} text={label}/>
        </Grid>
        <Grid item xs={textFieldXS}>
          <TextField
            className={`${classes.root}
                      ${isModified ? classes.modifiedTextField : ''}`}
            disabled={disabled}
            error={internError}
            helperText={(type && internError && !hideErrorLabel) ? localize(`${type}Error`) : null}
            InputProps={{
              className: `${isModified ? classes.modifiedTextField : ''}`
            }}
            multiline={multiline}
            onBlur={onBlur}
            onChange={e => handleOnChange(e.target.value)}
            required={required}
            style={{width: '90%'}}
            type={type}
            value={value || ''}
            variant='outlined'
          />
        </Grid>
      </Grid>
    </Grid>
  )
};