import CustomStepper from 'commons/CustomStepper/CustomStepper';
import { Actions } from 'components/Actions/Actions';
import { BasicPageContainer } from 'components/PageContainer/BasicPageContainer';
import PageContent from 'components/PageContent/PageContent';
import TabsMenu from 'components/TabsMenu/TabsMenu';
import useFetch from 'hook/UseFetch';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { AuditRoutes } from 'routes/AuditRoutes';
import { AuditService } from 'services/AuditService';
import { GET_AUDIT_TABS } from 'utils/constants/tabs';

export const AuditPage = () => {
  const {contract: contractId, audit: auditId} = useRealParams();
  const auditDetails = useFetch(() => AuditService.getAuditDetails(contractId, auditId), {}).response;
  const steps = useFetch(() => AuditService.getStepperStatuses(), []).response;
  const auditTabs = GET_AUDIT_TABS();
  return (
    <BasicPageContainer>
      <CustomStepper
        initialStatuses={auditDetails?.general?.status}
        key={auditDetails?.general?.status}
        steps={steps}
      />
      {Array.isArray(auditDetails.actions) && auditDetails.actions.length > 0 &&
        <Actions actions={auditDetails.actions}/>}
      <PageContent lowPaddingTop>
        <TabsMenu tabs={auditTabs} />
        <AuditRoutes audit={auditDetails} />
      </PageContent>
    </BasicPageContainer>
  );
};