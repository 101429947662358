import CustomStepper from 'commons/CustomStepper/CustomStepper';
import { Actions } from 'components/Actions/Actions';
import { BasicPageContainer } from 'components/PageContainer/BasicPageContainer';
import PageContent from 'components/PageContent/PageContent';
import TabsMenu from 'components/TabsMenu/TabsMenu';
import useFetch from 'hook/UseFetch';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { ProductTestRoutes } from 'routes/ProductTestRoutes';
import { ProductTestService } from 'services/ProductTestService';
import { GET_PRODUCT_TEST_TABS } from 'utils/constants/tabs';

export const TestPage = () => {
  const { contract: contractId, test: testId } = useRealParams();
  const testDetails = useFetch(() => ProductTestService.getTestDetails(contractId, testId), {}).response;
  const steps = useFetch(() => ProductTestService.getStepperStatuses(), []).response;
  const testTabs = GET_PRODUCT_TEST_TABS();
  return (
    <BasicPageContainer>
      {/* Use key to force redraw of the component in order to update its state */}
      <CustomStepper
        initialStatuses={[testDetails?.general?.generalTestInfo?.testStatusCustomer]}
        key={testDetails?.general?.generalTestInfo?.testStatusCustomer}
        steps={steps}
      />
      {Array.isArray(testDetails.actions) && testDetails.actions.length > 0 && <Actions actions={testDetails.actions} />}
      <PageContent lowPaddingTop>
        <TabsMenu tabs={testTabs} />
        <ProductTestRoutes test={testDetails} />
      </PageContent>
    </BasicPageContainer>
  );
};