/* eslint-disable max-len */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AutocompleteFilter } from 'commons/Filter/AutocompleteFilter/AutocompleteFilter';
import FileDownloader from 'commons/Icon/FileDownloader';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { useContractInfo } from 'hook/ContractInfoProvider';
import useFetch from 'hook/UseFetch';
import useFilters from 'hook/UseFilters';
import useRealParams from 'hook/UseRealParams';
import React from 'react';
import { DiplomaService } from 'services/DiplomaService';
import { CertificationStatus } from 'utils/constants/certification.status';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';
import { valueContained, valueContainedWithMatching } from 'utils/processing/filters';

const useStyles = makeStyles((theme) => ({
  downloadLink: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginRight: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(6)
    }
  }
}));

const DownloadIconSpace = ({ fileId, tooltip }) => {
  const classes = useStyles();
  return (
    <CustomTooltip title={tooltip}>
      <span className={classes.downloadLink}>
        <FileDownloader fileId={fileId} />
      </span>
    </CustomTooltip>
  );
};

const headers = [
  generateRow({
    name: 'rangeName',
    width: '33%',
    mobileWidth: '33%',
    label: 'range'
  }),
  generateRow({
    name: 'diplomaNumber',
    width: '33%',
    mobileWidth: '33%',
    label: 'diploma',
    template: (row) => (
      <Grid
        container
        justify="flex-start"
      >
        <Typography variant="body2">
          {row.diplomaNumber}
        </Typography>
        {
          row.status === CertificationStatus.CERTIFIED &&
              <DownloadIconSpace
                fileId={row.fileId}
                tooltip="downloadDiplomaTooltip" />
        }
      </Grid>
    )
  }),
  generateRow({
    name: 'status',
    width: '33%',
    mobileWidth: '33%',
    label: 'status',
    template: (row) => (
      <Grid container>
        <LocalizedText variant="body2">
          {row.status}
        </LocalizedText>
        {
          row.status === CertificationStatus.CERTIFIED
          && (
            <Typography variant="body2">
              &nbsp;
              {`${localize('until')} ${timestampToDate(row.validityDate)}`}
            </Typography>
          )
        }
      </Grid>
    )
  })
];

export const DiplomasCertifyRange = () => {
  const {contract: contractId} = useRealParams();
  const [filters, updateFilters] = useFilters();
  const contractInfo = useContractInfo();
  const {response: rows, isLoading} = useFetch(() => DiplomaService.getDiplomasCertifyRange(contractId), []);

  return (
    <>
      <Typography variant="h5">
        {`${contractInfo.programCode} - ${localize('certificationByRange')}`}
      </Typography>
      <Grid
        alignItems="center"
        container
        direction="row"
        justify="space-evenly"
      >
        <AutocompleteFilter
          filter={(value) => (row) => valueContainedWithMatching(value, row.rangeName)}
          isFree
          isMultiple
          label="range"
          options={rows.map((r) => r.rangeName)}
          updateFilter={updateFilters}
          valueName="range"
        />
        <AutocompleteFilter
          filter={(value) => (row) => valueContainedWithMatching(value, row.diplomaNumber)}
          isFree
          isMultiple
          label="diploma"
          options={rows.map((r) => r.diplomaNumber)}
          updateFilter={updateFilters}
          valueName="diploma"
        />
        <AutocompleteFilter
          filter={(value) => (row) => valueContained(value, row.status)}
          isMultiple
          isTranslatingOptions
          label="status"
          options={Object.values(CertificationStatus)}
          updateFilter={updateFilters}
          valueName="status"
        />
      </Grid>
      <br />
      <CustomTableWithQuerySort
        filters={filters}
        headers={headers}
        isLoading={isLoading}
        rowKey="diplomaNumber"
        rows={rows}
      />
    </>
  );
};