/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { localize } from 'commons/LocalizedText/LocalizedText';
import useQueryState from 'hook/UseQueryState';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ParamTypes } from 'utils/constants/param.type';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { arraysEqual } from 'utils/processing/filters';
import { parseQueryStringValue } from 'utils/processing/url';

const useStyles = makeStyles((theme) => ({
  root: (isMultiple) => ({
    flexGrow: 1,
    width: isMultiple ? 300 : 150,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    }
  }),
  inputRoot: {
    '& .MuiAutocomplete-tagSizeSmall': {
      maxWidth: 'calc(50% - 5px)'
    }
  }
}));

/**
 * AutoComplete is a multi-select list; selected value(s) is(are) synchronized with the query string
 * @param options list of available options
 * @param label translated label of the field
 * @param isFree is selection of items not in the list possible
 * @param valueName identifier of the field name
 * @param isMultiple is the field accepting multiple selects
 * @param isDoubleBound does the component need to react to url change
 * @param className is used to apply style
 * @param onChange is a function triggered when selected options change
 * @param isTranslatingOptions function to translate options if needed
 * @param optionService service to fetch options from
 * @param required makes this autocomplete field act like its required
 * @param defaultValue defines a default value that will be used instead of undefined when necessary
 */
export const AutoComplete = ({
  options, label, isFree, valueName, isMultiple, isDoubleBound, className, onChange, isTranslatingOptions,
  optionService, required = false, defaultValue
}) => {
  const classes = useStyles(isMultiple);
  const [value, setValue] = useQueryState(valueName, isMultiple ? [] : null, isMultiple ? ParamTypes.ARRAY : ParamTypes.STRING);
  const [realOptions, setRealOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  useEffect(() => {
    onChange(null, value);
    if (optionService) {
      setIsLoading(true);
      optionService().then((response) => {
        if (response) {
          setIsLoading(false);
          setRealOptions(response);
        }
      });
    }
  }, [optionService]);

  useEffect(() => {
    setRealOptions(options);
  }, [options]);

  // if double bound check if the value was changed by something else and if needed update the value of the component
  if (isDoubleBound) {
    const parsedState = parseQueryStringValue(valueName, window.location.search, ParamTypes.ARRAY);
    if (!arraysEqual(value, parsedState)) {
      setValue(parsedState);
      onChange(null, parsedState);
    }
  }

  return (
    <Autocomplete
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot
      }}
      className={`${classes.root} ${className}`}
      filterSelectedOptions
      freeSolo={isFree}
      getOptionLabel={isTranslatingOptions ? (option) => localize(option) : (option) => option}
      limitTags={2}
      loading={isLoading}
      multiple={isMultiple}
      onChange={handleOnChange}
      options={realOptions && realOptions.length > 0 ?
        Array.from(new Set(realOptions.filter((option) => option != null).sort())) : []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          label={localize(label)}
          margin="normal"
        />
      )}
      required={required}
      size="small"
      value={value ?? defaultValue}
    />
  );
};

AutoComplete.propTypes = {
  className: PropTypes.string,
  isDoubleBound: PropTypes.bool,
  isFree: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isTranslatingOptions: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  optionService: PropTypes.func,
  valueName: PropTypes.string.isRequired
};

AutoComplete.defaultProps = {
  className: '',
  isDoubleBound: false,
  isFree: false,
  isMultiple: false,
  isTranslatingOptions: false,
  options: [],
  optionService: null
};