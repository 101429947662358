import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { localize } from 'commons/LocalizedText/LocalizedText';
import { MandatoryCookiesSelectionLine } from 'components/Account/MandatoryCookiesSelectionLine';
import { OptionalCookiesSelectionLine } from 'components/Account/OptionalCookiesSelectionLine';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  generalContainer: {
    display: '-webkit-inline-box',
    flexWrap: 'wrap'
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

const cookiesText = (classes) => {
  return (<Grid className={`${classes.generalContainer}`} container item sm={12} xs={12}>
    <strong>{localize('cookiesFirstLinePart1')}</strong>&nbsp;
    {localize('cookiesFirstLinePart2')}&nbsp;
    <strong>{localize('cookiesFirstLinePart3')}&nbsp;</strong>
    <br/>
    <br/>
    <strong>{localize('cookiesSecondLinePart1')}</strong>&nbsp;
    {localize('cookiesSecondLinePart2')}&nbsp;
    <br/>
    <br/>
    <strong>{localize('cookiesThirdLinePart1')}</strong>&nbsp;
    {localize('cookiesThirdLinePart2')}&nbsp;
  </Grid>);
}

export const UserAdministrationCookies = () => {
  const classes = useStyles();

  return (
    <div className={classes.marginTop}>
      <div className={classes.centeredFlex}>
        {cookiesText(classes)}
      </div>
      <br/>
      <div>
        <Grid
          alignItems="flex-start"
          container
          direction="column"
          justify="space-evenly"
          spacing={2}
        >
          <MandatoryCookiesSelectionLine/>
          <OptionalCookiesSelectionLine/>
        </Grid>
      </div>
    </div>
  );
};