import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React, { Fragment } from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  generalContainer: {
    display: '-webkit-inline-box',
    flexWrap: 'wrap'
  },
  generalText: {
    fontWeight: 'bold'
  },
  gridPadding: {
    padding: theme.spacing(1)
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  },
  accountInfoTitle: {
    maxWidth: '15%'
  }
}));

export const UserAdministrationContactInformation = () => {
  const classes = useStyles();
  const toDisplayContactInfo = ['firstName', 'lastName', 'ssoEmail', 'customerEmail'];
  const contactInformation = useCustomerInfo();
  return (
    <div className={classes.marginTop}>
      <LocalizedText className={classes.sectionTitle} component="h5" variant="h5">
              contactInformation
      </LocalizedText>
      <div className={classes.centeredFlex}>
        <Grid className={`${classes.generalContainer}`} container item sm={12} xs={12}>
          {Object.entries(contactInformation)
            .filter(([key]) => toDisplayContactInfo.includes(key))
            .map(([key, value]) => (
              <Fragment key={key}>
                <Grid container>
                  <Grid className={[classes.gridPadding, classes.accountInfoTitle]} item sm={6} xs={6}>
                    <Typography className={classes.generalText}>
                      <LocalizedText text={key} />
                      {': '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.gridPadding} item sm={6} xs={6}>
                    <Typography>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          <a href={'https://aka.ms/ssprsetup'} rel="noreferrer" target="_blank">
            <LocalizedText>
              resetPassword
            </LocalizedText>
          </a>
        </Grid>
      </div>
    </div>
  );
};