/* eslint-disable no-param-reassign,react/no-unused-prop-types */
import { faBell, faCaretRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Container, useTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { Triangle } from 'commons/Triangle/Triangle';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  expansionPanel: {
    boxShadow: theme.palette.shadow.card,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 !important'
  },
  panelSummary: {
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
      alignItems: 'center'
    },
    '& .MuiExpansionPanelSummary-expandIcon': {
      marginRight: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0
    },
    minHeight: '48px !important',
    padding: 0
  },
  warningBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: 50,
    paddingLeft: 10,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main
  },
  wiggle: {
    animation: 'wiggle 4s infinite'
  },
  panelTitle: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold'
  },
  panelActions: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  actionsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 250
  },
  actionDetails: {
    marginLeft: theme.spacing(1)
  }
}));

export const Actions = ({ actions }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container
      className={classes.root}
      fixed
      maxWidth="lg"
    >
      <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
        <ExpansionPanelSummary
          aria-controls="actions-to-do-panel"
          className={classes.panelSummary}
          expandIcon={<CustomIcon icon={faChevronDown} size="xs" tooltip={localize('open')} />}
        >
          <div className={classes.warningBackground}>
            <CustomIcon className={classes.wiggle} icon={faBell} size="lg" />
          </div>
          <Triangle backgroundColor={theme.palette.warning.main} height={48} isDiagonal width={20} />
          <LocalizedText className={classes.panelTitle}>actionsToDo</LocalizedText>
          <Typography className={classes.panelActions}>
            (
            {actions.length}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justify="space-between"
          >
            {actions.map((action) => (
              <div className={classes.actionContainer} key={action}>
                <CustomIcon color={theme.palette.warning.main} icon={faCaretRight} size="lg" />
                <LocalizedText className={classes.actionDetails}>{action}</LocalizedText>
              </div>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Container>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired
};