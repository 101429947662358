import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridLayout } from 'utils/helpers/GridLayout';
import { PublicationPeriodSection } from 'components/News/PublicationPeriodSection';
import { AccountSection } from 'components/News/AccountSection';
import { TranslationSection } from 'components/News/TranslationSection';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import CustomIcon from 'commons/Icon/CustomIcon';
import { faEye, faSave, faUndo, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { NewsState } from 'components/News/news-admin-projection';
import { NewsPreview } from 'components/News/NewsPreview';
import { FloatingButton } from 'commons/Button/FloatingButton';
import { FloatingButtonMenu } from 'commons/Button/FloatingButtonMenu';
import { useDialog } from 'hook/DialogProvider';
import { DialogType } from 'commons/Dialog/CustomDialog';
import { useLocales } from 'hook/LocalesProvider';


export const useStyles = makeStyles((theme) => ({
  warningCard: {
    alignContent: 'center',
    backgroundColor: `orange`,
    marginBottom: '10px',
    padding: '10px'
  },
  warningIcon: {
    color: 'gold',
    paddingRight: "10px"
  },
  title: {
    color: theme.palette.secondary.main,
    paddingBottom: '15px',
    '&::before': {
      content: '"-"',
      backgroundColor: theme.palette.secondary.main,
      marginRight: '10px'
    }
  },
  section: {
    paddingBottom: '10px'
  }
}));

const allLocalesParams = {
  EN: {
    format: 'yyyy-MM-dd hh:mm aaa',
    ampm: true
  },
  FR: {
    format: 'yyyy-MM-dd HH:mm',
    ampm: false
  }
};

export const NewsForm = ({initialValue, saveAction}) => {
  const showDialog = useDialog();

  const [news, setNews] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [previewActive, setPreviewActive] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const styles = useStyles();
  const [currentLocaleParams, setCurrentLocaleParams] = useState();
  const locales = useLocales();
  const locale = locales[locales.findIndex((loc) => loc.current)];

  useEffect(() => {
    const selectedLocale = locale?.countryCode || 'EN';
    const localeParams = allLocalesParams[selectedLocale];
    setCurrentLocaleParams(localeParams)
  }, [locale])

  // When initialValue is set
  useEffect(() => {
    setNews(initialValue);
  }, [initialValue])

  const resetForm = () => {
    setNews(initialValue);
  }

  const updateNews = (delta) => {
    setNews((previousNews) => {
      return {...previousNews, ...delta}
    });
  }

  const updateTranslation = (delta, selectedLanguage) => {
    setNews((previousNews) => {
      const previousTranslation = findOrInitTranslation(previousNews, selectedLanguage);
      let newTranslation = {...previousTranslation, ...delta}

      if (!newTranslation.title && !newTranslation.content) {
        newTranslation = null;
      }

      const previousTranslations = previousNews?.translations;
      let newTranslations;
      if (previousTranslations) {
        newTranslations = previousTranslations.filter(t => t && t.languageCode !== previousTranslation.languageCode);
        newTranslations.push(newTranslation);
      } else {
        newTranslations = [newTranslation];
      }

      newTranslations = newTranslations.filter(t => t !== null)
      return {...previousNews, translations: newTranslations}
    });
  };

  const initTranslation = () => {
    return {languageCode: selectedLanguage};
  }

  const findOrInitTranslation = (news, selectedLanguage) => {
    const existingTranslations = news?.translations?.filter(t => t && t.languageCode === selectedLanguage);
    return (existingTranslations && existingTranslations.length) ? existingTranslations[0] : initTranslation();
  }

  function getNewsPreview(news, selectedLanguage) {
    const translation = findOrInitTranslation(news, selectedLanguage);
    return { startDate: news?.startDate, ...translation };
  }

  const saveNewsAction = (news) => {
    if (news?.translations && news.translations.length) {
      saveAction(news)
    } else {
      showDialog({
        variant: DialogType.ERROR,
        title: 'Error',
        description: 'errorMissingTranslations'
      });
    }
  }

  const isInputValid = (news) => {
    if (news?.translations) {
      for (const translation of news.translations) {
        if (!translation?.title) return false;
      }
      return news?.startDate;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setInputValid(isInputValid(news))
  }, [news])

  return (
    <div>
      { (news?.state && news.state === NewsState.ONGOING) && (
        <div className={styles.warningCard}>
          <GridLayout direction={'row'} justifyContent={'center'}>
            <CustomIcon className={styles.warningIcon} icon={faWarning}/>
            <LocalizedText>ongoingEditionWarning</LocalizedText>
          </GridLayout>
        </div>
      )}
      <GridLayout
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
      >
        <PublicationPeriodSection
          dateParams={currentLocaleParams}
          news={news}
          updateNews={updateNews}
        />
        { initialValue &&
          <AccountSection
            dateFormat={currentLocaleParams.format}
            news={news}
          />
        }
      </GridLayout>
      <TranslationSection
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        translation={findOrInitTranslation(news,selectedLanguage)}
        updateTranslation={(delta) => updateTranslation(delta, selectedLanguage)}
      />

      <FloatingButtonMenu>
        <FloatingButton
          icon={faUndo}
          onClick={() => resetForm()}
          tooltip={<LocalizedText>reset</LocalizedText>}
        />
        <FloatingButton
          icon={faEye}
          onClick={() => setPreviewActive(true)}
          tooltip={<LocalizedText>preview</LocalizedText>}
        />
        <FloatingButton
          disabled={!inputValid}
          icon={faSave}
          isMainButton={true}
          onClick={() => saveNewsAction(news)}
          tooltip={<LocalizedText>save</LocalizedText>}
        />
      </FloatingButtonMenu>

      <NewsPreview
        isOpen={previewActive}
        news={getNewsPreview(news, selectedLanguage)}
        onClose={() => setPreviewActive(false)}
      />
    </div>
  );
}

NewsForm.propTypes = {
  initialValue: PropTypes.shape({}).isRequired,
  saveAction: PropTypes.func.isRequired
}