import React from 'react';
import {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const fontSizes = ["12px" ,"16px", "24px", "32px"];
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = fontSizes;
Quill.register(SizeStyle, true);

export const CustomEditorToolbar = () => {

  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-font"></select>
        <select className="ql-size">
          {fontSizes.map((value, index) =>
            (<option selected={index===0} value={value}>{value}</option>)
          )}
        </select>
      </span>


      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
      </span>
      <span className="ql-formats">
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>
    </div>
  );
}

export const toolbarModule = {
  toolbar: {
    container: "#toolbar"
  }
}

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];