import { PageContainer } from 'components/PageContainer/PageContainer';
import TabsMenu from 'components/TabsMenu/TabsMenu';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React from 'react';
import { AdministrationRoutes } from 'routes/AdministrationRoutes';
import { isAdmin } from 'utils/constants/roles';
import { GET_ADMINISTRATION_TABS } from 'utils/constants/tabs';

export const AdministrationPage = () => {
  const { role } = useCustomerInfo();


  return (
    <PageContainer>
      {
        isAdmin(role)
        && (
          <>
            <TabsMenu role={role} tabs={GET_ADMINISTRATION_TABS()} />
            <AdministrationRoutes />
          </>
        )
      }
    </PageContainer>
  );
};