import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {RichTextDisplay} from 'components/Editor/RichTextDisplay';
import {TruncatedMultiline} from 'utils/helpers/TruncatedMultiline';
import {Box, Typography} from '@material-ui/core';
import {ConditionalWrapper} from 'hook/ConditionalWrapper';
import {GridLayout} from 'utils/helpers/GridLayout';
import {format} from 'date-fns';
import CustomIcon from 'commons/Icon/CustomIcon';
import {faAngleDown, faAngleRight} from '@fortawesome/pro-solid-svg-icons';


export const useStyles = makeStyles((theme) => ({
  card: {
    padding: '10px',
    margin: '10px',
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
    textTransform: 'none'
  },
  toRead: {
    backgroundColor: theme.palette.primary.light
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  date: {
    color: theme.palette.primary.main
  },
  content: {
    color: theme.palette.text.primary
  },
  dot: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
    borderRadius: '50%'
  },
  arrow: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    marginLeft: '5px'
  }
}));

export const NewsMenuCard = ({
  news, onClick = () => {}
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const dateFormat = 'dd MMM yyyy - kk:mm';
  const cardClasses = (news.read) ? classes.card : classes.card + ' ' + classes.toRead;
  const openClosedIcon = (expanded) ? faAngleDown : faAngleRight;

  const expandSwitch = () => {
    setExpanded((previousState) => !previousState);
    onClick();
  }

  return (
    <>
      <Box
        className={cardClasses}
        onClick={expandSwitch}
      >
        <GridLayout
          columnExpanded={[false, true]}
          direction={'row'}
          spacing={1}
        >
          {news.read ?
            <CustomIcon className={classes.arrow} icon={openClosedIcon}/> :
            <Box className={classes.dot}/>
          }

          <GridLayout
            alignItems={'flex-start'}
            direction={'column'}
            spacing={1}
          >
            <Typography className={classes.title}>
              {news.title}
            </Typography>
            <Typography className={classes.date}>
              {(news.startDate) ? format(new Date(news.startDate), dateFormat) : ""}
            </Typography>
            <ConditionalWrapper
              condition={!expanded}
              wrapper={(childrenNodes) => (
                <Typography className={classes.content}>
                  <TruncatedMultiline maxLines={2}>
                    {childrenNodes}
                  </TruncatedMultiline>
                </Typography>
              )}
            >
              <RichTextDisplay
                simplified={!expanded}
                value={news?.content}
              />
            </ConditionalWrapper>
          </GridLayout>
        </GridLayout>
      </Box>
    </>
  );
}

NewsMenuCard.propTypes = {}