import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

export const GridLayout = ({children, direction, columnExpanded, ...props}) => {
  if (direction === "column") columnExpanded=null;
  return (
    <Grid container
      direction={direction}
      {...props}
    >
      {React.Children.map(children, (child,index) => (
        <Grid item 
          xs={columnExpanded ? columnExpanded[index] : null}
        >
          { child }
        </Grid>
      ))}
    </Grid>
  );
};

GridLayout.propTypes = {
  alignItems: PropTypes.string,
  columnExpanded: PropTypes.arrayOf(PropTypes.bool),
  direction: PropTypes.string,
  justifyContent: PropTypes.string
};