import { API_URL } from 'utils/constants/app';
import { RequestHelper } from 'utils/helpers/RequestHelper';

const userServiceUrl = `${API_URL}/user`;

const getAresUserDetailedInfo = (userId) => RequestHelper.GET(`${userServiceUrl}/${userId}/details`);

const createAresUser = (userDetailedInfo) => RequestHelper.POST(
  `${userServiceUrl}`, userDetailedInfo
);

const updateAresUser = (userId, userDetailedInfo) => RequestHelper.POST(
  `${userServiceUrl}/${userId}/`, userDetailedInfo
);

export const UserService = {
  getAresUserDetailedInfo,
  createAresUser,
  updateAresUser
};