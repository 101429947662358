export const ErrorType = {
  UNAUTHORIZED: {
    title: 'Invalid account',
    description: 'Your account was not found in our database or is not activated, please contact your'
      + ' administrator or try with another account'
  },
  ERROR: {
    title: 'error',
    description: 'errorParagraph'
  }
};