import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

/**
 * Hook which returns a function which displays a snackbar with a localized message
 * @returns {function(*, *=): string | number}
 */
export default function useLocalizedSnackBar() {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((message, options) => {
    enqueueSnackbar(<LocalizedText text={message} />, options);
  }, [enqueueSnackbar]);
}