import useFetch from 'hook/UseFetch';
import React, { createContext, useContext } from 'react';
import { LanguageService } from 'services/LanguageService';

const LocalesContext = createContext(null);
/**
 * @typedef {Object} LocaleInfo
 * @property {string} name
 * @property {string} countryCode
 * @property {string} languageCode
 * @property {boolean} current
 */

/**
 * Hook which returns all available locales
 * with a boolean included in the locale object to indicate if it is the customer locale
 * @returns {LocaleInfo[]}
 */
export const useLocales = () => useContext(LocalesContext);

export const LocalesProvider = ({ children }) => {
  const locales = useFetch(() => LanguageService.getLocales(), []).response;
  return (
    <LocalesContext.Provider
      value={locales}
    >
      {children}
    </LocalesContext.Provider>
  );
};