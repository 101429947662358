import AuditRoute from 'hook/AuditRoute';
import ContractRoute from 'hook/ContractRoute';
import { useFeatures } from 'hook/FeatureProvider';
import ProductTestRoute from 'hook/ProductTestRoute';
import { AccountPage } from 'pages/AccountPage';
import { AdministrationPage } from 'pages/AdministrationPage';
import { AuditListPage } from 'pages/AuditListPage';
import { AuditPage } from 'pages/AuditPage';
import { BillPage } from 'pages/BillPage';
import { ContractPage } from 'pages/ContractPage';
import { DiplomaPage } from 'pages/DiplomaPage';
import { HomePage } from 'pages/HomePage';
import { TestListPage } from 'pages/TestListPage';
import { TestPage } from 'pages/TestPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

export const SubRoutes = () => {
  const { auditsActivated } = useFeatures();
  return (
    <Switch>
      <ContractRoute component={HomePage} exact path={ROUTES.HOME} />
      <ContractRoute component={TestListPage} exact path={ROUTES.TESTS} />
      <ContractRoute component={BillPage} exact path={ROUTES.BILLS} />
      <ContractRoute component={DiplomaPage} exact path={ROUTES.DIPLOMAS} />
      <ContractRoute component={ContractPage} path={ROUTES.CONTRACT.BASE} />
      {auditsActivated !== false && <ContractRoute component={AuditListPage} exact path={ROUTES.AUDITS} />}
      {/* TODO AUDIT Uncomment this route when audit are on ARES */}
      {/* <ContractRoute component={AuditPage} exact path={ROUTES.AUDITS} /> */}
      <ContractRoute component={AdministrationPage} path={ROUTES.ADMINISTRATION.BASE} />
      <ContractRoute component={AccountPage} path={ROUTES.ACCOUNT.BASE} />
      <ProductTestRoute component={TestPage} path={ROUTES.TEST.BASE} />
      {/* Use !== false here to generate route even if undefined to prevent redirect on page load */}
      {auditsActivated !== false && <AuditRoute component={AuditPage} path={ROUTES.AUDIT.BASE} />}
      <Redirect from={ROUTES.BASE} to={ROUTES.HOME} />
    </Switch>
  );
};