import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutoCompleteWithLabel } from 'commons/AutoComplete/AutoCompleteWithLabel';
import { DialogType } from 'commons/Dialog/DialogTypes';
import { DialogWrapper } from 'commons/Dialog/DialogWrapper';
import { localize } from 'commons/LocalizedText/LocalizedText';
import { FileDropZone } from 'commons/Upload/FileDropzone';
import { useDialog } from 'hook/DialogProvider';
import * as React from 'react';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  content: {
    padding: '20px'
  },
  dropdown: {
    width: 300,
    margin: 0
  }
}));

export const FileUploadDialog = ({ editedFileId = null, editionFileType = null, initialFileName = null, onClose, onSubmit, open, requiredSelect,
  requiredDropzone, title, uploadableFileTypes }) => {
  const showDialog = useDialog();
  const [file, setFile] = useState(null);
  const [fileTypeToAdd, setFileTypeToAdd] = useState(null);
  const classes = useStyles();

  const handleDropZoneChange = file => (
    file && setFile(file)
  );

  useEffect(() => {
    setFileTypeToAdd(editionFileType);
  }, [editionFileType]);

  const handleSubmit = () => {
    if (requiredDropzone && file === null) {
      showDialog({
        variant: DialogType.ERROR,
        title: 'error',
        description: 'fileShouldNotBeNull'
      });
    }
    onSubmit(file, fileTypeToAdd, editedFileId);
    setFileTypeToAdd(null);
    handleClose();
  };

  const handleClose = () => {
    setFile(null);
    onClose();
  }

  return (
    <DialogWrapper
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
      preventSubmit={file === null || fileTypeToAdd === null}
      title={localize(title)}
      variant={DialogType.CONFIRMATION}
    >
      <Grid className={classes.content} container direction={'column'} justifyContent={'center'}>
        <Grid item>
          <AutoCompleteWithLabel
            autoCompleteXS={9}
            className={classes.dropdown}
            disabled={editionFileType}
            isLoading={!uploadableFileTypes}
            isTranslatingOptions
            label="fileTypeToAdd"
            labelXS={3}
            noOptionsText="loading"
            onChange={(event) => setFileTypeToAdd(event)}
            options={uploadableFileTypes?.map(fileType => fileType.id)}
            required={requiredSelect}
            value={fileTypeToAdd}
            valueName="fileTypeToAdd"
          />
        </Grid>
        <Grid item>
          <FileDropZone fileTypeToAdd= {fileTypeToAdd} initialFileName={initialFileName} onFileDrop={handleDropZoneChange}/>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};