import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ErrorBoundary } from 'app/ErrorBoundary';
import ARES_THEME from 'app/theme';
import DebugFooter from 'components/DebugFooter/DebugFooter';
import Footer from 'components/Footer/Footer';
import ImpersonatingUserOverlay from 'components/ImpersonatingUserOverlay/ImpersonatingUserOverlay';
import { ContractInfoProvider } from 'hook/ContractInfoProvider';
import { CustomerInfoProvider, useCustomerInfo } from 'hook/CustomerInfoProvider';
import { DialogProvider } from 'hook/DialogProvider';
import { FeatureProvider } from 'hook/FeatureProvider';
import { LocalesProvider } from 'hook/LocalesProvider';
import useMobile from 'hook/UseMobile';
import usePageTracking from 'hook/usePageTracking';
import { SnackbarProvider } from 'notistack';
import { DownloadFilePage } from 'pages/DownloadFilePage';
import { ErrorPage } from 'pages/ErrorPage';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { SubRoutes } from 'routes/SubRoutes';
import { Roles } from 'utils/constants/roles';
import { ROUTES } from 'utils/constants/routes';
import { BreadCrumbContextProvider } from 'components/BreadCrumb/BreadCrumbContext';
import { ActiveRouteContextProvider } from 'components/ActiveRoute/ActiveRouteContext';
import { AuthProvider, AuthRequiredSection } from "hook/AuthProvider";
import { I18NProvider, useI18NContext } from "hook/I18nProvider";
import { AccountPage } from "pages/AccountPage";
import { ContractSelectionPage } from "pages/ContractSelectionPage";
import Header from "components/Header/Header";
import { Spinner } from "commons/Spinner/Spinner";

const MainAppPage = () => {
  const isMobile = useMobile();
  const {role, connectedAsUser} = useCustomerInfo();
  usePageTracking();

  return (
    role !== undefined ?
      <>
        <Header/>
        <ActiveRouteContextProvider>
          <BreadCrumbContextProvider>
            <SubRoutes/>
          </BreadCrumbContextProvider>
        </ActiveRouteContextProvider>

        {role === Roles.ECC_ADMIN && <DebugFooter/>}
        {isMobile && <Footer/>}
        {connectedAsUser && <ImpersonatingUserOverlay/>}
      </> : null
  );
}

const MainApp = () => (
  <LocalesProvider>
    <FeatureProvider>
      <ContractInfoProvider>
        <MainAppPage/>
      </ContractInfoProvider>
    </FeatureProvider>
  </LocalesProvider>
);

const NonAuthenticatedRoute = ({component, path}) => {
  return <Route component={component} path={path}/>
}

const AuthenticatedRoute = ({component, path, render}) => {
  const {translationsLoaded} = useI18NContext();

  return (
    <Route path={path}>
      <AuthRequiredSection>
        {translationsLoaded ? (
          <CustomerInfoProvider> {/* This provider also handles the cookie popup */}
            <Route component={component} render={render}/>
          </CustomerInfoProvider>
        ) : (
          <Spinner/>
        )}
      </AuthRequiredSection>
    </Route>
  )
}

const MainRouter = () => {
  return (
    <Switch>
      {/* Match no auth routes first */}
      <NonAuthenticatedRoute component={ErrorPage} path={ROUTES.ERROR}/>
      <NonAuthenticatedRoute component={DownloadFilePage} path={ROUTES.DOWNLOAD}/>

      {/* Match auth routes after */}
      <AuthenticatedRoute component={MainApp} path={ROUTES.BASE}/>
      <AuthenticatedRoute component={ContractSelectionPage} exact path={ROUTES.CHOICE}/>
      <AuthenticatedRoute path={ROUTES.GENERAL_ACCOUNT.BASE} render={(props) =>
        <AccountPage {...props} general={true}/>
      }/>
      <Redirect to={ROUTES.CHOICE}/>
    </Switch>
  )
}

const App = () => {
  return (
    <ThemeProvider theme={(ARES_THEME)}>
      <CssBaseline>
        <BrowserRouter>
          <CookiesProvider>
            <DialogProvider>
              <AuthProvider>
                <I18NProvider>
                  <SnackbarProvider maxSnack={3}>
                    <ErrorBoundary>
                      <MainRouter/>
                    </ErrorBoundary>
                  </SnackbarProvider>
                </I18NProvider>
              </AuthProvider>
            </DialogProvider>
          </CookiesProvider>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
};
export default App;