export const Roles = {
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
  ECC: 'ECC',
  ECC_ADMIN: 'ECC_ADMIN'
};

export const isSuperAdmin = (role) => role === Roles.ECC_ADMIN;
export const isAdmin = (role) => role === Roles.ECC_ADMIN || role === Roles.CUSTOMER_ADMIN;

export const isEcc = (role) => role === Roles.ECC_ADMIN || role === Roles.ECC;