import Grid from '@material-ui/core/Grid';
import React from 'react';
import AuditLaboratoryCard from 'components/AuditLaboratoryCard/AuditLaboratoryCard';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import SkeletonLaboratoryCard from 'components/Skeletons/SkeletonLaboratoryCard/SkeletonLaboratoryCard';

export const AuditLaboratoryPage = ({ laboratories }) => (
  <Grid
    alignItems="center"
    container
    direction="row"
    justify="space-evenly"
  >
    {
      laboratories ? (
        <>
          {laboratories.length
            ? laboratories.map((laboratory) => {
              const { laboratoryName } = laboratory;
              return (
                <AuditLaboratoryCard
                  key={laboratoryName}
                  laboratory={laboratory}
                />
              );
            })
            : <LocalizedText>noLaboratoryFound</LocalizedText>}
        </>
      )
        : (
          <SkeletonLaboratoryCard />
        )
    }
  </Grid>
);