import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NewsService } from 'services/NewsService';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import CustomIcon from 'commons/Icon/CustomIcon';
import { faNewspaper, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Drawer from '@material-ui/core/Drawer';
import SockJsClient from 'react-stomp';
import { NewsMenuCard } from 'components/News/NewsMenuCard';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import Grid from '@material-ui/core/Grid';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { FloatingButton } from 'commons/Button/FloatingButton';
import { API_URL } from 'utils/constants/app';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';


export const useStyles = makeStyles((theme) => ({
  newsButton: {
    color: theme.palette.primary.main
  },
  badge: {
    color: 'white'
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.paper,
    width: '40%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%'
    }
  },
  newsIcon: {
    color: 'white',
    transform: "scale(1, 1)",
    padding: '5px'
  },
  menuTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  menuHeader: {
    color: 'white',
    padding: '10px',
    backgroundImage: `linear-gradient(175deg,${theme.palette.primary.main},${theme.palette.secondary.main})`
  }
}));

export const NewsMenuButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const account = useCustomerInfo();

  const [displayNews, setDisplayNews] = useState(false);
  const [newsList, setNewsList] = useState([]);

  const newsTopic = '/topic/news/amountUpdate';
  const newsQueue = `/topic/news/amountUpdate/${account.userName}`;
  const newsSocketUrl = `${API_URL}/ws/news/`;

  const [newsAmount, setNewsAmount] = useState(0);

  const updateNews = () => {
    NewsService.getAllNewsForUser()
      .then((response) => setNewsList(response));
    NewsService.getUnreadNewsCount().then(resp => setNewsAmount(resp)).catch(console.error);
  }

  useEffect(() => {
    updateNews();
  }, [newsAmount])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newsMenu = params.get('newsMenu');
    if (newsMenu === 'open' && displayNews === false) {
      setDisplayNews(true);
      params.delete('newsMenu')
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  } ,[
    // reactive values
    location.search,
    displayNews,
    // reactive values that should not change
    location.pathname, // should stay constant while in this component
    // non reactive values
    history // TODO: is history really non reactive ? if not could it change ? + propagate decision
  ])


  const onNewsMenuClosed = () => {
    setDisplayNews(false);
  }

  const readNews = (news) => {
    if (!news.read) {
      news.read = true
      NewsService.readNews(news.recid)
        .catch(console.error)
    }
  }

  return (
    <>
      <IconButton
        className={classes.newsButton}
        onClick={() => setDisplayNews(true)}
      >
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
          badgeContent={newsAmount}
          classes={{badge: classes.badge}}
          color="error"
        >
          <CustomIcon icon={faNewspaper}/>
        </Badge>
      </IconButton>

      <Drawer
        anchor={'right'}
        classes={{paper: classes.drawerPaper}}
        onClose={onNewsMenuClosed}
        open={displayNews}
      >
        <div className={classes.menuHeader}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item>
              <CustomIcon className={classes.newsIcon} icon={faNewspaper} size="2x"/>
            </Grid>
            <Grid item xs zeroMinWidth>
              <LocalizedText className={classes.menuTitle}>yourNews</LocalizedText>
            </Grid>
            <Grid item>
              <FloatingButton icon={faTimes} onClick={onNewsMenuClosed}/>
            </Grid>
          </Grid>
        </div>

        {newsList.map((news, index) => (
          <NewsMenuCard key={`News#${index}`} news={news} onClick={() => readNews(news)}/>
        ))}
      </Drawer>

      <SockJsClient
        onMessage={(msg) => updateNews()}
        topics={[newsTopic, newsQueue]}
        url={newsSocketUrl}
      />
    </>
  );
}

NewsMenuButton.propTypes = {}