import { MSAL_CLIENT, MSAL_REDIRECT, MSAL_TENANT } from 'utils/constants/app';

import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT}`,
    redirectUri: MSAL_REDIRECT,
    postLogoutRedirectUri: MSAL_REDIRECT
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;
        // TODO: figure out which log levels to keep if any
        // case LogLevel.Info:
        //   console.info(message);
        //   return;
        // case LogLevel.Verbose:
        //   console.debug(message);
        //   return;
        case LogLevel.Warning:
          console.warn(message);
          return;
        default:
          return;
        }
      }
    }
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"]
};