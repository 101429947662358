/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import SkeletonTable from 'components/Skeletons/SkeletonTable/SkeletonTable';
import useMobile from 'hook/UseMobile';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const MIN_ROWS_FOR_PAGINATION = 5;
export const INITIAL_PAGE = 0;
export const INITIAL_ROWS_PER_PAGE = 10;

export const SORTING = {
  ASC: 'asc',
  DESC: 'desc'
};

const CELL_STYLE = { maxWidth: '30vw', overflowX: 'overlay', overflowY: 'hidden', wordWrap: 'break-word' };

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed'
  },
  white: {
    backgroundColor: theme.palette.background.paper
  },
  rowStriped: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.primary.light
    },
    '& > td': {
      [theme.breakpoints.down('sm')]: {
        padding: '2px 10px 2px 10px'
      }
    }
  },
  hoverRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.tableHover
    }
  },
  tableHeader: {
    ...CELL_STYLE,
    borderBottom: '1px solid black',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      padding: '3px 12px 3px 12px'
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '1px 8px 1px 8px'
    }
  },
  tableCell: {
    ...CELL_STYLE
  },
  overflowHidden: {
    overflow: 'hidden'
  }
}));

const desc = (a, b, orderBy, sortOnProperty) => {
  if (b[orderBy] && b[orderBy][sortOnProperty]) {
    if (b[orderBy][sortOnProperty] < a[orderBy][sortOnProperty]) return -1;
    if (b[orderBy][sortOnProperty] > a[orderBy][sortOnProperty]) return 1;
  } else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }
  return 0;
};

export const CustomTable = ({
  rows, headers, onRowCLick, stickyHeader, filters, isLoading, rowKey, onChange, isBackend, total, initialOrderBy,
  excludePagination, isLoadingExtraData,
  sortParams, paginationParams
}) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const {order, orderBy, setOrder, setOrderBy} = sortParams;
  const [sortOnProperty, setSortOnProperty] = useState(headers?.sortOn || 'name');
  const [filteredRows, setFilteredRows] = useState(rows);
  const {page, rowsPerPage, setPage, setRowsPerPage} = paginationParams;

  // FRONTEND: Handle filters for frontend grid (reset to first page every time filters change)
  useEffect(() => {
    if (!isBackend) {
      setPage && setPage(INITIAL_PAGE);
      let newRows = [...rows];
      Object.values(filters)
        .forEach((filter) => {
          newRows = newRows.filter(filter);
        });
      setFilteredRows(newRows);
    }
  }, [filters, rows]);

  // BACKEND/FRONTEND : Handle filters for backend grid
  useEffect(() => {
    if (isBackend) {
      setFilteredRows([...rows]);
    }
  }, [rows]);

  // BACKEND: Reset to first page when new backend filter is activated (number of pages change) + refresh grid
  useEffect(() => {
    setPage && setPage(INITIAL_PAGE);
    onChange();
  }, [total]);

  const handleSort = ({ name, sortOn }) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder && setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy && setOrderBy(name);
    setSortOnProperty(sortOn);
    setPage && setPage(INITIAL_PAGE);
    onChange();
  };

  const handleChangePage = (event, newPage) => {
    setPage && setPage(newPage);
    onChange();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage && setRowsPerPage(event.target.value);
    setPage && setPage(INITIAL_PAGE);
    onChange();
  };


  const getSorting = () => (order === 'desc'
    ? (a, b) => desc(a, b, orderBy, sortOnProperty)
    : (a, b) => -desc(a, b, orderBy, sortOnProperty));

  function getFinalRows(filteredRows) {
    if (isBackend) {
      return filteredRows;
    }

    const sortedRows = filteredRows?.sort(getSorting()) ?? [];

    if (page && rowsPerPage) {
      return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return sortedRows;
  }

  const finalRows = getFinalRows(filteredRows)

  const finalHeaders = isMobile
    ? headers.filter((header) => header.isOnMobile)
    : headers.filter((header) => header.isOnDesktop);

  const needForPages = isBackend || rows.length > MIN_ROWS_FOR_PAGINATION;

  return (
    <Table
      className={[classes.white, classes.table].join(' ')}
      size="small"
      stickyHeader={stickyHeader}
    >
      <TableHead>
        <TableRow>
          {finalHeaders.map((header) => (
            <TableCell
              className={`${classes.white} ${classes.tableHeader}`}
              key={header.name}
              width={isMobile ? header.mobileWidth : header.width}
            >
              {header.isSortable ? (
                <TableSortLabel
                  active={orderBy === header.name}
                  direction={order}
                  onClick={() => handleSort({ ...header })}
                >
                  <LocalizedText text={header.label} />
                </TableSortLabel>
              )
                : <LocalizedText text={header.label} />}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading
        ? <SkeletonTable columns={finalHeaders.length} rows={rowsPerPage} />
        : (
          <TableBody>
            {finalRows.length === 0
              ? (
                <TableRow>
                  <TableCell colSpan={finalHeaders.length}>
                    <LocalizedText align="center" variant="h6">noDataInTable</LocalizedText>
                  </TableCell>
                </TableRow>
              )
              : finalRows.map((row) => (
                <TableRow
                  className={`${classes.rowStriped} ${onRowCLick ? classes.hoverRow : ''}`}
                  key={rowKey ? row[rowKey] : row.id}
                  onClick={() => onRowCLick && onRowCLick(row)}
                >
                  {finalHeaders.map((column) => (
                    <TableCell
                      className={classes.tableCell}
                      key={column.name}
                      width={isMobile ? column.mobileWidth : column.width}
                    >
                      {column.template(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        )}
      {
        !excludePagination ? (
          needForPages && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  backIconButtonText={localize('previousPage')}
                  className={classes.overflowHidden}
                  count={total ?? filteredRows.length}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} ${localize('to')} ${count}`}
                  labelRowsPerPage={localize('rowsPerPage')}
                  nextIconButtonText={localize('nextPage')}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </TableRow>
            </TableFooter>
          )
        ) : (
          isLoadingExtraData && (
            <TableRow>
              <TableCell align='center' colSpan={finalHeaders.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )
        )
      }
    </Table>
  );
};

CustomTable.propTypes = {
  filters: PropTypes.object,
  headers: PropTypes.arrayOf(PropTypes.shape({
    isOnMobile: PropTypes.bool,
    isSortable: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  isBackend: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowCLick: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  rowKey: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stickyHeader: PropTypes.bool,
  total: PropTypes.number
};

CustomTable.defaultProps = {
  filters: {},
  isBackend: false,
  isLoading: false,
  onChange: () => {},
  onPageChange: (page) => {},
  onRowCLick: null,
  onRowsPerPageChange: (rowsPerPage) => {},
  onSortChange: ({order, orderBy}) => {},
  rowKey: null,
  stickyHeader: false,
  total: null
};